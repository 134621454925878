import toast from "react-hot-toast"
import { Workflow } from "../../consts"
import { fileHandler } from "../../services/utils"
import { setCompletedWorkflow } from "../completion/reducers"
import { setError } from "../error/reducers"
import { loadData } from "./reducers"

const workflow = Workflow.upgrades

const toastOptions = (endpoint, additional) => ({id: endpoint, position: "bottom-center", ...additional, style: {minWidth: "30%"}})

export const mutationFixedCacheKeys = {
    uploadBaseUpgradingFile: "uploadBaseUpgradingFile",
    upgradeAndExport: "upgradeAndExport"
}

export const createUpgradesEndpoints = (builder) => ({
    uploadBaseUpgradingFile: builder.mutation({
        query: ({body}) => ({
            url: "api/upgrader/upload_initial_file",
            method: "POST",
            body,
        }),
        onQueryStarted: async (arg, {dispatch, queryFulfilled, getState}) => {
            try {
                const {data} = await queryFulfilled
                dispatch(loadData(data))
            } catch ({error}) {
                dispatch(setError({error, workflow, key: "uploadBaseUpgradingFile"}))
            }
        }
    }),
    getMeasuresLib: builder.query({
        query: () => ({
            url: "api/upgrader/get_web_measures",
            method: "GET"
        }),
        onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
            try {
                const {data} = await queryFulfilled
            } catch ({error}) {
                dispatch(setError({error, workflow, key: "getMeasuresLib"}))

            }
        }
    }),
    upgradeAndExport: builder.mutation({
        query: ({mutations}) => ({
            url: "api/upgrader/upgrade_and_export",
            method: "POST",
            body: mutations,
            responseHandler: fileHandler,
        }),
        onQueryStarted: async (arg, {dispatch, queryFulfilled, getState}) => {
            try {
                toast.loading("Upgrading template library...", toastOptions(mutationFixedCacheKeys.upgradeAndExport))
                const {data} = await queryFulfilled
                dispatch(setCompletedWorkflow(workflow))
                toast.dismiss(mutationFixedCacheKeys.upgradeAndExport)
            } catch ({error}) {
                toast.dismiss(mutationFixedCacheKeys.upgradeAndExport)
                dispatch(setError({error, workflow, key: mutationFixedCacheKeys.upgradeAndExport}))
            }
        }
    })
})