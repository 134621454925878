// Hook
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import BarChartIcon from "@material-ui/icons/BarChart"
import ShowChartIcon from "@material-ui/icons/ShowChart"
import ShareIcon from "@material-ui/icons/Share"
import { makeStyles } from "@material-ui/core/styles"

///// Umiverse Imports

// Redux
import { GraphType, GraphTypeConfig } from "../../../redux/slices/analysis/consts"
import { selectGraphMode } from "../../../redux/slices/analysis/selectors"
import { setGraphMode } from "../../../redux/slices/analysis/reducers"

const useStyles = makeStyles(theme => ({
    buttonGroup: {
        marginBottom: theme.spacing(3)
    }
}))

const iconMap = {
    [GraphType.bar]: <BarChartIcon style={{marginLeft: "0.5rem"}}/>,
    [GraphType.sankey]: <ShareIcon style={{marginLeft: "0.5rem"}}/>,
    [GraphType.timeSeries]: <ShowChartIcon style={{marginLeft: "0.5rem"}}/>
}

export default () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const graphMode = useSelector(selectGraphMode)

    const handleChange = useCallback((e, value) => {
        dispatch(setGraphMode(value))

    }, [dispatch])
    return (
        <Grid container justifyContent="center" className={classes.buttonGroup}>
            <ToggleButtonGroup orientation="horizontal" size="small" value={graphMode} exclusive onChange={handleChange}>
                {Object.entries(GraphTypeConfig).map( ([graphMode, {label}])=> (
                    <ToggleButton key={`${graphMode}-graph-mode-toggle-button`} value={graphMode} >{ label }{iconMap[graphMode]}</ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Grid>
    )

}