// Hooks
import { useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast"

// MUI
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CancelIcon from '@material-ui/icons/Cancel'
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';

///// Umiverse Imports

// Redux
import { ubemIoApi } from '../../../redux/services/ubem';
import { changeArchetypeField } from '../../../redux/slices/umibuilder/reducers';
import { mutationFixedCacheKeys } from '../../../redux/slices/umibuilder/endpoints';
import { makeSelectArchetypeSubgroup } from '../../../redux/slices/umibuilder/selectors';

// Components
import TemplateTable from './TemplateTable';

const {useQuery: useTemplates} = ubemIoApi.endpoints.useTemplateDatabase
const {useMutation: uploadUserTemplateLib } = ubemIoApi.endpoints.uploadUserTemplateLibUmibuilder


export default ({archetypeID, primaryField, open, setOpen}) => {
    const dispatch = useDispatch()
    const { isLoading: isLoadingDatabaseTemplates} = useTemplates('')
    const [_, {isLoading: isLoadingUserTemplates}] = uploadUserTemplateLib({fixedCacheKey: mutationFixedCacheKeys.uploadUserTemplateLib})
    const currentTemplate = useSelector(makeSelectArchetypeSubgroup({field: primaryField, archetypeID}))?.template

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const assign = useCallback(({row}) => {
        dispatch(changeArchetypeField({field: primaryField, archetypeID, key: "template", value: row.Name, }))
        dispatch(changeArchetypeField({field: primaryField, archetypeID, key: "isUser", value: row.Type.includes("User")}))
        toast.success("Assigned template!")
        setOpen(false)
    }, [primaryField, archetypeID, dispatch, setOpen])

    const unassign = useCallback(() => {
        dispatch(changeArchetypeField({field: primaryField, archetypeID, key: "template", value: null, }))
        dispatch(changeArchetypeField({field: primaryField, archetypeID, key: "isUser", value: undefined}))
        toast.success("Unassigned template!")
    }, [primaryField, archetypeID, dispatch])




    return (
        <Dialog fullScreen open={open} onClose={handleClose} >
            <Container>

            <DialogTitle id="template-assignment-dialog">
                Select Template
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select a template to assign to the current archetype ({archetypeID}).  Filter by Climate Zone or building typology.
                </DialogContentText>
                {currentTemplate && <Chip onDelete={unassign} label={currentTemplate}/>}
                {(isLoadingDatabaseTemplates || isLoadingUserTemplates) && <LinearProgress />}
                <TemplateTable assign={assign} />
            </DialogContent>
            </Container>
            <DialogActions>
                <Button variant="contained" onClick={handleClose} color="primary" endIcon={<CancelIcon/>}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}