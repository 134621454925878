import { useCallback, useMemo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { enumKeys, ObjectDataFieldEnum } from "./ObjectDataFieldEnum";

export const ObjectDataFieldForm = ({ state, dispatch, keyName, index, parameter, label }) => {
    const {selectedNode, indexedNodes} = state
    const objectId = selectedNode
    const currentValue = useMemo(() => index !== undefined ?
        parameter !== undefined ?
            indexedNodes[objectId][keyName][index][parameter]
            :
            indexedNodes[objectId][keyName][index]
        :
        indexedNodes[objectId][keyName],
        [indexedNodes, objectId, keyName, index, parameter, indexedNodes[objectId][keyName]]);

    const _label = useMemo(() => label!==undefined ? label :
        index !== undefined ?
            parameter !== undefined ?
                `${keyName}-${index}-${parameter}`
                :
                `${keyName}-${index}`
            :
            keyName,
        [keyName, index, parameter, label]);
    
    const handleChange = useCallback((e) => {
        const value = typeof(currentValue) === "number" ? Number(e.target.value) : typeof(currentValue) === "boolean" ? e.target.checked : e.target.value;
        const args = {
            objectId,
            key: keyName,
            value
        };
        if (index !== undefined) {
            args.type = "update index of field";
            args.index = index;
            if (parameter !== undefined) {
                args.parameter = parameter;
                args.value = {
                    ...indexedNodes[objectId][keyName][index],
                    [parameter]: value
                };
            }
        } else {
            args.type = "update simple field";
        }
        dispatch(args);

    }, [dispatch, objectId, keyName, index, parameter, currentValue, indexedNodes, indexedNodes[objectId][keyName]]);

    

    const isBoolean = typeof(currentValue) === "boolean"
    const enumLookupName = useMemo(() => keyName==="Type" ? `${indexedNodes[objectId].class}Type`: keyName, [keyName, indexedNodes, objectId, indexedNodes[objectId].class])
    const isEnum = Object.keys(enumKeys).includes(enumLookupName)
    const isNonDefaults = [isBoolean, isEnum]
    const isDefault = isNonDefaults.every(val=>!val)
    return (
        <>
            {isBoolean && <FormControlLabel control={<Switch checked={currentValue} onChange={handleChange} name={_label} color="primary"/>} label={_label} />}
            {isEnum && <ObjectDataFieldEnum label={_label} keyName={keyName} value={currentValue} onChange={handleChange} parentClass={indexedNodes[selectedNode].class} />}
            {isDefault && <TextField variant="outlined" type={typeof(currentValue) === "number" ? "number" : undefined} onChange={handleChange} label={_label} value={![null, undefined].includes(currentValue) ? currentValue : "<null>"} />}
        </>
    );
};
