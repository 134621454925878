// Hooks
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Slider from "@material-ui/core/Slider"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"

///// Umiverse Imports

// Redux
import { SolarParameterConfig } from "../../../redux/slices/analysis/consts"
import { updateSolarScenarioSettings } from "../../../redux/slices/analysis/reducers"
import { makeSelectSolarSettings } from "../../../redux/slices/analysis/selectors"


export default ({parameter, scenarioName}) => {
    const dispatch = useDispatch()
    const settings = useSelector(makeSelectSolarSettings(scenarioName))
    const value = settings[parameter]
    const config = SolarParameterConfig[parameter]

    const handleChange = useCallback((e,v) => {
        switch (config.type) {
            case "number":
                const value = ![null, undefined].includes(e.target.value) ? Number(e.target.value) : Number(v)
                dispatch(updateSolarScenarioSettings({scenarioName, parameter, value}))
                break
            case "boolean":
                dispatch(updateSolarScenarioSettings({scenarioName, parameter, value: v}))
                break
        }

    }, [parameter, scenarioName, config.type])
    const {defaultValue, ...props} = config

    if (config.type === "number") return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                {...props}
                inputProps={props}
                value={value}
                disabled={!settings.Enabled}
                // helperText={config.units}
                onChange={handleChange}
                defaultValue={undefined}
            />
            <Slider 
                {...props} 
                onChange={handleChange} 
                value={value} 
                defaultValue={undefined}
                disabled={!settings.Enabled}
            />
        </>
    )

	if (config.type === "boolean") return (
        <FormControl>
            <FormControlLabel 
                control={
                    <Checkbox 
                        onChange={handleChange} 
                        checked={value} 
                        // disabled={normalized} 
                        name={`${scenarioName}-${parameter}-pv-toggle`}
                        color="primary" 
                    />
                } 
                label={config.label}
            />
            <FormHelperText>If disabled, this scenario will not show PV on charts.</FormHelperText>
        </FormControl>
    )
    return (<></>)
}