export const selectRuntimeErrors = state => {
    const runtimeErrors = [
        state.analysis.runtimeError, 
        state.error.runtimeError, 
        state.navigation.runtimeError, 
        state.umibuilder.runtimeError,
        state.asyncTasks.runtimeError
    ]
    const message = runtimeErrors.some(err => err) ? "An error occurred in a Redux Reducer!" : null
    return message ? {message} : null
}

export const selectError = state => {
    const runtimeError = selectRuntimeErrors(state)
    const mainError = state.error.error
    if (mainError) {
        return mainError
    } else {
        return runtimeError
    }
}