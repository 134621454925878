// Hooks
import { useMemo } from "react"
import { useSelector } from "react-redux"

// MUI
import Button from "@material-ui/core/Button"

///// Umiverse Imports

// Redux
import { makeSelectKeyFieldErrorMessage } from "../../../redux/slices/umibuilder/selectors"

export default ({setMapColorMode, keyField}) => {
    const errorSelector = useMemo(() => makeSelectKeyFieldErrorMessage(keyField), [keyField])
    const error = useSelector(errorSelector)
    return  (
        <Button 
            onClick={()=>setMapColorMode(`${keyField}Error`)}
            size="small" 
            color="secondary" 
            variant="contained" 
            disabled={error == ''}
        >
            Highlight Errors
        </Button>
    )
}