import { createSlice } from '@reduxjs/toolkit'

export const completionSlice = createSlice({
    name: 'completion',
    initialState: {
        completedWorkflow: null, // workflow || {workflow, context}
    },
    reducers: {
        setCompletedWorkflow: (state, {payload}) => {
            state.completedWorkflow = payload
        }, 
    },
})

// Action creators are generated for each case reducer function
export const { 
    setCompletedWorkflow,
} = completionSlice.actions

export default completionSlice.reducer