import { useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ubemIoApi } from "../../../redux/services/ubem";
import { useMemo } from "react";
import { Divider } from "@material-ui/core";

const { useLazyQuery } = ubemIoApi.endpoints.getCZFromLatLon;
export default () => {
    const [lat, setLat] = useState(34);
    const [lon, setLon] = useState(-118);
    const [trigger, { data, isLoading }] = useLazyQuery();
    const czs = useMemo(() => {
        if (!data) return null;
        return Object.values(data).map(({ CZ }) => CZ);
    }, [data]);

    const handleSubmit = useCallback(() => {
        trigger({ lat, lon });
    }, [lat, lon]);
    return (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item>
                <TextField
                    value={lat}
                    label={"Latitude"}
                    helpText={"Enter a latitude."}
                    type="number"
                    onChange={e => setLat(Number(e.target.value))}
                />
            </Grid>
            <Grid item>
                <TextField
                    value={lon}
                    label={"Longitude"}
                    helpText={"Enter a longitude."}
                    type="number"
                    onChange={e => setLon(Number(e.target.value))}
                />
            </Grid>
            <Grid item>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                >
                    Submit
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {data && !isLoading && (
                <Grid item>
                    <Typography variant="overline">
                        Climate Zones: {czs.join(", ")}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
