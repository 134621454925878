// Hooks
import { useMemo, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Button from "@material-ui/core/Button" 
import Grid from "@material-ui/core/Grid"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { useNavigationEffect } from "../../../redux/slices/navigation/hooks"
import { Entrypoint } from "../../../redux/slices/umibuilder/consts"
import { isLoading, selectEntrypoint, selectGeoJSON } from "../../../redux/slices/umibuilder/selectors"
import { startAsyncTask } from "../../../redux/slices/async-processing/reducers"
import { AsyncTask } from "../../../redux/slices/async-processing/consts"

// Components
import { RtkDropzone } from "../../RtkDropzone"

// Hooks
import { useRisingEdgeEffect } from "../../../clientLib/hooks"
import { clearAllData } from "../../../redux/slices/umibuilder/reducers"

const { useMutation: uploadFilesMutation } = ubemIoApi.endpoints.uploadBaseGisFile
const { useMutation: clearUserFiles } = ubemIoApi.endpoints.clearUserFiles

// TODO: Add warnings for incomplete shpfile stuff

export default () => {
    const dispatch = useDispatch()
    const entrypoint = useSelector(selectEntrypoint)
    const hasGeoJSON = useSelector(selectGeoJSON)
    const loading = useSelector(isLoading)
    const { isSuccess: isUploadSuccess } = useSelector(ubemIoApi.endpoints.uploadBaseGisFile.select(entrypoint))

    const processFiles = useCallback(() => {
        dispatch(startAsyncTask({endpoint: AsyncTask.processBaseGis}))
    }, [dispatch])

    // Automatically advance UIO files
    useRisingEdgeEffect({callback: processFiles, condition: isUploadSuccess && entrypoint === Entrypoint.uio})

    // Go to tab if geojson transitions from null
    useNavigationEffect({destination: "keyfields", condition: hasGeoJSON}) 

    const [triggerClearFiles] = clearUserFiles()

    // Clear user files on mount
    useEffect(() => {
        // triggerClearFiles()
        dispatch(clearAllData())
    }, [triggerClearFiles, dispatch])

    const dropzoneProps = useMemo(() => ({
        label: `${entrypoint === Entrypoint.uio ? ".UIO Exchange" : "GIS"} File Upload`,
        rows: 4,
        formData: {
            entrypoint
        },
        mutation: uploadFilesMutation,
        index: entrypoint,
        skipReset: false,
        maxFiles: entrypoint === Entrypoint.uio ? 1 : 6,
        accept: entrypoint === Entrypoint.uio ? '.uio' : 'application/zip, .geojson, application/json, application/x-zip-compressed, multipart/x-zip,' +
            ' "multipart/form-data", .cpg, .dbf, .prj, .qpj, .shp, .shx',
    }), [entrypoint])

	return (
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>
                <RtkDropzone {...dropzoneProps} />
            </Grid>
            <Button disabled={loading} variant="outlined" onClick={processFiles} >Open Files</Button>
        </Grid>
	)
}