import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { useCallback } from "react"
import Graph from "react-graph-vis"
import { ObjectEditor } from "./ObjectEditor"
import { ObjectList } from "./ObjectList"

export const EditLibraryView = (props) => {
    const { graphProps, dispatch, state} = props
    const {selectedNode} = state

    const selectClass = useCallback(_class => {
        const args = {
            type: "select class",
            class: _class
        }
        dispatch(args)

    }, [dispatch])

	return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <ButtonGroup size="small">
                    <Button onClick={()=>selectClass("BuildingTemplate")} >Browse Objects by Type</Button>
                </ButtonGroup>
                <Paper>
                    {graphProps.graph ? 
                        <Graph {...graphProps} /> 
                    : "loading..."}
                </Paper>
            </Grid>
            {selectedNode ? 
                <ObjectEditor {...props} />
            : 
                <ObjectList {...props} />
            }
        </Grid>

	)
}