// Hooks
import { useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

// MUI
import  TextField  from "@material-ui/core/TextField"

///// Umiverse Imports

// Redux
import { updateFuelScenarioSettings } from "../../../redux/slices/analysis/reducers"
import { FuelParameterConfig } from "../../../redux/slices/analysis/consts"
import { makeSelectFuelSettings } from "../../../redux/slices/analysis/selectors"

export default ({scenarioName, parameterKey, fuelSource}) => {
    const value = useSelector(makeSelectFuelSettings(scenarioName, parameterKey, fuelSource))
    const config = useMemo(() => FuelParameterConfig[parameterKey], [parameterKey])
    const dispatch = useDispatch()
    const handleChange = useCallback((e) => {
        dispatch(updateFuelScenarioSettings({
            scenarioName,
            parameterKey, 
            fuelSource, 
            value: Number(e.target.value)
        }))

    }, [dispatch, updateFuelScenarioSettings])
    return (
        <TextField
            label={`${ config.label }`}
            variant="outlined"
            type="number"
            min={config.min}
            max={config.max}
            step={config.step}
            value={value}
            helperText={config.units}
            onChange={handleChange}
        />

    )


}