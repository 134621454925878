
// Hooks
import { useDispatch, useSelector } from "react-redux"
// MUI
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

///// Umiverse Imports

// Redux
import { FuelParameters, FuelSources, FuelSourceConfig, ScenarioSettingsPageConfig, ScenarioSettingsPage, } from "../../../redux/slices/analysis/consts"

// Components
import ScenarioFuelParameter from "./ScenarioFuelParameter"
import { Header, DividerWrapper } from "./SettingLayout"

export default ({ scenarioName }) => {
    return (
        <Card variant="outlined">
            <CardContent >
                {/* Header for Scenario */}
                <Header name={ScenarioSettingsPageConfig[ScenarioSettingsPage.Fuels].label} />

                {/* Header for Scenario Fuel Source Config */}
                <Grid container spacing={3}>
                    {Object.entries(FuelSourceConfig).map( ([key, {label} ]) => 
                        <Grid key={`${key}-settings`} item container alignItems="center" xs={4}>
                            <DividerWrapper>
                                <Typography variant="overline" style={{margin: "0 0.5rem"}}>
                                    {label}
                                </Typography>
                            </DividerWrapper>
                        </Grid>
                    )}
                </Grid>

                {/* Scenario Fuel Source Controls */}
                {/* Rows = Parameter, Columns = Fuel Source */}
                {FuelParameters.map(( parameterKey, i ) => 
                    <Grid container key={`${ parameterKey }-controls-${i}`} spacing={3}>
                        {FuelSources.map( (fuelSource, j)=> (
                            <Grid 
                                item 
                                xs={4}
                                key={`${scenarioName}-${parameterKey}-${fuelSource}-control-${i}-${j}`} 
                                style={{marginBottom: "0.5rem" }}
                            >
                                <ScenarioFuelParameter 
                                    scenarioName={scenarioName}
                                    parameterKey={parameterKey}
                                    fuelSource={fuelSource}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CardContent>

        </Card>
    )
}

