// Hooks
import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

// MUI
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

///// Umiverse Imports

// Redux
import { buildLUTs } from "../../../redux/utils"
import { Breakdown, BreakdownOptions,MetricOptions } from "../../../redux/slices/analysis/consts"
import { selectBreakdown, selectMetric, selectNormalized, selectScenario, selectTargetMetric } from "../../../redux/slices/analysis/selectors"
import { setBreakdown, setMetric, setNormalized, setTargetMetric } from "../../../redux/slices/analysis/reducers"

const AnalysisDropdownConfig = {
    AdoptionBreakdown: {
        options: BreakdownOptions.filter(opt => [Breakdown.Total, Breakdown.Use].includes(opt.value)),
        selector: selectBreakdown,
        action: setBreakdown,
        label: "Breakdown",
        helperText: "Select how to partition data",
    },
    Breakdown: {
        options: BreakdownOptions,
        selector: selectBreakdown,
        action: setBreakdown,
        label: "Breakdown",
        helperText: "Select how to partition data",
    },
    Normalization: {
        options: [{label: "No normalization (absolute)", value: false}, {label: "Normalized", value: true}],
        selector: selectNormalized,
        action: setNormalized,
        label: "Normalization",
        helperText: "Normalize data by floor area",
    },
    Metric: {
        options: MetricOptions,
        selector: selectMetric,
        action: setMetric,
        label: "Metric",
        helperText: "Select a data series to view",
    },
    TargetMetric: {
        options: MetricOptions,
        selector: selectTargetMetric,
        action: setTargetMetric,
        label: "Metric",
        helperText: "Select a target metric"
    }
}

export const {enum: AnalysisDropdown} = buildLUTs(AnalysisDropdownConfig)

export default ({dropdown}) => {
    const dispatch = useDispatch()

    const allAreSelected = useSelector(state => !selectScenario(state))
    const disabled = allAreSelected && [AnalysisDropdown.Breakdown].includes(dropdown) && dropdown !== AnalysisDropdown.TargetMetric
    const value = useSelector(AnalysisDropdownConfig[dropdown].selector)
    const config = AnalysisDropdownConfig[dropdown]

    const handleChange = useCallback((e) => {
        const action = AnalysisDropdownConfig[dropdown].action
        dispatch(action(e.target.value))
    }, [dropdown, dispatch])
    

    return (
            <FormControl fullWidth size="small">
                <InputLabel id={`${dropdown}-filter-label`} >
                    {config.label}{disabled && " (currently disabled)"}
                </InputLabel>

                <Select
                    labelId={`${dropdown}-filter-label`}
                    id={`${dropdown}-filter`}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {config.options.map(({value, label: optionLabel}) => (
                        <MenuItem 
                            value={value} 
                            key={`${dropdown}-option-${value}`}>{optionLabel}</MenuItem>
                    ))}

                </Select>
                {config.helperText && <FormHelperText>{config.helperText}</FormHelperText>}
            </FormControl>
        
    )
}
