import { useCallback, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { ObjectDataFieldReference } from "./ObjectDataFieldReference";
import { ObjectDataFieldForm } from "./ObjectDataFieldForm";
import  IconButton  from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"


export const LayersControls = (props) => {
    // TODO: Implement validation for window glazing/gas pattern
    const {dispatch} = props
    const {selectedNode, indexedNodes} = props.state
    const valuesArray = useMemo(() => indexedNodes[selectedNode].Layers, 
        [indexedNodes,selectedNode, indexedNodes[selectedNode].Layers])

    const handleAdd = useCallback((index) => {
        const newArray = [...valuesArray]
        newArray.splice(index, 0, JSON.parse(JSON.stringify(newArray[index])))
        const args = {
            type: "update simple field",
            objectId: selectedNode,
            key: "Layers",
            value: newArray,
        }
        dispatch(args)

    }, [dispatch, selectedNode, valuesArray])

    const handleRemove = useCallback((index) => {
        const newArray = [...valuesArray]
        newArray.splice(index, 1)
        const args = [
            {
                type: "update simple field",
                objectId: selectedNode,
                key: "Layers",
                value: newArray
            },
            {
                type: "trigger rebuild graph"
            }
        ]
        dispatch(args)

    }, [dispatch, selectedNode, valuesArray])

    const handleMove = useCallback( (index, dir) => {
        console.log("here")
        const newArray = [ ...valuesArray ]
        console.log(newArray)
        const elem = newArray.splice(index,1)[0]
        newArray.splice(index+dir,0,elem)
        console.log(newArray)
        const args = {
            type: "update simple field",
            objectId: selectedNode,
            key: "Layers",
            value: newArray,
        }
        dispatch(args)

    }, [dispatch, selectedNode, valuesArray, indexedNodes[selectedNode].Layers])

    return (
        <>
            {valuesArray.map((value,i) => 
                <Grid item container xs={12} spacing={1} key={`layer-selector-${i}`} >
                    <Grid item xs={4} >
                        <ObjectDataFieldReference 
                            keyName="Layers" 
                            targetId={value.Material.$ref} 
                            targetLabel={ `Material (Layer ${i})` } 
                            index={i}
                            parameter="Material"
                            {...props}
                        />
                    </Grid>
                    <Grid item xs={3} >
                        <ObjectDataFieldForm 
                            keyName="Layers" 
                            index={i}
                            parameter="Thickness"
                            label={`Thickness [m]`}
                            {...props}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <IconButton onClick={()=>handleAdd(i)}><AddIcon/></IconButton>
                        <IconButton disabled={valuesArray.length===1} onClick={()=>handleRemove(i)}><RemoveIcon/></IconButton>
                        {i !== valuesArray.length-1 && <IconButton onClick={()=>handleMove(i,1)}><KeyboardArrowDownIcon/></IconButton>}
                        {i !== 0 && <IconButton onClick={()=>handleMove(i,-1)}><KeyboardArrowUpIcon/></IconButton>}
                    </Grid>
                </Grid>
            )}
        </>
    );
};
