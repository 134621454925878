import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    initiator: null,
}

const asyncStatusSlice = createSlice({
    name: 'asyncStatus',
    initialState,
    reducers: {
        startAsyncTask: (state, {payload}) => {
            // Configures data for the async request
            const {endpoint, body, method} = payload
            state[payload.endpoint] = null // Clear current results
            state.initiator = endpoint // Set the trigger
            // Store the request config
            state[`${endpoint}Config`] = { 
                body, method
            }
        },
        clearInitiator: (state) => {
            // Indicate that the trigger has been processed
            state.initiator = null
        },
        asyncSuccess: (state, {payload}) => {
            state[payload.endpoint] = payload.result
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    startAsyncTask,
    clearInitiator,
    asyncSuccess
} = asyncStatusSlice.actions

export default asyncStatusSlice.reducer

