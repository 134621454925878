// Hooks
import { useDispatch, useSelector } from "react-redux"

// MUI
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"

///// Umiverse Imports

// Redux
import { FuelParameterConfig, FuelSourceConfig } from "../../../redux/slices/analysis/consts"
import { updateTimeSeriesSetting } from "../../../redux/slices/analysis/reducers"

// Components
import { DividerWrapper, Header } from "./SettingLayout"
import TimeSeriesCSVDropzone from "./TimeSeriesCSVDropzone"

export default () => {
    const dispatch = useDispatch()
    const timeSeriesConfig = useSelector(state => state.analysis.timeSeriesSettings)

	return (
        <Card variant="outlined">
            <CardContent>
                <Header name="Time Series Settings" />
                <Grid container spacing={3} justifyContent="space-between" style={{marginBottom: "2rem"}}>
                        {Object.entries(FuelSourceConfig).map(([fuelSource, {label}]) => (
                            <Grid key={`${fuelSource}-timeSeriesConfig`} item xs={4}>
                                <Grid item container direction="column" alignItems="center" spacing={4} xs={12}>

                                    <Grid item container alignItems="center" spacing={2}>
                                        <DividerWrapper>
                                            <Typography variant="overline">{label}</Typography>
                                        </DividerWrapper>
                                    </Grid>
                                    {Object.entries(FuelParameterConfig).map(([fuelParameter, {label}]) => (
                                        <Grid key={`${fuelSource}-${fuelParameter}-timeSeriesConfig`} item container spacing={4} justifyContent="flex-start">
                                            <Grid item xs={4}>
                                                <ToggleButtonGroup 
                                                    value={timeSeriesConfig[fuelParameter][fuelSource].mode}
                                                    exclusive 
                                                    onChange={(e, value) => dispatch(updateTimeSeriesSetting({fuelParameter, fuelSource, parameter: "mode", value}))}
                                                >
                                                    <ToggleButton value="Exponential">Rate</ToggleButton>
                                                    <ToggleButton value="CSV">CSV</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {timeSeriesConfig[fuelParameter][fuelSource].mode === "Exponential" && (
                                                    <TextField
                                                        onChange={(e) => dispatch(updateTimeSeriesSetting({fuelParameter, fuelSource, parameter: "rate", value: Number(e.target.value) / -100}))}
                                                        fullWidth
                                                        label={`${ label } (% / year)`}
                                                        type="number"
                                                        inputProps={{
                                                            min: -10,
                                                            max: 10,
                                                        }}
                                                        // min={-0.05}
                                                        // max={0.05}
                                                        value={Math.round(timeSeriesConfig[fuelParameter][fuelSource].rate * -100)}
                                                        helperText={"+ growth, - decay"}
                                                    />
                                                )}
                                                {timeSeriesConfig[fuelParameter][fuelSource].mode === "CSV" && (
                                                    <TimeSeriesCSVDropzone fuelParameter={fuelParameter} fuelSource={fuelSource} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </CardContent>
        </Card>
    )
}