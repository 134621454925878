import { useCallback, useMemo } from "react";
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select"
import Tooltip from "@material-ui/core/Tooltip";


export const ObjectDataFieldReference = ({ targetId, targetLabel, state, keyName, index, parameter, buttonText, dispatch, }) => {
    const {indexedNodes, selectedNode} = state
    const _label = targetLabel !== undefined ? targetLabel : keyName
    const _targetId = useMemo(()=>targetId !== undefined ? targetId : indexedNodes[selectedNode][keyName].$ref, [targetId, indexedNodes[selectedNode][keyName], keyName])


    // TODO: Make sure unlinking works, get nested object linking working
    const handleChange = useCallback((e) => {
        const args = {
            type: `relink${parameter ? " parameter at index" : ![null, undefined].includes(index) ? " index" : ""}`,
            sourceId: selectedNode,
            key: keyName,
            targetId: e.target.value,
            oldTargetId: _targetId,
            index,
            parameter,
        }
        dispatch(args)

    }, [dispatch, selectedNode, keyName, index, parameter, _targetId])

    const goToObject = useCallback(() => {
        const args = {
            type: "select node",
            id: _targetId
        }
        dispatch(args)

    }, [dispatch, _targetId]);

    const duplicateObjectAndRelink = useCallback(() => {
        const args = {
            type: "duplicate",
            targetId: _targetId,
            relink: {
                type: `relink${parameter ? " parameter at index" : ![null, undefined].includes(index) ? " index" : ""}`,
                sourceId: selectedNode,
                key: keyName,
                oldTargetId: _targetId,
                index,
                parameter,
            }
        }
        dispatch(args)

    }, [dispatch, _targetId, keyName, index, parameter, selectedNode])

    const nodesOfSameType = useMemo(()=> Object.values(indexedNodes).filter(node=> indexedNodes[selectedNode].class === "WindowConstruction" ? 

            ["GasMaterial","GlazingMaterial"].includes(node.class) 
        : 
            node.class === indexedNodes[_targetId].class
    ), [indexedNodes, selectedNode, indexedNodes[selectedNode].class, indexedNodes[_targetId].class])

    const isNotGas = useMemo(() => indexedNodes[_targetId].class !== "GasMaterial", [indexedNodes, _targetId, indexedNodes[_targetId].class])

    return (
        <Box>
            <Tooltip enterDelay={350} title={`Use the menu to replace the ${indexedNodes[selectedNode].class}'s current ${_label} with an alternate ${indexedNodes[_targetId].class} from the library.`}>

                <FormControl fullWidth >
                    <InputLabel id={ `${_label}-reference-selector-label` }>{_label}</InputLabel>
                    <Select
                        labelId={ `${_label}-reference-selector-label` }
                        id={ `${_label}-reference-selector` }
                        value={_targetId}
                        onChange={handleChange}
                    >
                        {nodesOfSameType.map(node=> <MenuItem key={`reference-selector-menu-${node.$id}`} value={node.$id}>{node.Name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Tooltip>
            <ButtonGroup variant="text" size="small">
                <Tooltip enterDelay={350} title={ `Click to select and edit the ${indexedNodes[selectedNode].class}'s currently assigned ${_label} node.` }>
                    <Button
                        style={{color: "#888"}}
                        onClick={goToObject}
                    >
                        {buttonText ? buttonText : "Edit"}
                    </Button>
                </Tooltip>
                {isNotGas && 
                    <Tooltip enterDelay={350} title={`Click to duplicate the existing ${_label} and link the resulting copy to the current ${indexedNodes[selectedNode].class} as the new ${_label}.`}>
                        <Button
                            style={{color: "#888"}}
                            onClick={duplicateObjectAndRelink}
                        >
                            {buttonText ? buttonText : "Duplicate"}
                        </Button>
                    </Tooltip>
                }
            </ButtonGroup>
        </Box>
    );
};
