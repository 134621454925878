import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux"
import { useDropzone } from "react-dropzone";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ubemIoApi } from "../redux/services/ubem"
import { getNameSelectorByIndex } from "../redux/slices/analysis/selectors";

const editorProps = {
	accept: "application/zip, application/x-zip-compressed, multipart/x-zip, .umi, application/json, .json",
	maxFiles: 1,
}

export const RtkDropzone = ({ mutation, filenameSelector, rows, skipReset, index, accept, maxFiles, selectedFilename, showBrowseButton, label, formData}) => {
    const [trigger, {data, isLoading, isSuccess, isError, reset}] = mutation({fixedCacheKey: index })
    const selectorFilename = useSelector(filenameSelector ? filenameSelector(index) : state => null)
    const dataFilename = data?.filename // stored in json response

    useEffect(() => {
        if (skipReset) return
        reset()
    }, [skipReset])

    const onDrop = useCallback((acceptedFiles) => {
        const data = new FormData();
        data.append("index", index)
        if (formData) {
            Object.entries(formData).forEach(([key, value]) => {
                data.append(key, value)
            })
        }

        acceptedFiles.forEach((file) => {
            data.append("file", file);
        });
        trigger({index, body: data})
    }, [trigger, index]);

    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({ 
        accept: accept ?? "application/zip, application/x-zip-compressed, multipart/x-zip, .umi, application/json, .json",
        maxFiles: maxFiles ?? 1,
        onDrop,
     });

    return (
        <div className="template-browser" {...getRootProps()} >
            {/* {(showBrowseButton || showBrowseButton === undefined) && <Button style={{marginRight: "2rem"}}className="template-browser-button" variant="outlined">Browse...</Button>} */}
            <TextField
                variant="outlined"
                label={label ?? "Template Library"}
                value={dataFilename ? dataFilename : selectedFilename ? selectedFilename : selectorFilename ?  selectorFilename : acceptedFiles.length > 0 ? acceptedFiles.map(file => file.name).join(",\n") : ""}
                InputProps={{
                    readOnly: true
                }}
                multiline={true}
                rows={rows}
                fullWidth={true}
                size="small"
                helperText="Click to upload or drag 'n' drop"
                error={isError ? true : undefined} 
            />
            <input {...getInputProps()} />
        </div>
    );
};