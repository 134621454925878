// Hooks
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// MUI
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

///// Umiverse Imports

// Redux
import { selectContextLayers } from "../../../redux/slices/umibuilder/selectors";
import { ContextLayersConfig } from "../../../redux/slices/umibuilder/consts";
import { useTabNavigation } from "../../../redux/slices/navigation/hooks";
import { setContextLayer } from "../../../redux/slices/umibuilder/reducers";

///// Umiverse Imports

// Redux

export default () => {
    const dispatch = useDispatch()
    const {goToTab} = useTabNavigation()
    const contextLayers = useSelector(selectContextLayers)

    const updateLayer = useCallback((e, value) => {
        dispatch(setContextLayer({layer: e.target.id, value}))
    }, [dispatch])

    return (
        <>

            <Grid container justifyContent="center">
                {Object.entries(contextLayers).map( ([ layer, value ]) => (
                    <FormControlLabel key={`context-layer-toggle-${layer}`} control={<Switch id={layer} onChange={updateLayer} checked={value} color="primary"/>} label={ContextLayersConfig[layer]?.label}/>
                ))}
            </Grid>

            <Grid container justifyContent="center">
                <Button onClick={() => goToTab("export")} variant="contained" color="primary">Confirm</Button>
            </Grid>

        </>
    )
}
