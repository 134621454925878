// Hooks
import toast from "react-hot-toast"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

///// Umiverse Imports

// Redux
import { confirmSettings, setGraphMode, updateScenarioSettingsPageGroup, updateSettingsPageTab } from "../../../redux/slices/analysis/reducers"
import { selectAllSettingsConfirmed, selectSettingsPageScenarioGroup, selectSettingsPageTab, selectSortedScenarioNames } from "../../../redux/slices/analysis/selectors"
import { useTabNavigation } from "../../../redux/slices/navigation/hooks"
import { GraphType, GraphTypeConfig, ScenarioSettingsPages, SettingsPage, SettingsPageConfig, SettingsPages } from "../../../redux/slices/analysis/consts"

// Components
import ScenarioSettings from "../components/ScenarioSettings"
import GoalSettings from "../components/GoalSettings"
import TimeSeriesSettings from "../components/TimeSeriesSettings"
import ScenarioSettingsHeader from "../components/ScenarioSettingsHeader"

const nonScenarioSettingsPages = SettingsPages.filter(page => page !== SettingsPage.Scenario)


export default () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.analysis.data)
    const scenarioNames = useSelector(selectSortedScenarioNames)
    const {goToTab} = useTabNavigation()
    const activeSettingsGroup = useSelector(selectSettingsPageTab)
    const activeScenarioSettingsGroup = useSelector(selectSettingsPageScenarioGroup)
    const allSettingsConfirmed = useSelector(selectAllSettingsConfirmed)
    const settingsPagesWithScenarios = useMemo(() => SettingsPages.map(page => nonScenarioSettingsPages.includes(page) ? page : scenarioNames).flat(), [scenarioNames])

    const advance = useCallback(() => {
        // Handle navigation complete
        const settingsGroupIndex = settingsPagesWithScenarios.findIndex(page => page === activeSettingsGroup)
        const scenarioSettingsIndex = ScenarioSettingsPages.findIndex(page => page === activeScenarioSettingsGroup)

        dispatch(confirmSettings({ 
            settingsGroup: activeSettingsGroup, 
            scenarioSettingsGroup: nonScenarioSettingsPages.includes(activeSettingsGroup) ? undefined : activeScenarioSettingsGroup
        }))
        if (settingsGroupIndex === settingsPagesWithScenarios.length - 1) {
            dispatch(confirmSettings({all: true}))
            goToTab("technology-pathways")
            return
        } 

        // Handle navigation to next settings pages
        // Advance if non-scenario page, or last page in scenario settings groups
        if (nonScenarioSettingsPages.includes(activeSettingsGroup) || (scenarioSettingsIndex === ScenarioSettingsPages.length -1)) {
            const next = settingsPagesWithScenarios[settingsGroupIndex+1]
            dispatch(updateScenarioSettingsPageGroup(ScenarioSettingsPages[0]))
            dispatch(updateSettingsPageTab(next))
            return
        }

        // Otherwise advance to the next scenario settings group
        const next = ScenarioSettingsPages[scenarioSettingsIndex + 1]
        dispatch(updateScenarioSettingsPageGroup(next))
        

    }, [dispatch, scenarioNames, settingsPagesWithScenarios, goToTab, activeSettingsGroup, activeScenarioSettingsGroup, allSettingsConfirmed])

    const backDisabled = useMemo(() => activeSettingsGroup == settingsPagesWithScenarios[0], [activeSettingsGroup])

    const back = useCallback(() => {
        // Handle navigation to next settings pages
        // go back if non-scenario page, or first page in scenario settings groups
        const scenarioSettingsIndex = ScenarioSettingsPages.findIndex(page => page === activeScenarioSettingsGroup)
        if (nonScenarioSettingsPages.includes(activeSettingsGroup) || (scenarioSettingsIndex == 0)) {
            const index = settingsPagesWithScenarios.findIndex(page => page === activeSettingsGroup)
            const next = settingsPagesWithScenarios[(index-1) > -1 ? index-1 : 0]
            dispatch(updateScenarioSettingsPageGroup(ScenarioSettingsPages[ScenarioSettingsPages.length-1]))
            dispatch(updateSettingsPageTab(next))
            return
        }

        // Otherwise go back to the previous scenario settings group
        const next = ScenarioSettingsPages[scenarioSettingsIndex - 1]
        dispatch(updateScenarioSettingsPageGroup(next))
        

    }, [dispatch, scenarioNames, settingsPagesWithScenarios, goToTab, activeSettingsGroup, activeScenarioSettingsGroup, allSettingsConfirmed])

    const handleChangeSettingsTab = useCallback((e, value) => {
        // if (!allSettingsConfirmed) {
        //     toast.error("The first time you visit the settings page, you must confirm each individual settings page by using the 'next' button")
        //     return
        // }
        if (value?.length) dispatch(updateSettingsPageTab(value))

    },[dispatch, allSettingsConfirmed])


	return (
		<Container>
            <Grid container spacing={3} justifyContent="center">
                <Grid item container xs={12} justifyContent="center">
                    <Tooltip 
                        title={!allSettingsConfirmed ? "Until you have confirmed all settings, you must use the 'next' button to advance" : "Select a settings group to edit."}
                        placement="right"
                    >
                        <ToggleButtonGroup  size="small" exclusive value={activeSettingsGroup} onChange={handleChangeSettingsTab}>
                            {Object.entries(SettingsPageConfig).map(([page, {label}]) => page!== SettingsPage.Scenario ? (
                                <ToggleButton key={`${page}-page`} value={page}>{label}</ToggleButton>
                            ) : scenarioNames.map(name => (
                                <ToggleButton key={`${name}-toggle-button`} value={name}>{name}</ToggleButton>
                            )))}
                        </ToggleButtonGroup>
                    </Tooltip>
                </Grid>

                <Grid item container justifyContent="center" spacing={3}>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={back} disabled={backDisabled} startIcon={<NavigateBeforeIcon/>}>Back</Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={advance} endIcon={<NavigateNextIcon/>}>Next</Button>
                    </Grid>
                </Grid>
                {data && scenarioNames.map((scenarioName, i) => activeSettingsGroup === scenarioName && (
                    <>
                    <Grid item xs={12} container justifyContent="center" alignItems="center" direction="row">
                        <ScenarioSettingsHeader usesTrackerControl={i > 0} scenarioName={scenarioName} />
                    </Grid>
                    <Grid item xs={6} key={`${scenarioName}-settings-${i}`} > 
                        <ScenarioSettings scenarioName={scenarioName} />
                    </Grid>
                    </>
                ))}
                {activeSettingsGroup === SettingsPage.Targets && ( 
                    <Grid item xs={6}>
                        <GoalSettings />
                    </Grid> 
                )}
                {activeSettingsGroup === SettingsPage.TimeSeries && (
                    <Grid item xs={12}>
                        <TimeSeriesSettings />
                    </Grid>
                )}
                {!allSettingsConfirmed && (
                    <Grid item container xs={12} justifyContent="center">
                        <Button color="primary" variant="contained" onClick={() => {dispatch(confirmSettings({all: true})); dispatch(setGraphMode(GraphType.bar)); goToTab("technology-pathways")}}>Confirm</Button>
                    </Grid>
                )}
                {allSettingsConfirmed && (
                    <Grid item container xs={12} justifyContent="center" alignItems="center" spacing={3}>
                        {Object.entries(GraphTypeConfig).map(([graphType, {label}]) => (
                            <Grid item key={`go-to-${graphType}`}>
                                <Button onClick={() => {dispatch(setGraphMode(graphType)); goToTab("technology-pathways")}} variant="outlined">Plot {label}</Button>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
		</Container>
	)
}