// Hooks etc
import { useEffect } from "react";
import {
    Switch,
    Route,
    useHistory,
    useParams,
    useRouteMatch,
    useLocation,
    Redirect
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// MUI
// import PublishIcon from "@material-ui/icons/Publish"
// import SettingsIcon from "@material-ui/icons/Settings"
// import BarChartIcon from "@material-ui/icons/BarChart"
// import ShowChartIcon from "@material-ui/icons/ShowChart"
// import HomeWorkIcon from "@material-ui/icons/HomeWork"
// import ShareIcon from "@material-ui/icons/Share"

// Umiverse Imports

// Redux
import { setError } from "../../redux/slices/error/reducers";
import { Entrypoint, Entrypoints } from "../../redux/slices/umibuilder/consts";
import { setEntrypoint } from "../../redux/slices/umibuilder/reducers";
import {
    isLoading,
    selectDisabledTabs,
    selectEntrypoint
} from "../../redux/slices/umibuilder/selectors";

// Components
import { Layout } from "../Layout";
import UploadPage from "./pages/UploadPage";
import KeyfieldsPage from "./pages/KeyfieldsPage";
import OrganizePage from "./pages/OrganizePage";
import AssignTemplatesPage from "./pages/AssignTemplatesPage";
import ContextLayersPage from "./pages/ContextLayersPage";
import ExportPage from "./pages/ExportPage";
import ReferenceDownloadPage from "./pages/ReferenceDownloadPage";
import CZPage from "./pages/CZPage";

export default ({ children, tabValue }) => {
    const dispatch = useDispatch();

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const queryEntrypoint = queryParams.get("entrypoint");

    // Conditionally hide tabs based off of entrypoint
    const entrypoint = useSelector(selectEntrypoint);
    const disabledTabs = useSelector(selectDisabledTabs);
    const loading = useSelector(isLoading);
    // const error = useSelector(state => state.analysis.error)
    // const hasData = useSelector(state => state.analysis.data !== null)
    const { path } = useRouteMatch();

    const tabsConfig = {
        tabs: {
            upload: {
                title: {
                    default: "Upload GIS Data",
                    [Entrypoint.uio]: "Upload Exchange File"
                },
                description: {
                    default:
                        "Upload GIS files to begin the workflow. " +
                        "The GIS file should contain the building footprints of interest. " +
                        "UBEM.io can read almost any vector-based spatial data format including ESRI shapefile, GeoJSON files " +
                        "and more.",

                    [Entrypoint.uio]:
                        "Upload an UBEM.io exchange file to begin the workflow. " +
                        "The ubem.io exchange file includes the original shapefile/GIS data along with previous " +
                        "key field selections, and will have been generated by a previous usage of this workflow. " +
                        "Once uploaded, you will skip ahead in the workflow to confirm the key field selections and " +
                        "then begin assigning templates to archetypes."
                },
                label: "Upload",
                disabled: false,
                // icon: <PublishIcon />,
                component: <UploadPage />
            },
            keyfields: {
                title: {
                    default: "Select and Match Key Fields",
                    [Entrypoint.uio]: "Validate"
                },
                description: {
                    default:
                        "Check the contents of your shapefile and select metadata fields in your GIS file to segment the building stock." +
                        "If there are broken polygons or building footprints with irregular shapes, " +
                        "you may wish to examine the shapefile in detail and repair the footprints " +
                        "using GIS software such as QGIS or ArcGIS.",
                    [Entrypoint.uio]:
                        "Validate that there are no errors (or minimal errors) in the exchange file. If there are errors, " +
                        "consult with your GIS manager about resolving them.  UBEM.io will remove buildings with errors from the final UMI file."
                },
                label: {
                    default: "Keyfields",
                    [Entrypoint.uio]: "Validate"
                },
                disabled: false,
                // icon: <PublishIcon />,
                component: <KeyfieldsPage />
            },
            organize: {
                title: "Organize Buildings",
                description:
                    "Sort buildings into groups that you wish to be of the same archetype / be assigned " +
                    "the same building template for simulation",
                label: "Organize",
                disabled: false,
                hidden: [Entrypoint.gis].includes(entrypoint),
                // icon: <PublishIcon />,
                component: <OrganizePage />
            },
            assign: {
                title: "Assign Values & Templates",
                description:
                    "Assign building templates to each of the groups you identified and edit window-to-wall ratios.",
                label: "Assign",
                disabled: false,
                hidden: [Entrypoint.gis].includes(entrypoint),
                // icon: <PublishIcon />,
                component: <AssignTemplatesPage />
            },
            context: {
                title: "Context Layers",
                description:
                    "Choose which additional context layers to add to the urban model.",
                label: "Context",
                disabled: false,
                hidden: [Entrypoint.gis].includes(entrypoint),
                // icon: <PublishIcon />,
                component: <ContextLayersPage />
            },
            export: {
                title: "Verify & Export",
                description:
                    "Verify and download the UMI model of your building stock. " +
                    "The table below is a summary of simulation parameters for each building.",
                label: "Export",
                disabled: false,
                hidden: [Entrypoint.gis].includes(entrypoint),
                // icon: <PublishIcon />,
                component: <ExportPage />
            }
        },
        defaultTab: "upload",
        numberTabs: true,
        root: {
            title: "UMI Builder",
            description: "Create UMI files from GIS data",
            component: <div>Help info coming</div>,
            redirect: true
        },
        context: entrypoint,
        otherRoutes: {
            "reference-library": {
                component: <ReferenceDownloadPage />
            },
            "cz-lookup": {
                component: <CZPage />
            }
        }
    };

    // Disable tabs based on state
    Object.entries(tabsConfig.tabs).forEach(([name, tab]) => {
        tab.disabled = disabledTabs[name];
    });

    // Set the entrypoint based off of query param if available
    useEffect(() => {
        if (Entrypoints.includes(queryEntrypoint)) {
            dispatch(setEntrypoint({ entrypoint: queryEntrypoint }));
        } else if (queryEntrypoint) {
            dispatch(
                setError({
                    error: { message: "Invalid entrypoint requested." }
                })
            );
        }
    }, [queryEntrypoint, dispatch]);

    return (
        <Layout tabsConfig={tabsConfig} loading={loading}>
            {children}
        </Layout>
    );
};
