import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ObjectDataFieldForm } from './ObjectDataFieldForm';
import { useMemo, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const MetadataFields = [
    "Name",
    "Comments",
    "Category",
    "DataSource",
    "AuthorEmails",
    "Authors"
]

export const AssemblyInfoFields = [
    "AssemblyCarbon",
    "AssemblyEnergy",
    "DisassemblyCarbon",
    "DisassemblyEnergy",
    "AssemblyCost",
]

export const ObjectAssemblyInfo = (props) => {
    return <ObjectSimpleFieldGroup groupLabel="Assembly Properties" groupName="assembly-info" groupFields={AssemblyInfoFields} {...props}/>
}

export const ObjectMetadata = (props) => {
    return <ObjectSimpleFieldGroup groupLabel="Metadata" groupName="metadata" groupFields={MetadataFields} {...props}/>
}

export const ObjectSimpleFieldGroup = (props) => {
    const classes = useStyles();
    const { selectedNode, indexedNodes} = props.state
    const includesFields = useMemo(()=> props.groupFields.filter(field=>Object.keys(indexedNodes[selectedNode]).includes(field)).length > 0, [selectedNode])
	return (includesFields &&
        <Box>

        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`${props.groupName}-accordion`}
            >
                <Typography className={classes.heading}>{props.groupLabel}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {props.groupFields.map(keyName => !["AuthorEmails", "Authors"].includes(keyName) &&
                        <Grid key={`object-${selectedNode}-${props.groupName}-${keyName}`} item xs={3}>
                            <ObjectDataFieldForm keyName={keyName} {...props} />
                        </Grid>
                    )}
                    {indexedNodes[selectedNode].class === "BuildingTemplate" && props.groupName === "metadata" && <AuthorsTable {...props}/>}
                </Grid>
            </AccordionDetails>
        </Accordion>
        </Box>
    )
}

export const AuthorsTable = (props) => {
    const {state, dispatch} = props
    const {selectedNode, indexedNodes} = state
    const authors = useMemo(() => indexedNodes[selectedNode].Authors, [selectedNode, indexedNodes, indexedNodes[selectedNode].Authors])
    const authorsValue = useMemo(() => authors.join("\n"), [authors])
    const emails = useMemo(() => indexedNodes[selectedNode].AuthorEmails, [selectedNode, indexedNodes, indexedNodes[selectedNode].AuthorEmails])
    const emailsValue = useMemo(() => emails.join("\n"), [emails])

    const handleChange = useCallback((key,e) => {
        const value = e.target.value.split("\n")
        const args = {
            type: "update simple field",
            objectId: selectedNode,
            key,
            value
        }
        dispatch(args)
    }, [indexedNodes[selectedNode].AuthorEmails, indexedNodes, selectedNode, dispatch])

    const handleEmails = useCallback((e) => handleChange("AuthorEmails", e), [handleChange])
    const handleAuthors = useCallback((e) => handleChange("Authors", e), [handleChange])

    return ( 
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextField onChange={handleAuthors} variant="outlined" fullWidth multiline rows={5} label={"Authors"} helperText={"1 per line"} value={authorsValue}/>
            </Grid>
            <Grid item xs={6}>
                <TextField onChange={handleEmails} variant="outlined" fullWidth multiline rows={5} label={"Author Emails"} helperText={"1 per line"} value={emailsValue}/>
            </Grid>

        </Grid> 
    )
}