// Hooks
import { useSelector } from "react-redux"

// MUI
import Typography from "@material-ui/core/Typography"

///// Umiverse Imports

// Redux
import { makeSelectTracksBaseline, selectSettingsPageScenarioGroup, selectSortedScenarioNames } from "../../../redux/slices/analysis/selectors"

// Components
import ScenarioSettingsFuels from "./ScenarioSettingsFuels"
import ScenarioSettingsEndUses from "./ScenarioSettingsEndUses"
import ScenarioSettingsPV from "./ScenarioSettingsPV"
import { ScenarioSettingsPage, ScenarioSettingsPageConfig } from "../../../redux/slices/analysis/consts"

export default ({ scenarioName }) => {
    const scenarioSettingsGroup = useSelector(selectSettingsPageScenarioGroup)
    const tracksBaseline = useSelector(makeSelectTracksBaseline({scenarioName, settingsGroup: scenarioSettingsGroup}))
    const baselineScenario = useSelector(selectSortedScenarioNames)[0]
    return !tracksBaseline || scenarioName === baselineScenario ? (
        <>
            {scenarioSettingsGroup === ScenarioSettingsPage.Fuels && (
                <ScenarioSettingsFuels scenarioName={scenarioName}/>
            )}
            {scenarioSettingsGroup === ScenarioSettingsPage.EndUses && (
                <ScenarioSettingsEndUses scenarioName={scenarioName}/>
            )}
            {scenarioSettingsGroup === ScenarioSettingsPage.PV && (
                <ScenarioSettingsPV scenarioName={scenarioName}/>
            )}
        </>
    ) : (
        <Typography variant="overline">This scenario is tracking the Baseline's{ baselineScenario.toLowerCase() !== "baseline" ? ` (${baselineScenario}) ` : " " }{ScenarioSettingsPageConfig[scenarioSettingsGroup].label} settings.</Typography>
    )
}

