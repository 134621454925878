import { useCallback, useReducer, useState } from "react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    flexDirection:"row",
  },
  formInput: {
    marginRight: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const propsReducer = (state, action) => {
    const {type, name, data} = action
    switch (type) {
        case "changeName":
            return state
        case "removeProp":
            return state
        case "updateProp":
            const newState = {
                ...state,
                [name]: {
                    ...state[name],
                    ...data
                }
            }
            return newState
        case "createProp":
            const propId = `user_prop_${Object.keys(state).length}`
            return {
                ...state,
                [propId]: {
                    type: "number",
                    min: 0,
                    max: 20,
                    step: 0.01,
                    defaultValue: 2,
                    label: "My Prop",
                    uiType: "slider"
                }
            }
    }

}

const actionsReducer = (state, reducerAction) => {
    const {type, name, data} = reducerAction
    switch (type) {
        case "createAction":
            const actionId = `user_action_${Object.keys(state).length}`
            return {
                ...state,
                [actionId]: {
                    object_address: "Perimeter.Conditioning.CoolingSetpoint",
                    modifier_prop: "user_prop_0",
                }

            }
        case "updateAction":
            return {
                ...state,
                [name]: {
                    ...state[name],
                    ...data
                }
            }
    }
}

export const ConfigureCustomMeasure = ({setMeasuresLib, setSelectedTab}) => {

    const classes = useStyles()

    const [modifierProps, configModifierPropDispatch] = useReducer(propsReducer, {
        user_prop_0: {
            type: "number",
            min: 0,
            max: 20,
            step: 0.01,
            defaultValue: 2,
            label: "My Prop",
            uiType: "slider"
        }
    })

    const [modifierActions, configModifierActionDispatch] = useReducer(actionsReducer, {
        user_action_0: {
            object_address: "Perimeter.Conditioning.HeatingSetpoint",
            modifier_prop: "user_prop_0",
        }
    })

    const [measureName, setMeasureName] = useState("User Measure")

    const saveMeasure = useCallback(() => {
        setMeasuresLib(prev => {
            const existingCustomCount = Object.values(prev).filter(val=> val.custom).length
            const measureId = `user_measure_${existingCustomCount}`
            return {
            ...prev,
            [measureId]: {
                label: measureName,
                custom: JSON.parse(JSON.stringify(modifierActions)),
                presets: [],
                props: JSON.parse(JSON.stringify(modifierProps)),

            }
        }})
        setSelectedTab("Upgrade")

    }, [setMeasuresLib, setSelectedTab, measureName, modifierProps, modifierActions])
	return (
	<Card variant="outlined">
		<CardContent>
			<Typography>
				Define Custom Measure
			</Typography>
            <FormControl className={classes.formControl}> 
                <TextField 
                    id={"define-measure-name"}
                    onChange={(event) => setMeasureName(event.target.value)} 
                    label="Measure Name" 
                    variant="standard" 
                    value={measureName}
                    className={classes.formInput}
                />
                <Button className={classes.formInput} variant="outlined" onClick={()=>configModifierPropDispatch({type:"createProp"})} size="small">Add Prop</Button>
                <Button className={classes.formInput} variant="outlined" onClick={()=>configModifierActionDispatch({type:"createAction"})} size="small">Add Action</Button>
                <Button className={classes.formInput} variant="outlined" onClick={saveMeasure} size="small">Enable Measure</Button>
            </FormControl>
            <Divider />
            {Object.entries(modifierProps).map(([name, config]) => <ConfigModifierProp key={`config-modifier-prop-${name}`}name={name} config={config} dispatch={configModifierPropDispatch}/>)}
            <Divider />
            {Object.entries(modifierActions).map(([name, config]) => <ConfigModifierAction key={`config-modifier-Action-${name}`}name={name} config={config} modifierProps={modifierProps} dispatch={configModifierActionDispatch}/>)}
		</CardContent>

	</Card>
	)
}

const ConfigModifierProp = ({name, config, dispatch}) => {
    const classes = useStyles()

    const handleChangePropName = useCallback((event)=>{
        dispatch({type: "updateProp", name, data: {"label": event.target.value}})
    }, [dispatch, name])
    const handleChangePropMin = useCallback((event)=>{
        dispatch({type: "updateProp", name, data: {"min": Number(event.target.value)}})
    }, [dispatch, name])
    const handleChangePropMax = useCallback((event)=>{
        dispatch({type: "updateProp", name, data: {"max": Number(event.target.value)}})
    }, [dispatch, name])
    const handleChangePropDefault = useCallback((event)=>{
        dispatch({type: "updateProp", name, data: {"defaultValue": Number(event.target.value)}})
    }, [dispatch, name])

    return (
        <>
            <FormControl className={classes.formControl}> 
                <TextField 
                    id={`${name}-prop-define-name`}
                    onChange={handleChangePropName} 
                    label="Prop Name" 
                    variant="standard" 
                    value={config.label}
                    className={classes.formInput}
                />
                <TextField 
                    id={`${name}-prop-define-default`}
                    onChange={handleChangePropDefault} 
                    label="Default Value" 
                    className={classes.formInput}
                    type="number"
                    variant="standard" 
                    value={config.defaultValue}
                />
                <TextField 
                    id={`${name}-prop-define-min`}
                    onChange={handleChangePropMin} 
                    label="Minimum" 
                    type="number"
                    variant="standard" 
                    value={config.min}
                    className={classes.formInput}
                />
                <TextField 
                    id={`${name}-prop-define-max`}
                    onChange={handleChangePropMax} 
                    label="Maximum" 
                    type="number"
                    variant="standard" 
                    value={config.max}
                    className={classes.formInput}
                />
            </FormControl>
        </>
    )
}

const ConfigModifierAction = ({name, config, dispatch, modifierProps}) => {
    const classes = useStyles()
    const handleChangePropReference = useCallback((event)=>{
        dispatch({type: "updateAction", name, data: {"modifier_prop": event.target.value}})
    }, [dispatch, name])

    const handleChangeTargetAddress = useCallback((event)=>{
        dispatch({type: "updateAction", name, data: {"object_address": event.target.value}})
    }, [dispatch, name])

    return (
        <>
            <FormControl className={classes.formControl}> 
                <InputLabel id={ `${name}-action-prop-selector` }>Prop to Reference</InputLabel>
                <Select
                    labelId= { `${name}-action-prop-selector`  }
                    id={ `${name}-action-prop-selector-id`  }
                    value={config.modifier_prop}
                    className={classes.formInput}
                    onChange={handleChangePropReference}
                >
                    {Object.entries(modifierProps).map( ([propName,propConfig]) => ( 
                        <MenuItem key={`${name}-${propName}-menuitem`} value={propName}>
                            {propConfig.label}
                        </MenuItem> 
                    ))}
                </Select>
                <TextField 
                    id={`${name}-action-define-target`}
                    onChange={handleChangeTargetAddress} 
                    label="Target Address" 
                    variant="standard" 
                    value={config.object_address}
                    className={classes.formInput}
                />
            </FormControl>
        </>
    )
}