import { buildLUTs } from "../../utils"

export const EntrypointsConfig = {
	gis: {
	},
    uio: {
    },
    full: {
    }
}
export const {enum: Entrypoint, arr: Entrypoints} = buildLUTs(EntrypointsConfig)

export const KeyFieldsConfig = {
    id: {
        propTypes: "shapefileProps",
        isRequired: false,
        label: "Unique Identifier (ID)",
        help: "Unique identifier assigned to each building / building footprint polygon " + 
        "(usually numeric or alphanumeric). This unique identifier allows tracking of specific buildings. " +
        "If there is no relevant field in the GIS (.shp) file, select not available and the identifier "+
        "will be generated in a new column sequentially.",
        hover: "Select which column corresponds to the unique identifiers for all buildings (if blank, IDs will be auto-assigned).",
        legacyName: "selectedIDField",
    },
    height: {
        propTypes: "numericalProps",
        isRequired: true,
        label: "Building Height [m]",
        help: "The height of each building.  Buildings <2m will be ignored.  Buildings between 2m and 3m will have their heights set to 3m.",
        hover: "Select which column corresponds to the building height for all buildings (required).",
        legacyName: "selectedHeightField",
    },
    primary: {
        propTypes: "categoricalProps", // Which prop list to look at
        isRequired: true,
        label: "Primary Grouping (e.g. Program Type)",
        help: "Primary field to group / segment your building stock. For example, " +
        "modelers commonly segment building stock by Program Type, such as residential, commercial, mixed-use, institutional. ",
        hover: "Select which column to segment the building stock by (required).",
        legacyName: "selectedPrimaryField",
    },
    secondary: {
        propTypes: "numericalProps",
        isRequired: false,
        label: "Secondary Grouping (e.g. Age)",
        help: "Secondary field to group / segment your building stock. One common segmentation is" + 
        "by Building Age or vintage. Building Age may be a combination of original age and effective age, "+
        "where original age refers to the age of the building based on year of construction, and effective "+
        "age is a relative judgement based on year of last major renovation / retrofit.",
        hover: "Select which column to subdivide the building stock by (optional).",
        legacyName: "selectedSecondaryField",
    }
}

export const {enum: KeyField, arr: KeyFields} = buildLUTs(KeyFieldsConfig)

const MapErrorModesConfig = {
    id: {

    },
    height: {

    },
    primary: {

    },
    secondary: {

    },
    overlap: {

    },
    all: {

    }


}

export const {enum: MapColorMode, arr: MapColorModes, options: MapColorModeOptions} = buildLUTs(MapErrorModesConfig)

export const ContextLayersConfig = {
    streets: {label: "Street Network",}, 
    trees: {label: "Trees"}, 
    parks:{label: "Parks"}, 
}

export const {enum: ContextLayer, arr: ContextLayers } = buildLUTs(ContextLayersConfig)