// Hooks
import toast from "react-hot-toast"
import { useCallback, useState } from "react"

// MUI
import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"


///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"

// Components
import TemplateTable from "../components/TemplateTable"
import { mutationFixedCacheKeys } from "../../../redux/slices/umibuilder/endpoints"

const { useMutation: useExportReferenceLibrary } = ubemIoApi.endpoints.exportReferenceLibrary
const { useMutation: uploadUserTemplateLib } = ubemIoApi.endpoints.uploadUserTemplateLibUmibuilder

const useStyles = makeStyles((theme) => ({
    exportButton: {
        width: "100%",
        margin: "2rem"

    }
}))

export default () => {
    const classes = useStyles()
    const [templates, setTemplates] = useState([])
    const [exportLibrary, {isLoading}] = useExportReferenceLibrary({fixedCacheKey: mutationFixedCacheKeys.exportReferenceLibrary})
    const [_, {data: userTemplateData}] = uploadUserTemplateLib({fixedCacheKey: mutationFixedCacheKeys.uploadUserTemplateLib})

    const handleExport = useCallback(() => {
        exportLibrary({templates, user: userTemplateData ? true : false})
    }, [templates, userTemplateData])

    const assign = useCallback((template) => {
        if (template.row.Type.includes("User")) {
            toast("User-provided templates will be added by default.")
            return
        }
        if (templates.length > 15) {
            toast.error("Limited to 15 templates in custom download.  To download the complete library for a climate zone, please use the static downloader [COMING SOON].")
            return
        }
        setTemplates((prev) => {
            if (prev.findIndex(obj => obj.id ===template.id) === -1) {
                const newTemplates = [...prev, template]
                return newTemplates.sort((a,b) => a.id > b.id)
            } else {
                return [...prev]
            }
        })
    }, [setTemplates, templates])

    const handleChipDelete = useCallback((templateId) => {
        setTemplates(prev => {
            const index = prev.findIndex(({ id }) => id === templateId)
            if (index !== -1) prev.splice(index, 1)
            return [...prev]
        })
    }, [setTemplates])

	return (
        <>
            <Grid container justifyContent="center" spacing={3}>

                <Grid item xs={8}>
                    <TemplateTable assign={assign}/>
                </Grid>

                {templates.length > 0 && (

                    <Grid item xs={4}>

                        <Grid item container spacing={2} justifyContent="center" xs>
                            <Button disabled={isLoading} onClick={handleExport} className={classes.exportButton} color="primary" variant="contained">Download</Button>
                        </Grid>

                        <Grid item xs container spacing={2} justifyContent="center">
                            {templates.map((template => (
                                <Grid item key={template.id}>
                                    <Chip size="small" onDelete={() => handleChipDelete(template.id)} label={template.id}/>
                                </Grid>
                            )))}
                        </Grid>

                    </Grid>
                )}
                
            </Grid>

        </>
    )
}