// Hooks
import { useState } from "react"

// MUI
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

///// Umiverse Imports

// Components
import SubdivideDialog from "./SubdivideDialog"
import SecondaryFieldGroup from "./SecondaryFieldGroup"


export default ({primaryGroup}) => {
    const field = primaryGroup.field
    const [open, setOpen] = useState(false)
    const archetypeIDs = primaryGroup.groups.map(({archetypeID}) => archetypeID)
	return (
        <Grid item container>
            {/* Header */}
            <Grid item container xs={12} alignItems="center" justifyContent="space-around" spacing={1}>
                <Grid item xs={12} >
                    <Box bgcolor="primary.light" padding="0.5rem">
                        <Grid container justifyContent="center" spacing={2} alignItems="center">
                            <Typography variant="overline" style={{textAlign: "center", color: "white", marginRight: "0.5rem"}}>Primary Field Value:</Typography>
                            <Typography variant="caption" style={{textAlign: "center", color: "white"}}>{field}</Typography>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={4} justifyContent="center" container>
                    <Typography variant="overline">Archetype ID</Typography>
                </Grid>
                <Grid item xs={4} justifyContent="center" container>
                    <SubdivideDialog primaryGroup={primaryGroup} open={open} setOpen={setOpen} />
                </Grid>
                <Grid item xs={4} justifyContent="center" container>
                    <Typography variant="overline">Building Count</Typography>
                </Grid>
            </Grid>

            {/* Groups */}
            <Grid item container xs={12} alignItems="center" justifyContent="space-between" >
                {primaryGroup.groups.map((group, index) => (
                    <SecondaryFieldGroup {...group} archetypeIDs={archetypeIDs} index={index} key={`group-${field}- ${group.secondaryMin}-${index}`}/>
                ))}
            </Grid>
        </Grid>
    )
}