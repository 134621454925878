// Hooks
import { useCallback, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"

// MUI
import Tooltip from "@material-ui/core/Tooltip"
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit"
import Grid from "@material-ui/core/Grid";
import { DataGrid } from "@mui/x-data-grid"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { useNavigationEffect } from "../../../redux/slices/navigation/hooks";
import { startAsyncTask } from "../../../redux/slices/async-processing/reducers";
import { AsyncTask } from "../../../redux/slices/async-processing/consts";
import { changeArchetypeField } from "../../../redux/slices/umibuilder/reducers";
import { isLoading, selectGroupData, selectSummaryData } from "../../../redux/slices/umibuilder/selectors"
import { mutationFixedCacheKeys } from "../../../redux/slices/umibuilder/endpoints"

// Components
import AssignTemplateDialog from "../components/AssignTemplateDialog";

const { useMutation: mutateOrganizingData } = ubemIoApi.endpoints.sendGroupData

const columns = [
  {
    field: 'primaryField',
    headerName: 'Field',
    hide: true,
  },
  {
    field: 'archetypeID',
    headerName: 'Archetype ID',
    minWidth: 300,
    editable: false,
  },
  {
    field: 'WW',
    headerName: 'WWR',
    description: "Window-to-Wall Ratio",
    type: "number",
    minWidth: 140,
    editable: true,
    min: 0,
    max: 100,
    valueFormatter: ({value}) => `${value}%`,
    renderCell: ({value, error}) => (
        <Tooltip title="Double-click to edit"><Typography>{`${value}%`}</Typography></Tooltip>
    )
  },
  {
    field: 'template',
    headerName: 'Template',
    minWidth: 400,
    flex: 1,
    editable: false,
    renderCell: ({value, id}) => (
        <>
            <Button style={{margin: "1rem"}}size="small" variant="outlined" color="primary" startIcon={<EditIcon />}>
                Edit
            </Button>
            <Typography variant="overline">{value === "N/A" || !value ? "N/A (shading)" : value}</Typography>
        </>
    )
  },
];


export default () => {

    const dispatch = useDispatch()
    const loading = useSelector(isLoading)
    const allGroups = useSelector(selectGroupData)
    const summaryData = useSelector(selectSummaryData)


    const [openDialog, setOpenDialog] = useState(false)
    const [templateAssignmentContext, setTemplateAssignmentContext]= useState(null) // simple context for the last clicked template

    const [trigger] = mutateOrganizingData({fixedCacheKey: mutationFixedCacheKeys.sendGroupData})

    // Change to context layers page when final archetype assignment is complete
    useNavigationEffect({destination: "context", condition: summaryData})

    // Flattened group data
    const data = useMemo(() => allGroups.map(({groups}) => groups).flat().map(group => ({...group, id: `${group.primaryField}/UBEMIO/${group.archetypeID}`})), [allGroups])




    // Update archetype group data in backend when values change
    // TODO: maybe only send on confirm?
    useEffect(() => {
       trigger({groups: allGroups}) 
    }, [allGroups])

    // Trigger async processing
    const assignBuildingsToArchetypes = useCallback(() => {
        dispatch(startAsyncTask({endpoint: AsyncTask.assignBuildingsToArchetypes}))
    }, [dispatch])

    // Handle confirming a cell
    const handleCommitCell = useCallback((model ) => {
        const [field, archetypeID] = model.id.split("/UBEMIO/")
        const {field: key, value} = model
        dispatch(changeArchetypeField({field, archetypeID, key, value}))
    }, [dispatch])

    // Editing model allows rejecting edits etc
    const [editingModel, setEditingModel] = useState({})

    // Validate WWR is 0-100 (#TODO: show better error when invalid)
    const handleEditModel = useCallback((model) => {
        const newModel = {...model}
        Object.keys(newModel).forEach(id => {
            if (newModel[id].WW) {
                newModel[id].WW = {...newModel[id].WW, error: newModel[id].WW.value < 0 || newModel[id].WW.value > 100}
            }
        })
        setEditingModel(newModel)
    }, [setEditingModel])


    const handleOpenDialog = useCallback(({row, field }) => {
        const {primaryField, archetypeID} = row
        if (field === "template") {
            setTemplateAssignmentContext({
                archetypeID,
                primaryField
            })
            setOpenDialog(true)
        }
    }, [setTemplateAssignmentContext, setOpenDialog])

    return (
        <Grid container justifyContent="center" spacing={2} direction="column">
            <div style={{ display: 'flex', height: '100%' }}>
                <AssignTemplateDialog open={openDialog} setOpen={setOpenDialog} {...templateAssignmentContext} />
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={25}
                        autoHeight
                        onEditRowsModelChange={handleEditModel}
                        onCellEditCommit={handleCommitCell}
                        editRowsModel={editingModel}
                        onCellClick={handleOpenDialog}
                        // density="compact"
                    />
                </div>
            </div>
            <Button 
                style={{marginBottom: "3rem" }}
                variant="contained" 
                color="primary" 
                onClick={assignBuildingsToArchetypes}
                disabled={loading}
            >
                Confirm
            </Button>
        </Grid>
    )
}