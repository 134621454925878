// Hooks
import { useCallback } from "react"
import { useDispatch } from "react-redux"

// MUI
import Button from "@material-ui/core/Button"

///// Umiverse Imports

// Redux
import { useTabNavigation } from "../redux/slices/navigation/hooks"

export default ({mode, text, link, action, callback, handleClose, ButtonProps}) => {
    const dispatch = useDispatch()
    const { goToTab } = useTabNavigation()

    const handleClick = useCallback(() => {
        if (action) {
            dispatch(action)
        }
        if (callback) {
            callback()
        }
        switch (mode) {
            case "root":
                goToTab(".")
                break
            case "relative":
                goToTab(`./${link}`)
                break
            case "to":
                goToTab(link)
                break
        }
        handleClose()

    }, [handleClose, action, mode, goToTab, text, link, callback])
    return (
        <Button {...ButtonProps} size="small" variant="contained" color="primary" onClick={handleClick}>{text}</Button>

    )
}