// Hooks
import { useDispatch, useSelector } from "react-redux"
// MUI
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"

///// Umiverse Imports

// Redux
import {  EndUseParameterConfig, ScenarioSettingsPage, ScenarioSettingsPageConfig } from "../../../redux/slices/analysis/consts"

// Components
import ScenarioEndUseParameter from "./ScenarioEndUseParameter"
import { Header, DividerWrapper } from "./SettingLayout"

export default ({ scenarioName }) => {
    return (
        <Card variant="outlined">
            <CardContent >
                {/* Header for Scenario */}
                <Header name={ScenarioSettingsPageConfig[ScenarioSettingsPage.EndUses].label} />

                <Grid container spacing={3}>
                    {Object.entries(EndUseParameterConfig).map( ([parameterKey, {usedBy}], i) => 
                        usedBy.map( (endUse, j) => (
                            <Grid key={`${scenarioName}-${parameterKey}-selector-${i}-${j}`}  item xs={6}>
                                <ScenarioEndUseParameter scenarioName={scenarioName} endUse={endUse} parameter={parameterKey}/>
                            </Grid>
                        )
                    ))}

                </Grid>

            </CardContent>

        </Card>
    )
}

