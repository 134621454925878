// Hooks
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles"

///// Umiverse Imports

// Redux
import { clearData, clearReports } from "../../../redux/slices/analysis/reducers";
import { useTabNavigation } from "../../../redux/slices/navigation/hooks";

export const useStyles = makeStyles(theme => ({
    descriptiveText: {
        marginBottom: "0.5rem",
    },
    button: {
        margin: "0.5rem",
        marginLeft: 0,
    }
}))

export default () => {
    const reports = useSelector(state => state.analysis.reports);
    const dispatch = useDispatch();
    const history = useHistory();
    const {goToTab} = useTabNavigation()

    const ignoreErrorsCb = useCallback(() => {
        dispatch(clearReports());
        goToTab("settings")
    }, [dispatch, goToTab]);

    const restartCb = useCallback(() => {
        dispatch(clearData());

    }, [dispatch]);

    const titleVariant = "overline";
    const classes = useStyles();

    return reports && (
        <Grid container spacing={1} >
            <Grid item xs={12}>
                <Typography variant="body2">
                    The uploaded files are missing data.  Would you like to proceed?
                </Typography>
                <Button onClick={ignoreErrorsCb} className={classes.button} variant="outlined">Continue</Button>
                <Button onClick={restartCb} className={classes.button} variant="outlined">Restart</Button>
            </Grid>
            {Object.entries(reports.IntersectionReport).map(([scenarioName, missingData], i) => missingData.length > 0 && (
                <Grid key={`${scenarioName}-ix-report-${i}`} item xs={12}>
                    <Typography variant={titleVariant}>
                        {scenarioName}
                    </Typography>
                    <Typography variant="body2" className={classes.descriptiveText}>
                        The following buildings are not present in all of the other scenarios:
                    </Typography>
                    <Typography variant="body2" className={classes.descriptiveText}>
                        <em>
                            <strong>
                                {missingData.map(data => data.Name).join(", ")}
                            </strong>
                        </em>
                    </Typography>
                    {/* {missingData.map(({ MissingIn, Name }) => )} */}
                </Grid>
            ))}
            {Object.entries(reports.MissingData).map(([scenarioName, { series, buildings, floor_areas }], i) => (floor_areas.length > 0 || series.length > 0 || buildings.length > 0) && (
                <Grid key={`${scenarioName}-missing-report-${i}`} item xs={12}>
                    <Typography variant={titleVariant}>
                        {scenarioName}
                    </Typography>
                    {floor_areas.length > 0 &&
                        <>
                            <Typography variant="body2" className={classes.descriptiveText}>
                                The following buildings are missing simulation data because they had invalid floor areas, likely because the F2F height was greater than the building height:
                            </Typography>
                            <Typography variant="body2" className={classes.descriptiveText}>
                                <em>
                                    <strong>
                                        {floor_areas.join(", ")}
                                    </strong>
                                </em>
                            </Typography>
                        </>}
                    {series.length > 0 &&
                        <>
                            <Typography variant="body2" className={classes.descriptiveText}>
                                The following series are missing simulation data:
                            </Typography>
                            <Typography variant="body2" className={classes.descriptiveText}>
                                <em>
                                    <strong>
                                        {series.join(", ")}
                                    </strong>
                                </em>
                            </Typography>
                        </>}
                    {buildings.length > 0 &&
                        <>
                            <Typography variant="body2" className={classes.descriptiveText}>
                                The following buildings are missing simulation data:
                            </Typography>
                            <Typography variant="body2" className={classes.descriptiveText}>
                                    <em>
                                        <strong>
                                            {buildings.join(", ")}
                                        </strong>
                                    </em>
                            </Typography>
                        </>}
                </Grid>
            ))}

        </Grid>
    );
};
