import { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { ObjectDataFieldReference } from "./ObjectDataFieldReference";

const daysLut = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export const ScheduleDaySelectors = (props) => {
    const {selectedNode, indexedNodes} = props.state
    const valuesArray = useMemo(() => indexedNodes[selectedNode].Days.map(({$ref})=>$ref), 
        [indexedNodes,selectedNode, indexedNodes[selectedNode].Days])

    return (
        <>
            {valuesArray.map((value,i) => 
                <Grid item xs={4} key={`schedule-selector-${i}`} >
                    <ObjectDataFieldReference 
                        keyName="Days" 
                        targetId={value} 
                        targetLabel={ daysLut[i] } 
                        index={i}
                        {...props}
                    />

                </Grid>
            )}
        </>
    );
};
