// Hooks
import { useSelector } from "react-redux"
import { GraphType } from "../../../redux/slices/analysis/consts"

///// Umiverse Imports

// Redux
import { selectGraphMode } from "../../../redux/slices/analysis/selectors"

// Components
import BarCharts from "../components/BarCharts"
import GraphTypeSelector from "../components/GraphTypeSelector"
import SankeyCharts from "../components/SankeyCharts"
import TimeCharts from "../components/TimeCharts"

export default () => {
    const graphMode = useSelector(selectGraphMode)
    return (
        	<>
                <GraphTypeSelector />
                {graphMode === GraphType.bar && <BarCharts />}
                {graphMode === GraphType.timeSeries && <TimeCharts />}
                {graphMode === GraphType.sankey && <SankeyCharts />}
            </>
        // <Grid container spacing={1} justifyContent="space-between">
        //     <Grid item>
        //     </Grid>
        //     <Grid item xs>
            
        //     </Grid>

        // </Grid>
    )
}