// Hooks
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// Recharts
import { ResponsiveContainer, Sankey, Tooltip as RechartsTooltip } from "recharts"

// MUI
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

///// Umiverse Imports

// Redux
import { selectMetric, selectNormalized, selectSankeyData, selectScenario, selectSortedScenarioNames } from "../../../redux/slices/analysis/selectors"
import { useTabNavigation } from "../../../redux/slices/navigation/hooks"
import { setNormalized, setSelectedScenario } from "../../../redux/slices/analysis/reducers"
import { MetricConfig } from "../../../redux/slices/analysis/consts"

// Lib Hooks
import { useImageDownloader, usePng } from "../../../clientLib/hooks"

// Components
import ChartSidebar from "./ChartSidebar"
import toast from "react-hot-toast"

const useStyles = makeStyles((theme ) => ({
    divider: {
        marginRight: "2rem"
    }
}))

export default () => {
    const {goToTab} = useTabNavigation()
    const dispatch = useDispatch()
    const [pngCallback, setPngCallback] = useState({})
    const downloader = useImageDownloader(pngCallback)
    const normalization = useSelector(selectNormalized)
    const selectedScenario = useSelector(selectScenario)
    const scenarioNames = useSelector(selectSortedScenarioNames)
    const classes = useStyles()

    useEffect(() => {
        if (normalization) {
            dispatch(setNormalized(false))
        }
    }, [normalization, dispatch])

    useEffect(() => {
        if (!selectedScenario) dispatch(setSelectedScenario(scenarioNames[0]))
    }, [scenarioNames, selectedScenario, dispatch])

    return (

        <Fade timeout={1000} in={true}>
            <Box >
                <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                    <Grid item xs>
                        <SankeyChartWithPng setPngCallback={setPngCallback} />
                    </Grid>
                    <Divider orientation="vertical" flexItem className={classes.divider}/>
                    <Grid item>
                        <ChartSidebar
                            pngCallback={() => toast.error("Not yet implemented on this page!")}
                            exportData={() => toast.error("Not yet implemented on this page!")}
                            usesScenarioSelect={true}
                            usesBreakdownSelect={false}
                            usesNormalizationSelect={false}
                        >
                            {/* No extra options*/}
                        </ChartSidebar>

                    </Grid>
                </Grid>
            </Box>
        </Fade>
    )
}

const SankeyChartWithPng = ({setPngCallback}) => {
    const ref = usePng({ setPngCallback, title: "ubem-data-visualization" })
    const data = useSelector(selectSankeyData)
    const metric = useSelector(selectMetric)
    return (
        // <Grid container justifyContent="center">
            <Box height="50vh" >
                <ResponsiveContainer width="99%" height="99%">
                    <Sankey
                        ref={ref}
                        width={960}
                        height={500}
                        data={data}
                        node={<MyCustomNode />}
                        nodePadding={50}
                        margin={{
                                left: 130,
                                right: 130,
                                top: 20,
                                // bottom: 100,
                        }}
                        link={<MyCustomLink/>}
                    >

                    <RechartsTooltip cursor={false} formatter={MetricConfig[metric].formatter}/>
                    </Sankey>
                </ResponsiveContainer>
            </Box>
        // </Grid>
    )
}
const MyCustomNode = (props) => {
  const isASource = props.payload.targetNodes.length > 0
  return (
    <>
    <text 
        x={ props.x + (isASource ? -10 : 20)} 
        y={ props.y + (props.height / 2)} class="small"
        textAnchor={isASource ? "end" : "start"}
    >
        {props.payload.name}
    </text>
    <path 
        fill={props.payload.color} 
        fill-opacity="0.8" 
        stroke={props.payload.stroke} 
        stroke-width="0" 
        x={props.x} 
        y={props.y} 
        width="10"
        height={props.height} 
        radius="0" 
        className="recharts-rectangle recharts-sankey-node"
        d={`M ${props.x},${props.y} h ${props.width} v ${props.height} h -${props.width} Z`} 
    />
    </>
  )
}
const MyCustomLink = (props) => {
  return (
        <>
        <defs>
            <linearGradient id={props.payload.mylinkname} x1="0" y1="0" x2="1" y2="0">
                <stop offset="5%" stopColor={props.payload.source.color} stopOpacity={1}/>
                <stop offset="95%" stopColor={props.payload.target.color} stopOpacity={1}/>
            </linearGradient>
        </defs>
        <path
            d={`
            M${props.sourceX},${props.sourceY}
            C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}
            `}
            stroke-opacity="0.7"
            fill-opacity="0"
            stroke={ `url(#${props.payload.mylinkname})` }
            strokeWidth={props.linkWidth}
            {...props}
        />
        </>
  )
}
