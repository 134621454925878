import { createSlice, current } from "@reduxjs/toolkit";
import { Workflow } from "../../consts";
import { AsyncTask } from "../async-processing/consts";
import { addAsyncExtraReducers } from "../async-processing/utils";
import { addErrorExtraReducers } from "../error/utils";
import { ContextLayers, Entrypoint, KeyFields } from "./consts";
import { addErrorsToGeoJSON } from "./utils";

const workflow = Workflow.umibuilder;

const makeKeyFields = () => {
    const keyFields = {};
    KeyFields.forEach(field => (keyFields[field] = null));
    return keyFields;
};

const makeContextLayers = () => {
    const contextLayers = {};
    ContextLayers.forEach(layer => (contextLayers[layer] = false));
    return contextLayers;
};

const makeBaseGisData = () => ({
    largeGeoJSON: false,
    geoJSON: null,
    categoricalProps: null,
    numericalProps: null,
    shapefileProps: null,
    summaryStats: null,
    mapBounds: null,
    climateZones: null
});

const initialState = {
    entrypoint: Entrypoint.gis,

    // Processed Base Data
    ...makeBaseGisData(),

    // KeyField Assignments
    keyFields: makeKeyFields(),
    keyFieldsErrorSummary: {
        any: 0
    },

    // Group Data
    groups: null,

    // Summary Data
    summary: null,

    // Context Layer
    contextLayers: makeContextLayers(),

    // Template Library Filter
    libraryFilters: {
        ClimateZone: [],
        Type: []
    },

    // Umi Export
    umiReady: false,

    // Other
    isProcessing: false,

    error: null,
    errors: {}
};

const umibuilderSlice = createSlice({
    name: workflow,
    initialState,
    reducers: {
        setEntrypoint: (state, { payload }) => {
            state.entrypoint = payload.entrypoint;
        },
        setIsProcessing: (state, { payload }) => {
            state.isProcessing = payload;
        },
        setAllKeyFields: (state, { payload }) => {
            state.keyFields = {
                ...payload
            };
            handleClearGroupData(state);
        },
        computeErrors: state => {
            state.keyFieldsErrorSummary = addErrorsToGeoJSON({
                geoJSON: state.geoJSON,
                assignments: state.keyFields
            }).errorSummary;
        },
        setKeyFieldAssignment: (state, { payload }) => {
            handleClearGroupData(state);
            state.keyFields[payload.field] = payload.value;
            state.keyFieldsErrorSummary = addErrorsToGeoJSON({
                geoJSON: state.geoJSON,
                assignments: state.keyFields
            }).errorSummary;
        },
        loadGroupData: (state, { payload }) => {
            state.groups = payload;
        },
        addSubdivision: (state, { payload }) => {
            handleClearSummaryData(state);
            const { field, breakpoint } = payload;
            const primaryGroup = state.groups.find(
                ({ field: _field }) => field === _field
            );
            const { groups } = primaryGroup;
            const groupToSplit = groups.find(
                group =>
                    group.secondaryMin <= breakpoint &&
                    group.secondaryMax >= breakpoint
            );
            if (groupToSplit) {
                const {
                    secondaryMax,
                    primaryField,
                    secondaryMin
                } = groupToSplit;
                const newGroup = {
                    ...groupToSplit
                };
                groupToSplit.secondaryMax = breakpoint;
                groupToSplit.archetypeID = `${primaryField}_${secondaryMin}_to_${breakpoint}`;
                newGroup.secondaryMin = breakpoint;
                newGroup.secondaryMax = secondaryMax;
                newGroup.archetypeID = `${primaryField}_${breakpoint}_to_${secondaryMax}`;
                groups.push(newGroup);
                groups.sort((a, b) => a.secondaryMin - b.secondaryMin);
            }
        },
        removeSubdivision: (state, { payload }) => {
            handleClearSummaryData(state);
            const { field, index } = payload;
            const primaryGroup = state.groups.find(
                ({ field: _field }) => field === _field
            );
            const { groups } = primaryGroup;
            const groupToPop = groups[index];
            const { secondaryMax, primaryField } = groupToPop;
            const groupToExpand = groups[index - 1];
            const { secondaryMin } = groupToExpand;
            groupToExpand.secondaryMax = secondaryMax;
            groupToExpand.archetypeID = `${primaryField}_${secondaryMin}_to_${secondaryMax}`;
            groups.splice(index, 1);
        },
        changeArchetypeID: (state, { payload }) => {
            handleClearSummaryData(state);
            const { index, field, archetypeID } = payload;
            const primaryGroup = state.groups.find(
                ({ field: _field }) => field === _field
            );
            const { groups } = primaryGroup;
            const groupToRename = groups[index];
            groupToRename.archetypeID = archetypeID;
        },
        changeArchetypeField: (state, { payload }) => {
            handleClearSummaryData(state);
            const { archetypeID, field, key, value } = payload;
            const primaryGroup = state.groups.find(
                ({ field: _field }) => field === _field
            );
            const { groups } = primaryGroup;
            const groupToMutate = groups.find(
                ({ archetypeID: id }) => id === archetypeID
            );
            if (groupToMutate) {
                groupToMutate[key] = value;
            }
        },
        setContextLayer: (state, { payload }) => {
            state.contextLayers[payload.layer] = payload.value;
        },
        setTemplateLibraryFilters: (state, { payload }) => {
            const { parameter, values } = payload;
            state.libraryFilters[parameter] = values;
        },
        clearUserTemplateAssignments: state => {
            state.groups.forEach(({ groups }) => {
                groups.forEach(group => {
                    if (group.isUser) {
                        group.isUser = false;
                        group.template = null;
                    }
                });
            });
        },
        clearGroupData: state => {
            handleClearGroupData(state);
        },
        clearSummaryData: state => {
            handleClearSummaryData(state);
        },
        clearAllData: state => {
            handleClearAllData(state);
        }
    },
    extraReducers: builder => {
        addErrorExtraReducers(builder, { workflow });
        addAsyncExtraReducers(builder, { workflow, handlers });
    }
});

const handleProcessedGis = (state, { payload }) => {
    const {
        geoJSON,
        shapefileProps,
        numericalProps,
        categoricalProps,
        mapBounds,
        summaryStats,
        climateZones
    } = payload.result;
    if (summaryStats.polygons > 1000) {
        state.largeGeoJSON = true;
        state.geoJSON = {};
    } else {
        state.geoJSON = geoJSON;
    }
    if (state.geoJSON?.features) {
        const errorSummary = { any: 0, overlap: 0 };
        state.geoJSON.features.forEach(feature => {
            if (feature.properties.UBEM_OVERLAP_ERROR) {
                errorSummary.any += 1;
                errorSummary.overlap += 1;
            }
        });
        state.keyFieldsErrorSummary = errorSummary;
    }
    state.shapefileProps = shapefileProps;
    state.categoricalProps = categoricalProps;
    state.numericalProps = numericalProps;
    state.mapBounds = [
        [mapBounds[1], mapBounds[0]],
        [mapBounds[3], mapBounds[2]]
    ];
    state.summaryStats = summaryStats;
    if (climateZones) {
        state.climateZones = Object.values(climateZones).map(({ CZ }) => CZ);
        state.libraryFilters.ClimateZone = [...state.climateZones];
    }
};

const handleFinalArchetypeAssignments = (state, { payload }) => {
    state.summary = {
        ...payload.result
    };
    state.summary.config = state.summary.config.map((building, i) => ({
        ...building,
        id: i
    }));
};

const handleUmiReady = state => {
    state.umiReady = true;
};

// Handlers for clearing flags to control page access
const handleClearUmiExport = state => {
    state.umiReady = false;
};

const handleClearSummaryData = state => {
    state.summary = null;
    handleClearUmiExport(state);
};

const handleClearGroupData = state => {
    state.groups = null;
    handleClearSummaryData(state);
};

const handleClearBaseGisData = state => {
    Object.entries(makeBaseGisData()).forEach(([key, value]) => {
        state[key] = value;
    });
    state.keyFields = makeKeyFields();
    handleClearGroupData(state);
};

const handleClearAllData = state => {
    handleClearBaseGisData(state);
};

// Async Action Handlers
const handlers = {
    [AsyncTask.processBaseGis]: {
        onStart: handleClearBaseGisData,
        onSuccess: handleProcessedGis
    },
    [AsyncTask.assignBuildingsToArchetypes]: {
        onStart: handleClearSummaryData,
        onSuccess: handleFinalArchetypeAssignments
    },
    [AsyncTask.startUmiExport]: {
        onStart: handleClearUmiExport,
        onSuccess: handleUmiReady
    }
};

// Action creators are generated for each case reducer function
export const {
    setEntrypoint,
    setIsProcessing,
    setAllKeyFields,
    setKeyFieldAssignment,
    computeErrors,
    loadGroupData,
    addSubdivision,
    removeSubdivision,
    changeArchetypeID,
    changeArchetypeField,
    setContextLayer,
    setTemplateLibraryFilters,
    clearUserTemplateAssignments,
    clearGroupData,
    clearSummaryData,
    clearAllData
} = umibuilderSlice.actions;

export default umibuilderSlice.reducer;
