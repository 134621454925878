// Hooks
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MUI
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
///// Umiverse Imports

// Redux
import { addSubdivision, removeSubdivision } from '../../../redux/slices/umibuilder/reducers';


export default ({primaryGroup, open, setOpen}) => {
    const dispatch = useDispatch()
    const {field} = primaryGroup

    // const [changeProcessed, setChangeProcessed] = useState(true)
    const [breakpoint, setBreakpoint] = useState('')

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const handleChange = useCallback((e) => {
        setBreakpoint(Number(e.target.value))
    }, [setBreakpoint])

    const subdivide = useCallback(() => {
        dispatch(addSubdivision({field, breakpoint}))

        // setChangeProcessed(false)
        setBreakpoint('')

    }, [breakpoint, field, dispatch])

    // useEffect(() => {
    //     if (!changeProcessed) {
    //         setChangeProcessed(true)
    //         trigger({groups})
    //     }

    // }, [changeProcessed, groups, setChangeProcessed, trigger])

    const handleEnter = useCallback((e) => {
        if (e.key === "Enter") {
            subdivide(e)
        }
    }, [subdivide])


    const totalGroups = primaryGroup.groups.length
    const marks = primaryGroup.groups.map(({secondaryMin}) => secondaryMin )
    marks.push(primaryGroup.groups[totalGroups-1].secondaryMax)

    const removeBreakpoint = useCallback((index) => {
        dispatch(removeSubdivision({field, index}))

    }, [field, dispatch])

    return (
        <>
            <IconButton color="primary" onClick={() => setOpen(true)}>
                <AddIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle id={ `subdivide-${primaryGroup.field}-dialog-title` }>
                    Subdivide Primary Field: {primaryGroup.field}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Subdivide the buildings matching the selected primary field using the breakpoints to split the secondary field range.
                    </DialogContentText>
                    {marks.map((mark, i) => (
                        <Grid item container xs={12} justifyContent="center" alignItems="center" key={`${field}-${i}-bp`} spacing={2}>
                            <Grid item >
                                <Typography variant="overline">
                                    {mark}
                                </Typography>
                            </Grid>
                                <Grid item xs={2}>
                                    {i > 0 && i < marks.length -1 ? (
                                            <IconButton onClick={() => removeBreakpoint(i)}>
                                                <RemoveIcon color="error"/>
                                            </IconButton>
                                    ) : (
                                        <Typography variant="overline">{i === 0 ? "(min)" : "(max)"}</Typography>
                                    )
                                    } 
                                </Grid>
                        </Grid>
                    ))}
                    <TextField
                        autoFocus
                        margin="dense"
                        onKeyDown={handleEnter}
                        label="Breakpoint"
                        type="number"
                        value={breakpoint}
                        onChange={handleChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={subdivide} color="primary">
                        Confirm
                    </Button>
                    <Button variant="contained" onClick={handleClose} color="primary" endIcon={<CancelIcon/>}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}