// Hooks
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

///// Umiverse Imports

// Redux
import { setTarget } from "../../../redux/slices/analysis/reducers"
import { selectTargetLong, selectTargetNear } from "../../../redux/slices/analysis/selectors"

// Components
import { DividerWrapper, Header } from "./SettingLayout"
import AnalysisDropdownSelect, { AnalysisDropdown } from "../components/AnalysisDropdownSelect"

export default () => {
    const dispatch = useDispatch()
    // TODO: abstract this
    const near = useSelector(selectTargetNear)
    const long = useSelector(selectTargetLong)
    const handleChange = useCallback((payload, e) => {
        const value = Number(e.target.value)
        const args = {
            ...payload,
            value
        }
        dispatch(setTarget(args))

    }, [dispatch])

	return (
        <Card variant="outlined">
            <CardContent>
                <Header name="Goals" />
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <AnalysisDropdownSelect dropdown={AnalysisDropdown.TargetMetric} />
                    </Grid>

                    <Grid item container direction="column" alignItems="center" spacing={1} xs={6}>

                        <Grid item container alignItems="center" spacing={2}>
                            <DividerWrapper>
                                <Typography variant="overline">Short-term</Typography>
                            </DividerWrapper>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                onChange={(e) => handleChange({parameter: "year", target: "near"}, e)}
                                label="Target"
                                value={near.year}
                                type="number"
                                min={2020}
                                max={2100}
                                step={1}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                onChange={(e) => handleChange({parameter: "value", target: "near"}, e)}
                                label="% compared to baseline"
                                value={near.value}
                                // helperText="%"
                                type="number"
                                min={0}
                                max={10000}
                                step={1}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" alignItems="center" spacing={1} xs={6}>
                        <Grid item container alignItems="center" spacing={2}>
                            <DividerWrapper>
                                <Typography variant="overline">Long-term</Typography>
                            </DividerWrapper>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                onChange={(e) => handleChange({parameter: "year", target: "long"}, e)}
                                label="Target"
                                value={long.year}
                                type="number"
                                min={2020}
                                max={2100}
                                step={1}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                onChange={(e) => handleChange({parameter: "value", target: "long"}, e)}
                                label="% compared to baseline"
                                value={long.value}
                                // helperText="Units"
                                type="number"
                                min={0}
                                max={10000}
                                step={1}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}