
// Hooks
import { useDispatch, useSelector } from "react-redux"

// MUI
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"

///// Umiverse Imports

// Redux
import { ScenarioSettingsPage, ScenarioSettingsPageConfig, SolarParameters } from "../../../redux/slices/analysis/consts"

// Components
import { Header, DividerWrapper } from "./SettingLayout"
import PVParameter from "./PVParameter"

export default ({ scenarioName }) => {
    return (
        <Card variant="outlined">
            <CardContent >
                {/* Header for Scenario */}
                <Header name={ScenarioSettingsPageConfig[ScenarioSettingsPage.PV].label} />

                {/* Header for Scenario Fuel Source Config */}
                <Grid container spacing={3}>
                    { SolarParameters.map(parameter => (
                        <Grid item key={`${scenarioName}-control-${parameter}-solar`}>
                            <PVParameter parameter={parameter} scenarioName={scenarioName}/>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>

        </Card>
    )
}

