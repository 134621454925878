// Hooks
import { useMemo, useEffect } from "react"
import { useRouteMatch, Switch, Route, Redirect, useLocation } from "react-router-dom"

// MUI
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import LinearProgress from "@material-ui/core/LinearProgress"
import Fade from "@material-ui/core/Fade"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Skeleton from "@material-ui/lab/Skeleton"
import Alert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/styles"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../redux/services/ubem"
import { useTabNavigation } from "../redux/slices/navigation/hooks"

// Components
import WorkflowSelectorDropdown from "./WorkflowSelectorDropdownMui"
import Help from "./Help"

const { useQuery: useMetadata } = ubemIoApi.endpoints.useAllMetadata
const { useQuery: useHelpdata } = ubemIoApi.endpoints.useAllHelpdata
const { useQuery: usePingTest } = ubemIoApi.endpoints.pingBackend

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: "2rem",
        marginBottom: "1rem",
        textAlign: "center",
    },
    maintenanceAlert: {
        marginBottom: "1rem",
    },
    description: {
        textAlign: "center",
        paddingBottom: "1.5rem"
    },
    homeButton: {
        marginRight: "1rem", 
        borderColor: "#fff", 
        '&:hover': {
            borderColor: "#fff",
            backgroundColor: "#fff",
            color: "#000",
        }
    },
    tabs: {
        marginRight: "auto",
    }

}))
export const Layout = ({children, tabsConfig, loading}) => {
    const { path } = useRouteMatch()
    const search = useLocation()
    const classes = useStyles()
    const {selectedTab, goToTab} = useTabNavigation(path,tabsConfig.defaultTab)
    const {data: pingData} = usePingTest('')
    console.log(pingData)
    const {data, isLoading: isLoadingMetadata } = useMetadata('')
    const {data: helpData, isLoading: isLoadingHelpData} = useHelpdata('')
    const {text: helpText, workflow_links: helpLinksWorkflows, page_links: helpLinksPages} = helpData ? helpData : {}
    

    const context = tabsConfig.context ? tabsConfig.context : "default"
    const help = useMemo(() => {
        const helpForTab = helpText?.[path]?.[selectedTab ?? "root"]
        const defaultHelp = helpForTab?.filter(help => help.context === "default")
        const contextHelp = helpForTab?.filter(help => help.context === context)
        const help = contextHelp?.length > 0 ? contextHelp : defaultHelp
        return help
    }, [context, path, selectedTab, helpText])

    const helpLinks = useMemo(() => {
        const helpLinksForWorkflow = helpLinksWorkflows?.[path] ? helpLinksWorkflows?.[path] : []
        const helpLinksForPage = helpLinksPages?.[path]?.[selectedTab ?? "root"]
        const defaultLinks = helpLinksForPage?.default
        const contextLinks = helpLinksForPage?.[context]
        const pageLinks = (contextLinks?.length > 0 ? contextLinks : defaultLinks)


        return [...(pageLinks ? pageLinks : []), ...helpLinksForWorkflow]
    }, [path, selectedTab, context, helpLinksWorkflows, helpLinksPages])



    const headerConfig = useMemo(() => {
        const splitPath = search.pathname.split("/")
        const last = splitPath.pop()
        const pagekey = selectedTab ? selectedTab : splitPath.length > 1 ? last : null

        if (!pagekey) {
            return {
                title: tabsConfig.root.title,
                description: tabsConfig.root.description,
                maintenance: false // TODO: root should be maintenance-able
            }
        }
        const {title: fallbackTitle, description: fallbackDescription} = tabsConfig.tabs[pagekey] ?? {}
        const remoteTitleData = data?.[path]?.[pagekey]?.title
        const title = remoteTitleData?.[context] ? remoteTitleData[context] : remoteTitleData?.default ? remoteTitleData.default : fallbackTitle
        const remoteDescriptionData = data?.[path]?.[pagekey]?.description
        const description = remoteDescriptionData?.[context] ? remoteDescriptionData[context] : remoteDescriptionData?.default ? remoteDescriptionData?.default : fallbackDescription
        const config = {}
        switch (typeof(title)) {
            case "string":
                config.title = title
                break
            case "object":
                config.title = title[tabsConfig.context] ?? title.default
                break
            case "undefined":
                config.title = ""
                break
            default:
                config.title = "DEVERROR: DON'T USE COMPONENTS"
                break
        }
        switch (typeof(description)) {
            case "string":
                config.description = description
                break
            case "object":
                config.description = description[tabsConfig.context] ?? description.default
                break
            case "undefined":
                config.description = ""
                break
            default:
                config.description = "DEVERROR: DON'T USE COMPONENTS"
                break
        }
        const remoteMaintenanceData = data?.[path]?.[pagekey]?.Maintenance
        config.maintenance = remoteMaintenanceData?.[context] ? remoteMaintenanceData?.[context] : remoteMaintenanceData?.default
        const remoteWorkflowTitle = data?.[path]?.[pagekey]?.WorkflowTitle
        config.workflowTitle= remoteWorkflowTitle?.[context] ? remoteWorkflowTitle?.[context] : remoteWorkflowTitle?.default
        return config
    },[tabsConfig.tabs, selectedTab, tabsConfig.context, data, search])


	return (
		<div>
			<AppBar position="sticky">

                <Toolbar >

                    <WorkflowSelectorDropdown workflowTitle={headerConfig?.workflowTitle} />
                    <Tabs
                        value={Object.keys(tabsConfig.tabs).filter(tabName => !tabsConfig.tabs[tabName].hidden).includes(selectedTab) ? selectedTab : false}
                        className={classes.tabs}
                        onChange={(event, value)=> goToTab(value)}
                    >
                        {selectedTab  && Object.entries(tabsConfig.tabs).filter( ([_,{hidden}]) => hidden !== true).map( ([ tabValue, { label: labelObj, disabled, icon} ], i) =>  {
                            const remoteLabelData = data?.[path]?.[tabValue]?.tab
                            let label = remoteLabelData?.[context] ? remoteLabelData[context] : remoteLabelData?.default ? remoteLabelData?.default : labelObj

                            switch (typeof(label)) {
                                case "string":
                                    label = label
                                    break
                                case "object":
                                    label = label[tabsConfig.context] ?? label.default
                                    break
                                case "undefined":
                                    label = ""
                                    break
                                default:
                                    label = "DEVERROR: DON'T USE COMPONENTS"
                                    break
                            }
                            return <Tab key={tabValue} value={tabValue} label={tabsConfig.numberTabs ? `${i+1}. ${label}`: label} disabled={disabled} icon={icon} />
                        }) 
                    }

                    </Tabs>
                    <Button href="https://www.ubem.io" variant="contained" className={classes.homeButton}>UBEM.io</Button>
                </Toolbar>


				{loading ? <LinearProgress /> : <LinearProgress variant="determinate" value={0}/>}
			</AppBar>
            <Help help={help} helpLinks={helpLinks}/>
			<Container>
                {children}
                {isLoadingMetadata ? 
                	<>
                        <Typography variant="h3" className={classes.title} ><Skeleton /></Typography>
                        <Typography variant="body2" className={classes.description} ><Skeleton /></Typography>
                    </>
                :
                    <>
                        {headerConfig.title && 
                            <Typography variant="h3" className={classes.title} >{headerConfig.title}</Typography>
                        }
                        {headerConfig?.maintenance && (
                            <Alert severity="warning" className={classes.maintenanceAlert}>
                                This page is currently undergoing maintenance.  It may not function correctly.  Use at your own risk. Please check again in a few hours.
                            </Alert> 
                        )
                        }
                        {headerConfig.description && 
                            <Typography variant="body2" className={classes.description} >{headerConfig.description}</Typography>
                        }
                        
                    </>
                }
                <Switch>
                    {tabsConfig.tabs[selectedTab]?.disabled && (
                        <Redirect from={path+"/"+selectedTab} to={`${path}/${tabsConfig.defaultTab}`} />
                    )}
                    {Object.entries(tabsConfig.tabs).map(([tabValue, {component}]) => (
                        <Route key={`${tabValue}-tab-route`} path={`${path}/${tabValue}`}>
                            <Fade timeout={1000} in={true}>
                                <div>
                                    {component}
                                </div>
                            </Fade>
                        </Route>
                    ) )}
                    {Object.entries(tabsConfig.otherRoutes).map(([name, {component}]) => (
                        <Route key={`${name}-nontab-route`} path={`${path}/${name}`}>
                            {component}
                        </Route>
                    ) )}
                    {tabsConfig.root.redirect && (
                        <Redirect from={path} to={`${path}/${tabsConfig.defaultTab}`} />
                    )}
                    <Route path={path} >
                        {tabsConfig.root.component}
                    </Route>

                </Switch>

			</Container>
		</div>
	)

}