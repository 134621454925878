import { useCallback } from "react";
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  selector: {
    minWidth: "100%",
    margin: theme.spacing(1),
  },
}))

export const Selector = ({ options, activeList, setActiveList, name, descriptor, customRender, helperText}) => {
    const classes = useStyles();
    const theme = useTheme();

    const handleChange = useCallback((event) => {
        setActiveList(event.target.value);
    }, [setActiveList]);

    return (
        <FormControl className={classes.selector}>
            <InputLabel id={ `active-${name}-mutiple-checkbox-label` }>{descriptor}</InputLabel>
            <Select
                labelId={ `active-${name}-mutiple-checkbox-label` }
                id={ `active-${name}-mutiple-checkbox` }
                multiple
                value={activeList}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => customRender ? customRender(selected) : selected.join(', ')}
                MenuProps={MenuProps}
                disabled={options.length === 0}
            >
                {options.map(({label, value}) => (
                    <MenuItem key={value} value={value}>
                        <Checkbox checked={activeList.indexOf(value) > -1} />
                        <ListItemText primary={label} />
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};
