import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import LinearProgress from "@material-ui/core/LinearProgress"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import WorkflowSelectorDropdown from "../WorkflowSelectorDropdownMui"
import { useMemo } from "react"

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: "2rem",
        marginBottom: "1rem",
        textAlign: "center",
    },
    description: {
        textAlign: "center",
        paddingBottom: "3rem"
    },
    homeButton: {
        marginRight: "1rem", 
        borderColor: "#fff", 
        '&:hover': {
            borderColor: "#fff",
            backgroundColor: "#fff",
            color: "#000",
        }
    },
    tabs: {
        marginRight: "auto",
    }

}))
export const TemplateLibraryLayout = ({children, tabsConfig, loading}) => {
    const classes = useStyles()
    const headerConfig = useMemo(() => {
        const {title, description} = tabsConfig.tabs[tabsConfig.value]
        return {
            title,
            description
        }
    },[tabsConfig.tabs, tabsConfig.value])

    // TODO: MUI v4 TabPanel can be used to auto load the tabs, however it is in "lab"
    // only v5 has in the main lib
    const view = tabsConfig.tabs[tabsConfig.value].component
	return (
		<div>
			<AppBar position="sticky">

                <Toolbar >
                    <Button href="https://www.ubem.io" variant="contained" className={classes.homeButton}>UBEM.io</Button>

                    <Tabs
                        value={tabsConfig.value}
                        onChange={tabsConfig.onChange}
                        className={classes.tabs}
                    >
                        {Object.entries(tabsConfig.tabs).map( ([ tabValue, { label, disabled, icon} ]) => (
                            <Tab key={tabValue} value={tabValue} label={label} disabled={disabled} icon={icon} iconPosition="end" />
                        ))}

                    </Tabs>
                    <WorkflowSelectorDropdown />
                    {/* <Typography style={{ marginLeft: "auto" }} variant="caption">{ headerConfig.title }</Typography> */}
                </Toolbar>


				{loading ? <LinearProgress /> : <LinearProgress variant="determinate" value={0}/>}
			</AppBar>
			<Container>
				<Typography variant="h3" className={classes.title} >{headerConfig.title}</Typography>
				<Typography variant="body2" className={classes.description} >{headerConfig.description}</Typography>
                {children}
                {view}

			</Container>
		</div>
	)

}