import { ubemIoApi } from "../../services/ubem";
import { Entrypoints, KeyField, KeyFields, KeyFieldsConfig } from "./consts";
import { mutationFixedCacheKeys } from "./endpoints";
import { errorAutoHandlingMessages } from "./utils";

export const selectEntrypoint = state => state.umibuilder.entrypoint;

export const isLoading = state => {
    const baseFileUploading = Entrypoints.map(entrypoint =>
        ubemIoApi.endpoints.uploadVizFile.select(entrypoint)(state)
    )
        .map(({ isLoading }) => isLoading)
        .some(isLoading => isLoading);
    const uioIsExporting = ubemIoApi.endpoints.exportExchangeFile.select(
        mutationFixedCacheKeys.exportExchangeFile
    )(state).isLoading;
    const keyFieldsErrorsAreExporting = ubemIoApi.endpoints.exportKeyFieldsErrorReport.select(
        mutationFixedCacheKeys.exportKeyFieldsErrorReport
    )(state).isLoading;
    const keyFieldsAreConfirming = ubemIoApi.endpoints.confirmKeyFields.select(
        mutationFixedCacheKeys.confirmKeyFields
    )(state).isLoading;
    const groupDataIsSending = ubemIoApi.endpoints.confirmKeyFields.select(
        mutationFixedCacheKeys.sendGroupData
    )(state).isLoading;
    const isLoadingDatabaseTemplateLibrary = ubemIoApi.endpoints.useTemplateDatabase.select(
        ""
    )(state).isLoading;
    return (
        state.umibuilder.isProcessing ||
        baseFileUploading ||
        uioIsExporting ||
        keyFieldsErrorsAreExporting ||
        keyFieldsAreConfirming ||
        groupDataIsSending ||
        isLoadingDatabaseTemplateLibrary
    );
};

export const selectGeoJSON = state => state.umibuilder.geoJSON;
export const selectIsLargeGeoJSON = state => state.umibuilder.largeGeoJSON;

export const selectBounds = state => state.umibuilder.mapBounds;

export const makeSelectAvailableColumns = field => state => {
    const columns = [...state.umibuilder[KeyFieldsConfig[field].propTypes]];
    if (!KeyFieldsConfig[field].isRequired) columns.push("N/A");
    return columns;
};
export const makeSelectKeyFieldAssignment = field => state =>
    state.umibuilder.keyFields[field];

export const allFieldsAssigned = state => {
    return KeyFields.filter(field => KeyFieldsConfig[field].isRequired).every(
        field => state.umibuilder.keyFields[field]
    );
};

export const selectKeyFields = state => state.umibuilder.keyFields;

export const selectErrorSummary = state =>
    state.umibuilder.keyFieldsErrorSummary;

export const makeSelectKeyFieldErrorMessage = keyField => state => {
    // TODO: this should probably be done in utils error tools!
    const summary = selectErrorSummary(state);
    if (keyField !== KeyField.id) {
        return summary[keyField]
            ? `${summary[keyField]} error${summary[keyField] !== 1 ? "s" : ""}`
            : "";
    } else {
        const duplicateErrors = summary.duplicateId
            ? `${summary.duplicateId} duplicate IDs`
            : "";
        const missingIds = summary.id
            ? `${summary.id} error${summary.id !== 1 ? "s" : ""}`
            : "";
        return [duplicateErrors, missingIds].filter(a => a).join(", ");
    }
};
export const selectKeyFieldErrorBypassMessages = state => {
    const errorMessages = [];
    KeyFields.forEach(keyField => {
        const message = makeSelectKeyFieldErrorMessage(keyField)(state);
        if (!message) return;

        errorMessages.push({
            automatedAction: errorAutoHandlingMessages[keyField],
            summary: `${keyField.toUpperCase()}: ${message}`
        });
    });
    if (state.umibuilder.keyFieldsErrorSummary.overlap) {
        errorMessages.push({
            automatedAction:
                "Overlapping buildings may cause errors in your UMI simulations!  The buildings should be addressed in the GIS file.",
            summary: `Overlap: ${state.umibuilder.keyFieldsErrorSummary.overlap} errors`
        });
    }
    return errorMessages;
};

export const selectClimateZones = state => state.umibuilder.climateZones;

export const selectContextLayers = state => state.umibuilder.contextLayers;

export const selectGroupData = state => state.umibuilder.groups;

export const selectSummaryData = state => state.umibuilder.summary;

export const selectUmiReady = state => state.umibuilder.umiReady;

export const selectTemplateLibraryFilters = state =>
    state.umibuilder.libraryFilters;

export const makeSelectArchetypeSubgroup = ({
    field,
    archetypeID
}) => state => {
    const primaryGroup = state.umibuilder.groups.find(
        ({ field: _field }) => field === _field
    );
    const { groups } = primaryGroup ?? {};
    const group = groups?.find(({ archetypeID: id }) => id === archetypeID);
    return group;
};

export const selectDisabledTabs = state => {
    const entrypoint = selectEntrypoint(state);
    // TODO: The pages should be declared outside of the component to make these accessible
    return {
        upload: false,
        keyfields: !selectGeoJSON(state),
        organize: !selectGroupData(state),
        assign: !selectGroupData(state),
        context: !selectSummaryData(state),
        export: !selectSummaryData(state)
    };
};
