// Hooks
import { useSelector } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
///// Umiverse Imports

// Redux
import { selectTemplateContext } from "../../../redux/slices/upgrades/selectors"

// components
import MeasureChip from "./MeasureChip"

const useStyles = makeStyles((theme) => ({
    measureChips: {
        marginBottom: "1rem",
    }
}))


export default () => {
    const measures = useSelector(selectTemplateContext)
    const classes = useStyles()

    if (Object.entries(measures ?? {}).length === 0) return <></>


    return ( 
        <Grid container spacing={2} className={classes.measureChips}>
            {Object.entries(measures).map( ([measureName, row], i)=> (
                <Grid item key={`measure-chip-${measureName}-${i}`} >
                    <MeasureChip measureName={measureName} presetName={row.Name} row={row} />
                </Grid>
            ))}
        </Grid> 
    )
    
}