import { Toast, Button } from 'react-bootstrap'
import fileDownload from 'js-file-download'
import {useEffect, useCallback, useState } from 'react'
import './ErrorDisplay.css';

export const ErrorDisplay = ({error, onClose}) => {
    const [showToast, setShowToast] = useState(![undefined, null].includes(error))
    const [log, setLog] = useState([])
    const [message, setMessage] = useState()

    useEffect(() => {
        if (error) {

            try {
                let _log
                let _error
                if (error.data) {
                    _error = error.data
                } else if (error.error) {
                    _error = error.error
                } else if (error.response) {
                    _error = error.response
                }
                if (_error.data) {
                    _error = _error.data
                } else if (_error.error) {
                    _error = _error.error
                } else if (_error.response) {
                    _error = _error.response
                }
                if (_error.log) {
                    _log = _error.log
                } else {
                    _log = _error
                }
                if (_log instanceof Blob) {
                    _log.text().then(text => setLog(text.split("\n")))
                } else {
                    setLog(_log.split("\n"))
                }
            } catch {
                setLog([])
                if (error.message) setMessage(error.message)
            }
        }
        setShowToast(![null, undefined].includes(error))
    }, [error,setShowToast,setLog])

    const closeToast = useCallback(()=>{
        if (showToast && onClose) {
            onClose()
        }
        if (showToast) setShowToast(false)
    },[setShowToast,showToast,onClose])

    const downloadLog = useCallback(()=>{
        log.length > 0 ? fileDownload(log.join("\n"),'error.log') : fileDownload("No log available.","error.log")

    },[error,log])

    return (
                <Toast className="error-toast bg-danger"  show={showToast} onClose={closeToast} >
                    <Toast.Header className="error-toast-header">
                        <strong >UBEM.io Error</strong>
                    </Toast.Header>
                    {

                    log.length > 0 ? (
                        <Toast.Body>
                            <strong>Final lines of log:</strong>
                            <div className="error-toast-log">

                            <ul>
                                {log.map((line,i)=>(line.length>0) && (i>(log.length-6)) ?<li key={`error-log-line-${i}`}> <small key={`error-line-${i}`}>{line}</small></li>: null)}
                            </ul>
                            <p>Please <a href="mailto:ubem_io@mit.edu">send us an email</a> with the log file attached with your original files so we can help you!</p>
                            </div>
                            <Button variant="secondary" onClick={downloadLog}>Download Complete Log</Button>
                        </Toast.Body>
                    ) : (
                        <Toast.Body>
                            {message ? message : "No log file delivered.  Server may be offline."}
                        </Toast.Body>
                    )
                    }
                </Toast>
    )

}
