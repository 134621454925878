// Hooks
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI
import Chip from "@material-ui/core/Chip";

///// Umiverse Imports

// Redux
import { removeMeasureFromTemplate, setMeasureContext } from "../../../redux/slices/upgrades/reducers";
import { selectTemplateSelection } from "../../../redux/slices/upgrades/selectors";


export default ({ measureName, presetName, row }) => {
    const dispatch = useDispatch();
    const templateName = useSelector(selectTemplateSelection)
    const handleClickDelete = useCallback(() => {
        dispatch(removeMeasureFromTemplate({ measureName, templateName }));
        setMeasureContext(null)

    }, [templateName, measureName, dispatch]);

    const handleMakeActive = useCallback(() => {
        dispatch(setMeasureContext(row))
    }, [row, dispatch])

    return (
        <Chip color="primary" onClick={handleMakeActive} onDelete={handleClickDelete} label={`${measureName}: ${presetName}`} />
    );
};
