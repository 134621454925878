// Hooks
import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

// MUI
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { setAdoptionGisIdColumn, setAdoptionOccupation, setAdoptionProbability } from "../../../redux/slices/analysis/reducers"
import { makeSelectFixedBaselineOwnerOccupancy, makeSelectFixedECMProbability } from "../../../redux/slices/analysis/selectors"
import { AdoptionConfigMode } from "../../../redux/slices/analysis/consts"
import { mutationFixedCacheKeys } from "../../../redux/slices/analysis/endpoints"

const { useMutation } = ubemIoApi.endpoints.uploadAdoptionGisFile


const AdoptionControlConfig = {
    baseline: {
        inputProps: {

            label: "Owner Occupancy",
            helperText: "Proportion of owner-occupied buildings (0-1)"
        },
        action: setAdoptionOccupation,
        makeSelector: makeSelectFixedBaselineOwnerOccupancy,
        isNumerical: true,
    },
    ecm: { 
        inputProps: {
            label: "Probability",
            helperText: "Upgrade likelihood per building",
        },
        action: setAdoptionProbability, 
        makeSelector: makeSelectFixedECMProbability,
        isNumerical: true,
    },
    name: {
        inputProps: {
            label: "ID",
            helperText: "Building ID matching UMI Name",
        },
        action: setAdoptionGisIdColumn,
        makeSelector: () => state => state.analysis.adoption.nameColumn,
        isNumerical: false,
    }
}

export const AdoptionControls = {
    ecm: "ecm",
    baseline: "baseline",
    name: "name"
}

const arrayToOptions = arr => arr.map(item => ({value: item, label: item}))

export default ({type, index}) => {
      
    const dispatch = useDispatch()
    const [mutation, {data, isLoading, isUninitialized}] = useMutation({fixedCacheKey: mutationFixedCacheKeys.adoptionGisConfigFile})

    const config = AdoptionControlConfig[type]
    const mode = useSelector(state => state.analysis.adoption.mode)
    const value = useSelector(config.makeSelector(index)) // index ignored by baseline

    const handleChange = useCallback((e) => {
        dispatch(config.action({index, value: Number(e.target.value)}))
    }, [dispatch, config.action])
    const handleSelectorChange = useCallback((e) => {
        dispatch(config.action({index, value: e.target.value}))
    }, [dispatch, config.action])

    switch (mode) {
        case AdoptionConfigMode.Fixed:
            return (
                <TextField
                    variant="outlined"
                    type="number"
                    value={value}
                    inputProps={{
                        step: 0.01,
                        min: 0,
                        max: 1,

                    }}
                    onChange={handleChange}
                    {...config.inputProps}
                />
            )
        case AdoptionConfigMode.GIS:
            if (isLoading) {
                return <CircularProgress />
            }
            if (isUninitialized) {
                return <Typography variant="overline">Awaiting upload...</Typography>
            }
            if (data) {
                const options = config.isNumerical ? arrayToOptions(data?.numericalColumns) : arrayToOptions(data?.allColumns)
                // TODO: add errors
                return ( 
                    <FormControl fullWidth > 
                        <InputLabel id={`${type}-${index}-column-selector-label`} >
                            {config.inputProps.label}
                        </InputLabel>

                        <Select
                            labelId={`${type}-${index}-column-selector-label`}
                            id={`${type}-${index}-column-selector`}
                            value={value ?? ""}
                            onChange={handleSelectorChange}
                        >
                            {options.map(({value, label}) => (
                                <MenuItem 
                                    value={value} 
                                    key={`column-${type}-${index}-option-${value}`}>{label}</MenuItem>
                            ))}

                        </Select>
                        {/* <FormHelperText>{error ? error : helper}</FormHelperText> */}
                    </FormControl>
                )
            }
            return <Typography variant="overline">Error processing uploadedfile</Typography>
    } 
    return (
        <div>
            error
        </div>
    )
}