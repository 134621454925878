import { Workflow } from "../../consts"
import { buildLUTs } from "../../utils"
import { Entrypoint } from "../umibuilder/consts"
import { setEntrypoint } from "../umibuilder/reducers"

export const WorkflowActionModeConfig = {
    root: {

    },
    relative: {

    },
    bypass: {

    }
}

export const {enum: WorkflowActionMode} = buildLUTs(WorkflowActionModeConfig)

export const WorkflowCompletionConfig = {
	[Workflow.umibuilder]: {
        [Entrypoint.gis]: {
            title: "GIS to UIO Completed!",
            message: "You may now start over with a new GIS file, or you may continue on to finish constructing your UBEM if you are also responsible for energy modeling.",
            actions: [ 
                {
                    mode: WorkflowActionMode.root,
                    text: "Start over"

                },
                {
                    mode: WorkflowActionMode.bypass,
                    text: "Continue on to UBEM generation",
                    action: setEntrypoint("uio"),
                    callback: () => console.log("here's a callback")
                }, 
            ]
        },
        default: {
            title: "UBEM Complete!",
            message: "You have now completed constructing your UBEM.  You may go back to the beginning to build a new UBEM, or you may go to the Upgrades workflow to apply technology packages to your UBEM.",
            actions: [ 
                {
                    mode: WorkflowActionMode.root,
                    text: "Start over"

                },
                {
                    mode: WorkflowActionMode.relative,
                    link: "../upgrades",
                    text: "Upgrade your UBEM"
                }, 
            ]

        }
    },
    [Workflow.upgrades]: {
        title: "Upgrades Complete!",
        message: "You just finished applying upgrades and have downloaded the new file.  You may now start over with a new UMI or JSON file, or continue adding upgrades.",
        actions: [
            {
                mode: WorkflowActionMode.root,
                text: "Start over"
            },
            {
                mode: WorkflowActionMode.bypass,
                text: "Continue"
            },
        ]
    }
}

export const { enum: WorkflowCompletion} = buildLUTs(WorkflowCompletionConfig)