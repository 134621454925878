import { useCallback, useEffect, useRef } from "react"
import { useDispatch , useSelector} from "react-redux"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { useRisingEdgeEffect } from "../../../clientLib/hooks"
import { setRootPath, setTab } from "./reducers"

export const useTabNavigation = (rootPath) => {
	const dispatch = useDispatch()
    const history = useHistory()
    const {path} = useRouteMatch()
    const selectedTab = useSelector(state=>state.navigation.selectedTab)
    const parentPath = useSelector(state => rootPath ? rootPath : state.navigation.rootPath)
    const params = useParams()
    useEffect(() => {
        dispatch(setRootPath(rootPath))
    }, [rootPath])

    const goToTab = useCallback((tab) => {
        dispatch(setTab(tab))
        history.push(`${rootPath ?? "."}/${tab}`)
    }, [dispatch, rootPath])

    // TODO: make sure this works when navigating between workflows
    // Set the selected tab if path says we are on a tab
    // but state does not have it
    useEffect(() => {
        const tab = path.split("\/")[2]
        if (!selectedTab) {
            if (tab) {
                dispatch(setTab(tab))
            }
        }
        if (tab && tab !== selectedTab) dispatch(setTab(tab))
    }, [selectedTab, path, dispatch])


    return {selectedTab, goToTab}
}

export const useNavigationEffect = ( {destination, condition}) => {
    const {goToTab} = useTabNavigation()
    const navigate = useCallback(() => {
        goToTab(destination)
    }, [destination, goToTab])

    useRisingEdgeEffect({callback: navigate, condition})
}