import { useState } from "react";
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid"
import Switch from '@material-ui/core/Switch';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useEffect } from "react";

export const ConfigureDownload = ({download, inputFileType}) => {
    const [mergeLibraries, setMergeLibraries] = useState(true)

    const [mergeName, setMergeName] = useState("")

    const [changeOriginalName, setChangeOriginalName] = useState(false)

    useEffect(() => {
        changeOriginalName ? setMergeName("Original") : setMergeName("Upgraded")

    }, [changeOriginalName, setMergeName])

    const downloadOptions = {
        mergeLibraries,
        mergeName,
        changeOriginalName
    }

	return (
        <Card variant="outlined">
            <CardContent>
                <Typography>Configure Library Export</Typography>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={4}>
                        <FormControl>
                            <FormControlLabel
                                control={<Switch color="primary" checked={mergeLibraries} onChange={()=>setMergeLibraries(prev=>!prev)} name="mergeLibraries" />}
                                label="Merge Template Libraries"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormControlLabel
                                control={<Switch disabled={!mergeLibraries} color="primary" checked={!changeOriginalName} onChange={()=>setChangeOriginalName(prev=>!prev)} name="changeOriginalName" />}
                                label={ `Append suffix to upgraded templates' names` }
                            />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                control={<Switch disabled={!mergeLibraries} color="primary" checked={changeOriginalName} onChange={()=>setChangeOriginalName(prev=>!prev)} name="changeOriginalName" />}
                                label={ `Append suffix to original templates' names` }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <TextField 
                                id="define-merge-name"
                                onChange={(event) => setMergeName(event.target.value)} 
                                disabled={!mergeLibraries}
                                label="Suffix" 
                                variant="standard" 
                                value={mergeName}
                                // className={classes.formInput}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <CardActions>
                    <Button variant="outlined" onClick={() => download(downloadOptions)}>Download {inputFileType.toLowerCase() === ".json" ? "Template Library" : "Updated UMI file"}</Button>

                </CardActions>
            </CardContent>

        </Card>
    )
}