// Hooks
import { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux"

// MUI
import TextField from "@material-ui/core/TextField"

///// Umiverse Imports

// Redux
import { updateFuelScenarioSettings, updateTimeSeriesSetting } from "../../../redux/slices/analysis/reducers";
import { FuelParameterConfig } from "../../../redux/slices/analysis/consts";
import { selectScenarioNames } from "../../../redux/slices/analysis/selectors";

export default ({fuelParameter, fuelSource}) => {
    const dispatch = useDispatch()
    const timeSeriesConfig = useSelector(state => state.analysis.timeSeriesSettings)
    const scenarioNames = useSelector(selectScenarioNames)
    const values = useMemo(() => timeSeriesConfig[fuelParameter][fuelSource].list, [fuelParameter, fuelSource, timeSeriesConfig])

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onload = async (data) => {
                // Split on new lines, drop the first row
                const rows = data.target.result.split("\r\n").map(row => row.split(",").slice(0,2).map(value => Number(value))).filter(([year,_]) => !isNaN(year)).filter(row => row.length === 2)
                rows.sort(([a,_], [b,__]) => a - b)
                const output = []
                rows.forEach(([year, value], i) => {
                    output.push(value)
                    if (i < rows.length-1) {
                        if (rows[i+1][0] > year + 1)  {
                            const timeDelta = rows[i+1][0] - year
                            const valueDelta = rows[i+1][1] - value
                            const slope = valueDelta / timeDelta
                            Array(timeDelta-1).fill().forEach( (_, j) => {
                                output.push( value + (j+1)*slope)
                            })
                        }
                    }
                })
                dispatch(updateTimeSeriesSetting({fuelParameter, fuelSource, parameter: "list", value: output}))
                scenarioNames.forEach(scenarioName => {
                    dispatch(updateFuelScenarioSettings({scenarioName, parameterKey: fuelParameter, fuelSource, value: output[0]}))
                })
            }
            reader.readAsText(file)
        });
    }, [fuelSource, scenarioNames, fuelParameter, dispatch]);

    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({ 
        accept: ".csv",
        maxFiles: 1,
        onDrop,
     });


	return (
        <div {...getRootProps()}>

            <TextField
                fullWidth
                label={`Drop ${ FuelParameterConfig[fuelParameter].label} CSV`}
                value={values.join(",")}
            />
            <input {...getInputProps()} />
        </div>
    )
}