import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createAnalysisEndpoints, analysisTags } from '../slices/analysis/endpoints'
import { createAsyncProcessingEndpoints } from '../slices/async-processing/endpoints'
import { createUmibuilderEndpoints, umibuilderTags } from '../slices/umibuilder/endpoints'
import { createUpgradesEndpoints } from '../slices/upgrades/endpoints'
import { setError } from '../slices/error/reducers'
import { transformTemplateLib } from '../slices/umibuilder/utils'
import { Workflow } from '../consts'

// Define a service using a base URL and expected endpoints
export const ubemIoApi = createApi({
    reducerPath: 'ubemIoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_USERS_SERVICE_URL}`,
        credentials: "include"
    }),
    tagTypes: [...analysisTags, ...umibuilderTags],
    endpoints: (builder) => ({
        ...createAnalysisEndpoints(builder),
        ...createAsyncProcessingEndpoints(builder),
        ...createUmibuilderEndpoints(builder),
        ...createUpgradesEndpoints(builder),
        pingBackend: builder.query({
            query: () => ({
                url: "api/ping-frontend-test"
            })
        }),
        useTemplateDatabase: builder.query({
            query: () => ({
                url: "api/umibuilder/get_all_templates",
            }),
            transformResponse: (res) => {
                const library = Object.values(res)
                return transformTemplateLib({library, isUser: false})
            },
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                try {
                    await queryFulfilled
                } catch ({error}) {
                    dispatch(setError({error, workflow: Workflow.umibuilder, key: "useTemplateDatabase"}))
                }
            }
        }),
        useAllMetadata: builder.query({
            query: () => ({
                url: "api/metadata",
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                try {
                    const {data} = await queryFulfilled
                } catch ({error}) {
                    dispatch(setError({error, workflow: "all", key: "useAllMetadata"}))
                }
            }
        }),
        useAllHelpdata: builder.query({
            query: () => ({
                url: "api/help",
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                try {
                    const {data} = await queryFulfilled
                } catch ({error}) {
                    dispatch(setError({error, workflow: "all", key: "useAllHelpdata"}))
                }
            }
        }),
        clearUserFiles: builder.mutation({
            query: () => ({
                url: "api/remove_user_files",
                method: "GET",
                responseHandler: "text",
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                try {
                    const res  = await queryFulfilled
                    console.log(res)
                } catch ({error}) {
                    console.error(error)
                    dispatch(setError({error, workflow: "all", key: "removeUserFiles"}))
                }
            }
        })
    }),
})


// /**
//  * Log a warning and show a toast!
//  */
// export const rtkQueryErrorLogger = (api) => (next) => (action) => {
//   // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
//   if (isRejectedWithValue(action)) {
//     console.warn('We got a rejected action!')
//     toast.warn({ title: 'Async error!', message: action.error.data.message })
//   }

//   return next(action)
// }
