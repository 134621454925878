import { KeyFieldsConfig } from "./consts"

export const buildLegacyKeyFields = (assignments) => {
    let body = {}
    Object.entries(KeyFieldsConfig).forEach( ([keyField, {legacyName }])=> {
        body[legacyName] = assignments[keyField] ? assignments[keyField] : "N/A"
    })
    return body
} 

const constructErrorTools = ({id, height, primary, secondary}) => {
    const missingIdField = (feature, all) => [null,undefined,''].includes(feature.properties[id]) && id !== "N/A"
    const idErrorMessage = () => `Missing value for selected UBEM ID Field: ${ id }`

    const duplicateIdField = (feature, all) => {
        if (missingIdField(feature)) return false
        if ( id === "N/A") return false
        const allIds = all.map(_feature => _feature.properties[ id ])
        const matchingIds = allIds.filter(_id => _id === feature.properties[id])
        return matchingIds.length > 1
    }
    const duplicateIdErrorMessage = (feature) => `Selected id field has a duplicate: ${ id }:${feature.properties[id]}`

    const invalidHeightField = (feature,) => [null,undefined,''].includes(feature.properties[height]) || feature.properties[height] < 2
    
    const heightErrorMessage = () => `Missing or invalid value (< 2m) for selected UBEM Height Field: ${height}`

    const missingPrimaryField = (feature,) => primary !== "N/A" ? [null,undefined,''].includes(feature.properties[primary ]) : false
    const primaryErrorMessage = () => `Missing value for selected UBEM Primary Field: ${primary }`

    const missingSecondaryField = (feature,) => secondary !== "N/A" ? [null,undefined,''].includes(feature.properties[secondary ]) : false
    const secondaryErrorMessage = () => `Missing value for selected UBEM Secondary Field: ${secondary }`

    const errorTools = {
        id: {
            isError: id ? missingIdField : () => false,
            message: id ? idErrorMessage : () => "",
            label: "ID",
        },
        duplicateId: {
            isError: id ? duplicateIdField : () => false,
            message: id ? duplicateIdErrorMessage : () => "",
            label: "DUPLICATE_ID",
        },
        height: {
            isError: height ? invalidHeightField : () => false,
            message: height ? heightErrorMessage : () => "",
            label: "HEIGHT",
        },
        primary: {
            isError: primary ? missingPrimaryField : () => false,
            message: primary ? primaryErrorMessage : () => "",
            label: "PRIMARY",
        },
        secondary: {
            isError: secondary ? missingSecondaryField : () => false,
            message: secondary ? secondaryErrorMessage : () => "",
            label: "SECONDARY",
        }
    }
    return errorTools
}

export const errorAutoHandlingMessages = {
    id: "A new ID column will be created and all buildings will be assigned new unique IDs, even if they had IDs in the original GIS column.",
    height: "Buildings <2m or missing height information will be removed from the model.  Buildings between 2m and 3m will be changed to 3m.",
    primary: "Buildings missing primary field information will be moved to the Shading Layer",
    secondary: "Buildings missing secondary field information will be moved to the Shading Layer",
}

export const addErrorsToGeoJSON = ({geoJSON, assignments}) => {
    const {features} = geoJSON
    if (features) {
        const errorTools = constructErrorTools( assignments)
        const errorSummary = {any: 0, overlap: 0}
        features.forEach(feature=> {
            if (!feature.properties.UBEM_OVERLAP_ERROR) feature.properties.UBEM_ERROR = false
        })
        features.forEach((feature,i)=> {
            Object.entries(errorTools).forEach(( [key,tools] ) => {
                
                if ( assignments[key] || assignments.id && key==="duplicateId") {
                    if (tools.isError(feature, features)) {
                        feature.properties = {
                            ...feature.properties,
                            UBEM_ERROR: true,
                            [`UBEM_${tools.label}_ERROR`]: true,
                            [`UBEM_${tools.label}_ERROR_DESC`]: tools.message(feature, features),
                        }
                        if (!errorSummary[key]) {
                            errorSummary[key] = 1
                        } else {
                            errorSummary[key] += 1

                        }
                    } else {
                        feature.properties = {
                            ...feature.properties,
                            [`UBEM_${tools.label}_ERROR`]: false,
                            [`UBEM_${tools.label}_ERROR_DESC`]: "N/A",
                        }

                    }
                }
            })
	    if (feature.properties.UBEM_OVERLAP_ERROR) errorSummary.overlap += 1
            if (feature.properties.UBEM_ERROR) errorSummary.any += 1
        })
        return {errorSummary, geoJSON}
    } else {
        return {errorSummary: {any: 0}}
    }
}

export const ClimateZones = ["1A",  "2A", "2B", "3A", "3B", "3C", "4A", "4B", "4C", "5A", "5B", "5C", "6A", "6B", "7A", "7C"]
export const typeDescriptors = ['Warehouse','Hospital','Office','MidRise','Restaurant','Market','Apartment','School','Hotel','Retail','SF']
typeDescriptors.sort()
typeDescriptors.push("Other")
typeDescriptors.push("User")

export const transformTemplateLib = ({library, isUser}) => {
    library.forEach(template => {
        template.id = `${template.Name}${isUser ? "_USER" : ""}`
        if (isUser) {
            template.isUser = true
            template.Type = ["User"]
        } else {
            template.isUser = false
            template.Type = []
        }
        const types = typeDescriptors.filter(type => template.Name.toUpperCase().includes(type.toUpperCase()) || template.Category?.join(", ").includes(type))
        if (types.length === 0) {
            template.Type.push("Other")
        } else {
            template.Type = [...template.Type, ...types]
        }
    })
    return library 
}