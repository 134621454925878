// Hooks etc
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"

// Umiverse Imports

// Redux
import { setError } from "../../redux/slices/error/reducers";
import { useTabNavigation } from "../../redux/slices/navigation/hooks";
import { isLoading, selectDisabledTabs } from "../../redux/slices/upgrades/selectors";

// Components
import { Layout } from "../Layout";
import AssignUpgradesPage from "./pages/AssignUpgradesPage";
import ExportPage from "./pages/ExportPage";
import UploadPage from "./pages/UploadPage";

export default ({children}) => {
    const dispatch = useDispatch()
    const loading = useSelector(isLoading)
    const disabledTabs = useSelector(selectDisabledTabs)


    // Conditionally hide tabs based off of entrypoint
    // const disabledTabs = useSelector(selectDisabledTabs)

    const tabsConfig = {
        tabs: {
            upload: {
                title: "Upload",
                description: "Upload an UBEM file (.UMI) or a template library (.json) to apply upgrades to.",
                label: "Upload",
                disabled: disabledTabs.upload,
                component: <UploadPage />,
            },
            assign: {
                title: "Technology Packages",
                description: "Upgrade templates and archetypes by selecting technology packages.",
                label: "Upgrade",
                disabled: disabledTabs.assign,
                component: <AssignUpgradesPage />,
            },
            // export: {
            //     title: "Export Upgraded Files",
            //     description: "Export your upgraded UBEM or template library.  You may choose to preserve the original templates in the template library or only export the upgraded templates.",
            //     label: "Export",
            //     disabled: disabledTabs.export,
            //     component: <ExportPage />,
            // },
        },
        defaultTab: "upload",
        numberTabs: true,
        root: {
            title: "Technology Packages",
            description: "Upgrade template libraries or UMI files with technology packages",
            component: <div>Help info coming</div>,
            redirect: true
        },
        // context: entrypoint,
        otherRoutes: {
            someOtherRoute: {
                component: <div>hello there</div>
            }
        }
    }

    // // Disable tabs based on state
    // Object.entries(tabsConfig.tabs).forEach(([name, tab ]) => {
    //     tab.disabled  = disabledTabs[name]
    // })


	return (
        <Layout 
            tabsConfig={tabsConfig}
            loading={loading}
        >
            {children}
        </Layout>

	)
}
