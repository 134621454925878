// A nice palette of ~20 colors to display as many building archeytpes as you can imagine.

import { buildLUTs } from "../../utils";

// Generated with coolors.co
export const colors = [
    "#ACD2ED",
    "#F25F5C",
    "#FFE066",
    "#3A6678",
    "#70C1B3",
    "#5D5CBB",
    "#B8D18D",
    "#F9A061",
    "#304C89",
    "#F6805F",
    "#8ECAD0",
    "#FCC064",
    "#247BA0",
    "#50514F",
    "#68A7C7",
    "#333432",
    "#355981",
    "#896BED",
    "#858683",
    "#F4705E"
];


export const MaxVizFileUploads = 5;

// Converts energy use for each use case to the appropriate metric 
// while accounting for the cost/carbon modifiers for each end use
// according to its fuel assignment
export const computeAdjustedData = ({ data, multipliers, copScalars}) => {
    const adjustedData = {}
    Object.entries(EndUseConfig).forEach( ( [endUse, {transformer}] ) => {
        const energy = data[endUse]
        const fuelScalar = multipliers[endUse]
        const copScalar = copScalars[endUse]
        // Apply whatever transformation happens (e.g. inverting umi heating cop calc)
        const transformedData = transformer({energy, fuelScalar, copScalar})
        // store the data for that end use
        adjustedData[endUse] = transformedData
    })
    return adjustedData
}

// FuelSource LUT -- These should be identical, enum is just for validation
export const FuelSourceConfig = {
    Oil: {
        label: "Oil",
        color: colors[7],
    },
    Gas: {
        label: "Gas",
        color: colors[5],
    },
    Electricity: {
        label: "Electricity",
        color: colors[4],
    }
}
export const {options: FuelSourceOptions, enum: FuelSource, arr: FuelSources} = buildLUTs(FuelSourceConfig)

export const EndUseConfig = {
    Heating: {
        label: "Heating",
        color: "#F25F5C",
        fuelSource: null,
        transformer: ({energy, fuelScalar}) => Math.round(energy * fuelScalar) ,
    },
    Cooling: {
        label: "Cooling",
        color: "#247BA0",
        fuelSource: FuelSource.Electricity,
        transformer: ({energy, fuelScalar}) => Math.round(energy * fuelScalar),
    },
    Lighting: {
        label: "Lighting",
        color: "#FFE066",
        fuelSource: FuelSource.Electricity,
        transformer: ({energy, fuelScalar}) => Math.round(energy * fuelScalar),
    },
    Equipment: {
        label: "Equipment",
        color: "#70C1B3",
        fuelSource: FuelSource.Electricity,
        transformer: ({energy, fuelScalar}) => Math.round(energy * fuelScalar), 
    },
    DHW: {
        label: "Domestic Hot Water",
        color: "#F9A061",
        fuelSource: null,
        transformer: ({energy, fuelScalar, copScalar}) => Math.round(energy * fuelScalar / copScalar * 0.8)
    },
};

export const {options: EndUseOptions, enum: EndUse, arr: EndUses} =  buildLUTs(EndUseConfig)

export const FuelParameterConfig = {
    Cost: {
        label: "Cost",
        umiSuffix: "Dollars",
        units: "$ / kWh",
        min: 0,
        max: 2,
        step: 0.01,
    },
    Carbon: {
        label: "Carbon Intensity",
        umiSuffix: "Carbon",
        units: "kg CO2eq / kWh",
        min: 0,
        max: 2,
        step: 0.01,
    }
};

export const {options: FuelParameterOptions, enum: FuelParameter, arr: FuelParameters} = buildLUTs(FuelParameterConfig)

export const SolarParameterConfig = {
    Coverage: {
        label: "Rooftop Coverage (%)",
        type: "number",
        min: 0,
        max: 100,
        step: 0.1,
        defaultValue: 80,
    },
    Efficiency: {
        label: "PV Efficiency (%)",
        type:"number",
        min: 10,
        max: 23,
        step: 0.1,
        defaultValue: 15,
    },
    Enabled: {
        label: "Enabled",
        type: "boolean",
        defaultValue: true,
    }
}
export const {options: SolarParameterOptions, enum: SolarParameter, arr: SolarParameters} = buildLUTs(SolarParameterConfig)

export const EndUseParameterConfig = {
    FuelSource: {
        label: "Fuel",
        type: "select",
        options: FuelSourceOptions,
        defaultValue: FuelSource.Gas,
        // Replace usedbys and just rely on nulled fields in enduses?
        usedBy: [EndUse.DHW, EndUse.Heating],
    },
    CoP: {
        label: "C.o.P.",
        type: "number",
        min: 0.5,
        max: 4.5,
        step: 0.01,
        defaultValue: 0.8,
        usedBy: [EndUse.DHW],
    }

}

export const {options: EndUseParameterOptions, enum: EndUseParameter, arr: EndUseParameters} = buildLUTs(EndUseParameterConfig)

const unitScaleLookup = {
    0: "k",
    1: "M",
    2: "G",
    3: "T",
    4: "E",
}

const commaConverter = val => {
    const power = Math.floor((Math.log10(val > 0 ? val : -val)/3)); 
    const info = {
        unit: unitScaleLookup[power], 
        numberFormatScaled: new Intl.NumberFormat('en').format(val/10**(power*3)), 
        numberFormat: new Intl.NumberFormat('en').format(val), 
        scaledValue: val/(10**(power*3))
    }
    return info
}

export const MetricConfig = {
    Energy: {
        label: "Operational Energy",
        units: "kWh",
        formatter: ((value) => `${commaConverter(value).numberFormatScaled} ${commaConverter(value).unit}Wh`),
        timeVaries: false,
        adjuster: null,
    },
    Cost: {
        label: "Operating Cost",
        units: "$",
        formatter: (value) => `$${commaConverter(value).numberFormat}`,
        timeVaries: true,
        adjuster: FuelParameter.Cost,
    },
    Carbon: {
        label: "Carbon Emissions",
        units: "kg CO2eq",
        formatter: ((value) => `${commaConverter(value).numberFormat} kg CO2eq`),
        timeVaries: true,
        adjuster: FuelParameter.Carbon,
    }
}
export const {options: MetricOptions, enum: Metric, arr: Metrics} = buildLUTs(MetricConfig)

export const ChartConfig = {
    TimeSeries: {
        label: "Time Series",
        icon: null,
        availableMetrics: MetricOptions.filter(({value, label}) => MetricConfig[value].timeVaries)
    },
    BarCharts: {
        label: "Bar Charts",
        icon: null,
        availableMetrics: MetricOptions
    }
}


// Breakdown setup
// Transformers take the data and configured options for a scenario and compute
// the output data for a single time step of the breakdown
export const BreakdownConfig = {
    Use: {
        label: "End Use", // Display text
        initialData: "Use", // Uses the energy values for each use case
        colorPicker: (id,i) => EndUseConfig[id].color,
        transformer: ({data, multipliers, copScalars}) => {
            return computeAdjustedData({data, multipliers, copScalars})
        },
    },
    Source: {
        label: "Fuel Source",
        initialData: "Use",
        colorPicker: (id,i) => FuelSourceConfig[id].color,
        transformer: ({data, multipliers, copScalars, fuelAssignments}) => {
            // Initial each fuel source accumulator to 0
            const dataByFuelSource = {}
            FuelSources.forEach( fuelSource => {
                dataByFuelSource[fuelSource] = 0
            })
            // Re-adjust the energy values to the approrpiate units
            const adjustedEndUseData = computeAdjustedData({data, multipliers, copScalars})
            // For each end use, increment the corresponding fuel assignment's data 
            Object.entries(adjustedEndUseData).forEach(([endUse, value]) => {
                const fuelAssignment = fuelAssignments[endUse]
                dataByFuelSource[fuelAssignment] += value
            })
            return dataByFuelSource
        }
    },
    Archetype: {
        label: "Archetype",
        initialData: "Archetype",
        colorPicker: (id,i) => colors[i],
        transformer: ({data, multipliers, copScalars}) => {
            const dataByArchetype = {}
            // For each archetype, map the end uses energy values 
            // to the approrpiate units using the scalars determined by metric and fuel assignments
            // then sum the end uses
            Object.entries(data).forEach(([archetype, _data ]) => {
                const adjustedData = computeAdjustedData({data: _data, multipliers, copScalars })
                const summedData = Object.values(adjustedData).reduce( (a,b) => a+b, 0)
                dataByArchetype[archetype] = summedData
            })
            return dataByArchetype
        }
    },
    Total: {
        label: "Total",
        initialData: "Use",
        colorPicker: (id,i) => colors[i],
        transformer: ({data, multipliers, copScalars}) => {
            const adjustedData = computeAdjustedData({data, multipliers, copScalars})
            const summedData = Object.values(adjustedData).reduce((a,b) => a+b, 0)
            return {Total: summedData}
        }
    },
}
export const {options: BreakdownOptions, enum: Breakdown, arr: Breakdowns} = buildLUTs(BreakdownConfig)

export const TimeSeriesModeConfig = {
    Exponential: {label: "Exponential"},
    CSV: {label: "CSV Upload"},
};
export const {options: TimeSeriesModeOptions, enum: TimeSeriesMode, arr: TimeSeriesModes} = buildLUTs(TimeSeriesModeConfig)


// Adoption Config modes
export const AdoptionConfigModeConfig = {
    Fixed: {
        label: "Fixed"
    },
    GIS: {
        label: "GIS File"
    },
}
export const {options: AdoptionConfigModeOptions, enum: AdoptionConfigMode, arr: AdoptionConfigModes} = buildLUTs(AdoptionConfigModeConfig)

export const GraphTypeConfig = {
    bar: {
        label: "Scenario Comparison"
    },
    timeSeries: {
        label: "Time Series"
    },
    sankey: {
        label: "Sankey Diagram"
    },
}

export const {options: GraphTypeOptions, enum: GraphType, arr: GraphTypes} = buildLUTs(GraphTypeConfig)

export const SettingsPageConfig = {
    Targets: {
        label: "Targets",
    },
    Scenario: {
        label: "Dynamic",
    },
    TimeSeries: {
        label: "Time Series"
    }
}

export const {options: SettingsPageOptions, enum: SettingsPage, arr: SettingsPages} = buildLUTs(SettingsPageConfig)

export const ScenarioSettingsPageConfig = {
    EndUses: {
        label: "End Uses"
    },
    Fuels: {
        label: "Fuels",
    },
    PV: {
        label: "PV"
    }
}
export const {options: ScenarioSettingsPageOptions, enum: ScenarioSettingsPage, arr: ScenarioSettingsPages} = buildLUTs(ScenarioSettingsPageConfig)
