import { setError } from "./reducers"

export const addErrorExtraReducers = (builder, {workflow, handler}) => {
    builder.addCase(setError, (state, {payload}) => {
        if (payload.workflow === workflow) {
            state.error = payload.error
            state.errors[payload.key] = payload.error
            state.isProcessing = false
            if (handler) handler(state, {payload})
        }
    })
}