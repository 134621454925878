// Hooks
import { useCallback, useState, useEffect, useRef } from "react";
import { useCurrentPng } from "recharts-to-png";

// Packages
import * as ReactDOM from "react-dom";
import FileSaver from "file-saver";
import toast from "react-hot-toast";
import fileDownload from "js-file-download";

export const useTabSelector = ({
    initialTab,
    beforeChangeCb,
    afterChangeCb
}) => {
    const [selectedTab, setSelectedTab] = useState(initialTab);
    const handleTabChange = useCallback(
        (event, value) => {
            if (beforeChangeCb) beforeChangeCb(value);
            setSelectedTab(value);
            if (afterChangeCb) afterChangeCb(value);
        },
        [setSelectedTab, beforeChangeCb, afterChangeCb]
    );

    return {
        selectedTab,
        handleTabChange,
        setSelectedTab
    };
};

export const useSvgAndPng = ({ title }) => {
    const [getPng, { ref, isLoading }] = useCurrentPng();
    const [dummy, setDummy] = useState();
    const exportPng = useCallback(async () => {
        console.log("attempting to generate png");
        const png = await getPng();
        if (png) {
            FileSaver.saveAs(png, `${title}.png`);
        } else {
            console.error("Could not generate png");
        }
    }, [getPng, title]);

    const exportSvg = useCallback(() => {
        if (!ref.current) return;
        let chartSVG = ReactDOM.findDOMNode(ref.current);

        let svgURL = new XMLSerializer().serializeToString(chartSVG);
        let svgBlob = new Blob([svgURL], {
            type: "image/svg+xml;charset=utf-8"
        });
        fileDownload(svgBlob, `${title}.svg`);
    }, [ref?.current, title]);
    useEffect(() => {
        setDummy(Math.random());
    }, []);
    return { ref, exportSvg, exportPng };
};
export const useSVG = ({ setSvgCallback, ref, title }) => {
    const exportSvg = useCallback(() => {
        console.log("here");
        let chartSVG = ReactDOM.findDOMNode(ref.current);

        let svgURL = new XMLSerializer().serializeToString(chartSVG);
        let svgBlob = new Blob([svgURL], {
            type: "image/svg+xml;charset=utf-8"
        });
        fileDownload(svgBlob, `${title}.svg`);
    }, [ref]);
    setSvgCallback(exportSvg);
    return ref;
};

// Store references to image download callbacks
export const usePng = ({ setPngCallback, title }) => {
    const [getPng, { ref, isLoading }] = useCurrentPng();
    const handleDownload = useCallback(async () => {
        console.log("attempting to generate png");
        const png = await getPng();
        if (png) {
            FileSaver.saveAs(png, `${title}.png`);
        }
    }, [getPng, title]);

    useEffect(() => {
        if (setPngCallback) setPngCallback();
    }, [setPngCallback]);

    useEffect(() => {
        setPngCallback({
            [title.replaceAll('"', "")]: handleDownload
        });
    }, [handleDownload, setPngCallback, title]);
    return ref;
};

// Can download multiple charts mounted on the same page
export const useImageDownloader = pngCallbacks => {
    const download = useCallback(() => {
        Object.values(pngCallbacks).map(cb => cb());
    }, [pngCallbacks]);
    return download;
};

// Only fires on rising edges
export const useRisingEdgeEffect = ({ callback, condition }) => {
    const gateIsOpen = useRef(false);
    useEffect(() => {
        if (gateIsOpen.current && condition) {
            callback();
            gateIsOpen.current = false;
        }
        if (!condition) {
            gateIsOpen.current = true;
        }
    }, [condition, gateIsOpen, callback]);
};

export const useIsMainWindow = () => {
    const initialized = useRef(false);
    const isNewWindowPromotedToMain = useRef(false);
    const windowId = useRef(null);
    const [isMain, setIsMain] = useState(true);

    const getWindowArray = () => {
        let storage = window.localStorage.getItem("checkTab");
        return storage ? JSON.parse(storage) : [];
    };

    const setWindowArray = data => {
        window.localStorage.setItem("checkTab", JSON.stringify(data));
    };

    const determineWindowState = () => {
        let windowArray = getWindowArray();

        if (initialized.current) {
            if (
                windowArray.length <= 1 ||
                (isNewWindowPromotedToMain.current
                    ? windowArray[windowArray.length - 1]
                    : windowArray[0]) === windowId.current
            ) {
                setIsMain(true);
            } else {
                setIsMain(false);
            }
        } else {
            if (windowArray.length === 0) {
                setIsMain(true);
            } else {
                setIsMain(false);
            }
            const newWindowArray = [...windowArray, windowId.current];
            setWindowArray(newWindowArray);
        }

        setTimeout(function() {
            determineWindowState();
        }, 1500);
    };

    const removeWindow = () => {
        var newWindowArray = getWindowArray();
        for (var i = 0, length = newWindowArray.length; i < length; i++) {
            if (newWindowArray[i] === windowId.current) {
                newWindowArray.splice(i, 1);
                break;
            }
        }
        setWindowArray(newWindowArray);
    };

    useEffect(() => {
        window.addEventListener("beforeunload", removeWindow);
        window.addEventListener("unload", removeWindow);
        isNewWindowPromotedToMain.current = true;
        windowId.current = Date.now().toString();
        determineWindowState();
        initialized.current = true;

        return () => {
            window.removeEventListener("beforeunload", removeWindow);
            window.removeEventListener("unload", removeWindow);
        };
    }, []);

    return isMain;
};

// Shows a toast when data disappars
export const useClearDataToast = ({ data }) => {
    const callback = useCallback(() => {
        toast.success("Cleared all data.", { position: "bottom-center" });
    }, []);

    useRisingEdgeEffect({ callback, condition: !data });
};
