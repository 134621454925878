// Hooks
import { useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

///// Umiverse Imports

// Redux
import { setTemplateLibraryFilters } from "../../../redux/slices/umibuilder/reducers"
import { selectTemplateLibraryFilters } from "../../../redux/slices/umibuilder/selectors"
import { ClimateZones, typeDescriptors } from "../../../redux/slices/umibuilder/utils"
import { buildLUTs } from "../../../redux/utils"

// Components
import { Selector } from "../../MultiSelector"

export const TemplateFiltersConfig = {
    ClimateZone: {
        descriptor: "Climate Zones",
        helperText: "Select climate zone filters",
        options: ClimateZones.map(value => ({label: value, value})),
    },
    Type: {
        descriptor: "Typology",
        helperText: "Select building typology filters",
        options:typeDescriptors.map(value => ({label: value, value})),
    }
}

export const {enum: TemplateFilter, arr: TemplateFilters} = buildLUTs(TemplateFiltersConfig)

export default ({parameter}) => {
    const dispatch = useDispatch()
    const config = TemplateFiltersConfig[parameter]

    const filters = useSelector(selectTemplateLibraryFilters)
    const filterValues = useMemo(() => filters[parameter], [parameter, filters])

    const setFilterValues = useCallback((values) => {
        dispatch(setTemplateLibraryFilters({parameter, values}))
    }, [dispatch, parameter])

    return (
        <Selector {...config} name={parameter} activeList={filterValues} setActiveList={setFilterValues} />
    )
}