// Hooks
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// MUI
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridToolbarDensitySelector, GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';

///// Umiverse Imports

// Redux
import { ubemIoApi } from '../../../redux/services/ubem';
import { mutationFixedCacheKeys } from '../../../redux/slices/umibuilder/endpoints';
import { selectTemplateLibraryFilters } from '../../../redux/slices/umibuilder/selectors';

// Components
import { RtkDropzone } from '../../RtkDropzone';
import TemplateTableFilter, { TemplateFilter } from './TemplateTableFilter';

const {useQuery: useTemplates} = ubemIoApi.endpoints.useTemplateDatabase
const {useMutation: uploadUserTemplateLib } = ubemIoApi.endpoints.uploadUserTemplateLibUmibuilder

const dropzoneProps = {
    label: `User Template Library`,
    showBrowseButton: true,
    mutation: uploadUserTemplateLib,
    index: mutationFixedCacheKeys.uploadUserTemplateLib,
    skipReset: true,
    maxFiles: 1,
    accept: "application/json, .json",
}

const columns = [
  {
    field: 'Name',
    headerName: 'Name',
    minWidth: 250,
    flex: 1,
    editable: false,
  },
  {
    field: 'ClimateZone',
    headerName: 'Climate Zone',
    minWidth: 90,
    flex: 1,
    editable: false,
    valueFormatter: ({value}) => value.join(", "),
  },
  {
    field: 'Type',
    headerName: 'Type',
    minWidth: 90,
    flex: 1,
    editable: false,
    valueFormatter: ({value}) => value.join(", "),
  },
  {
    field: 'Country',
    headerName: 'Country',
    minWidth: 90,
    flex: 1,
    editable: false,
  },
  {
    field: 'YearFrom',
    headerName: 'Year From',
    minWidth: 90,
    flex: 1,
    hide: true,
    editable: false,
  },
  {
    field: 'YearTo',
    headerName: 'Year To',
    minWidth: 90,
    flex: 1,
    hide: true,
    editable: false,
  },
  {
    field: 'DataSource',
    headerName: 'Data Source',
    minWidth: 90,
    flex: 1,
    hide: true,
    editable: false,
  },
  {
    field: 'Comments',
    headerName: 'Comments (hover)',
    minWidth: 90,
    flex: 1,
    hide: true,
    editable: false,
  },
];


const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

const TemplateTable = ({assign}) => {

    const {data: databaseTemplateData} = useTemplates('')
    const [_, {data: userTemplateData}] = uploadUserTemplateLib({fixedCacheKey: mutationFixedCacheKeys.uploadUserTemplateLib})

    const data = useMemo(()=> {
        const databaseTemplates = databaseTemplateData ? databaseTemplateData : []
        const userTemplates = userTemplateData ? userTemplateData : []
        return [...userTemplates, ...databaseTemplates]
    }, [userTemplateData, databaseTemplateData])

    const [pageSize, setPageSize] = useState(10)

    const filters = useSelector(selectTemplateLibraryFilters)

    const filteredData = useMemo(() => {
      if (!data) return []
      return data.filter(({ClimateZone, Type}) => {
        let pass = true
        if (filters.ClimateZone.length > 0) {
          if (filters.ClimateZone.filter(zone => ClimateZone.includes(zone)).length === 0) pass = false
        }
        if (filters.Type.length > 0) {
          if (filters.Type.filter(t => Type.includes(t)).length === 0) pass = false
        }
        return pass
      })
    }, [filters.Type, filters.ClimateZone, data])

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs>
                    <TemplateTableFilter parameter={TemplateFilter.ClimateZone} />
                </Grid>
                <Grid item xs>
                    <TemplateTableFilter parameter={TemplateFilter.Type} />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                autoHeight
                                rows={filteredData}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(size) => setPageSize(size)}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                onRowClick={assign}
                                sortModel={[{ field: "ClimateZone", sort: "asc" }, {field: "Type", sort: "asc"}]}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                density="compact"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <RtkDropzone {...dropzoneProps} />
                </Grid>
            </Grid>
    </>
    )
}

export default TemplateTable