// Hooks
import React from "react"
import { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// MUI
import { GridFilterListIcon } from '@mui/x-data-grid'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles'


///// Umiverse Imports

// Redux
import { ubemIoApi } from '../../../redux/services/ubem'
import { selectActiveFilters } from '../../../redux/slices/upgrades/selectors'
import { setActiveFilter } from '../../../redux/slices/upgrades/reducers'

const {useQuery: useMeasuresLib} = ubemIoApi.endpoints.getMeasuresLib

const useStyles = makeStyles((theme) => ({
  popover: {
    width: "100%",
  },
  filter: {
    margin: "1rem",
    width: "20vw",
  },
  filterChip: {
    margin: "0 0.5rem"
  }
}));

export default () => {

    const dispatch = useDispatch()
    const { data: measuresLib, isLoading } = useMeasuresLib('')
    const activeFilters = useSelector(selectActiveFilters)
    // TODO: This will be run the first time the dialog opens every time I think
    const filtersConfig = useMemo(() => {
        if (!measuresLib) return []
        const filters = []
        const categoryOptions = [...new Set(Object.values(measuresLib).map(({"Measure.Category": Category}) => Category ))]
        filters.push({
            label: "Category",
            prop: "Measure.Category",
            options: categoryOptions
        })
        const systemsOptions = [...new Set(Object.values(measuresLib).map(({"Measure.BuildingSystems": Systems}) => Systems).flat())]
        filters.push({
            label: "Building Systems",
            prop: "Measure.BuildingSystems",
            options: systemsOptions
        })
        return filters

    }, [measuresLib])

    const handleChange = useCallback(({prop, value}) => {
        dispatch(setActiveFilter({prop, value}))
    }, [dispatch])

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;


    return (
        <>
            <Button startIcon={<GridFilterListIcon />} onClick={handleClick} color="primary" size="small">Filters</Button>
            {Object.entries(activeFilters ?? {}).map(([filterProp, filterValue]) => (
                <Chip 
                    color="primary"
                    className={classes.filterChip}
                    size="small" 
                    key={`${filterProp}-filter-chip`} 
                    onClick={handleClick}
                    onDelete={() => handleChange({prop: filterProp, value: ''})}
                    // TODO: this should be memoized in a component
                    label={`${filtersConfig.find(({prop, label}) => prop===filterProp).label}: ${filterValue}`}
                />
            ))}
            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container alignItems="center">
                    {filtersConfig?.map( ({label, prop, options},i) =>   (
                        <React.Fragment key={`filter-${label}-${i}`} >
                            <Grid item xs>

                                <FormControl className={classes.filter}>
                                    <InputLabel id={`filter-label-${label}-${i}`} >{label}</InputLabel>
                                    <Select 
                                        labelId={`filter-label-${label}-${i}`}
                                        value={activeFilters[prop] ?? ''} 
                                        label={label}
                                        id={ `labeld-multil-${i}` }
                                        onChange={(e) => handleChange({prop, value: e.target.value})}
                                    >
                                        {options.map((opt, j) => (
                                            <MenuItem key={`filter-${label}-${opt}-${j}`} value={opt}>{opt}</MenuItem>
                                        ))}
                                        <MenuItem value={""}>Remove</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {(i !== filtersConfig.length - 1) && (
                                <Grid item xs>
                                    <Typography variant="overline">or</Typography>
                                </Grid>
                            )}
                        </React.Fragment>
                        
                    ))}
                </Grid>
            </Popover>
        </>
        
    );
}