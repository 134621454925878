// Hooks
import {useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux';

// Packages
import fileDownload from 'js-file-download'

// MUI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider"
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

///// Umiverse Imports

// Redux
import { selectError } from '../redux/slices/error/selectors';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '50vw',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default ({onClose}) => {
    // TODO: add "stale/viewed" tracker
    const classes = useStyles();
    const error = useSelector(selectError)
    const [showToast, setShowToast] = useState(![undefined, null].includes(error))
    const [log, setLog] = useState([])
    const [message, setMessage] = useState()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setShowToast(false);
    };

    useEffect(() => {
        if (error) {

            try {
                let _log
                let _error
                if (error.data) {
                    _error = error.data
                } else if (error.error) {
                    _error = error.error
                } else if (error.response) {
                    _error = error.response
                }
                if (_error.data) {
                    _error = _error.data
                } else if (_error.error) {
                    _error = _error.error
                } else if (_error.response) {
                    _error = _error.response
                }
                if (_error.log) {
                    _log = _error.log
                } else {
                    _log = _error
                }
                if (_log instanceof Blob) {
                    _log.text().then(text => setLog(text.split("\n")))
                } else {
                    setLog(_log.split("\n"))
                }
            } catch {
                setLog([])
                if (error.message) setMessage(error.message)
            }
        }
        setShowToast(![null, undefined].includes(error))
    }, [error,setShowToast,setLog])

    const closeToast = useCallback(()=>{
        if (showToast && onClose) {
            onClose()
        }
        if (showToast) setShowToast(false)
    },[setShowToast,showToast,onClose])

    const downloadLog = useCallback(()=>{
        log.length > 0 ? fileDownload(log.join("\n"),'error.log') : fileDownload("No log available.","error.log")

    },[error,log])

    return (
        <div className={classes.root}>
            <Snackbar open={showToast} onClose={handleClose} >
                <Alert onClose={handleClose} severity="error">
                    <Typography variant="h5">UBEM.io Error</Typography>
                    {log.length > 0 && (
                        <>
                            <Typography variant="overline">Final lines of log:</Typography>
                            <Typography variant="caption">
                                <ul>
                                    {log.map((line,i)=>(line.length>0) && (i>(log.length-6)) ?<li key={`error-log-line-${i}`}>{line}</li>: null)}
                                </ul>
                            </Typography>
                            <MuiButton 
                                style={{marginLeft: "1rem", backgroundColor: "#fff", marginBottom: "1rem"}} 
                                color="secondary" 
                                variant="outlined"
                                onClick={downloadLog}
                            >
                                Download Complete Log
                            </MuiButton>
                            <Divider style={{marginBottom: "1rem"}}/>
                            <Typography variant="body2">
                                Please <Link underline="always" style={{color: "white"}} href="mailto:ubem_io@mit.edu">send us an email</Link> with the log file attached with your original files so we can help you!
                            </Typography>
                        </>
                    )}
                    {log.length == 0 && message && <Typography variant="overline">{message}</Typography>}
                </Alert>
            </Snackbar>
        </div>
    )
}


