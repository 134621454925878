import axios from "axios";
import { useMemo, useCallback, useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help"
import CancelIcon from "@material-ui/icons/Cancel"
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { ObjectMetadata, ObjectAssemblyInfo } from "./ObjectSimpleFieldGroup";

export const ObjectHeader = (props) => {
    const {dispatch, state} = props
    const {indexedNodes, selectedNode,} = state
    const data = useMemo(() => indexedNodes[selectedNode], [indexedNodes, selectedNode, indexedNodes[selectedNode]]);
    const [helpText, setHelpText] = useState(null)
    const [openHelp, setOpenHelp] = useState(false)

    const getHelp = useCallback(async () => {
        try {

            const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/get_help_documentation`, {class: indexedNodes[selectedNode].class}, { withCredentials: true })
            setHelpText(res.data)
        } catch (error) {
            console.error(error)
            dispatch({
                type: "error",
                error
            })
        }

    }, [indexedNodes[selectedNode].class, dispatch])

    const toggleHelp = useCallback(()=> {
        setOpenHelp(prev=>!prev)

    }, [setOpenHelp])

    useEffect(() => {
        setHelpText(null)
        getHelp()
    }, [getHelp, setHelpText, indexedNodes[selectedNode].class])

    const innerHtml = useMemo(()=>({__html: helpText}), [helpText])

    return (<>
        <Grid item xs={12}>
            <Drawer anchor="right" open={openHelp} onClose={toggleHelp}>
                <Grid style={{padding: "2rem"}} spacing={3} container>
                    <Grid item xs={2}>
                        <IconButton onClick={toggleHelp}>
                            <CancelIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h3">{data.class} Help</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {helpText ? <div dangerouslySetInnerHTML={innerHtml}></div> : <CircularProgress style={{margin: "5rem", }} />}
                    </Grid>
                </Grid>
            </Drawer>
            <Typography variant="h5">{data.Name}</Typography>
            <Typography variant="overline">{data.class}</Typography>
            <IconButton onClick={toggleHelp}>
                <HelpIcon />

            </IconButton>
            <Divider style={{marginBottom: "1rem"}} />
            <ObjectMetadata {...props} />
            <ObjectAssemblyInfo {...props} />
        </Grid>
    </>);
};
