import toast from "react-hot-toast"
import { setError } from "../error/reducers"
import { AsyncTaskConfig } from "./consts"
import { clearInitiator, asyncSuccess } from "./reducers"

const toastOptions = (endpoint, additional) => ({id: endpoint, position: "bottom-center", ...additional, style: {minWidth: "30%"}})

export const createAsyncProcessingEndpoints = (builder) => ({
    startAsyncTask: builder.mutation({
        query: ({endpoint, body, method}) => ({
            url: `api/${AsyncTaskConfig[endpoint].route}`,
            body,
            method: method ?? "POST",
        }),
        onQueryStarted: async ({endpoint}, {dispatch, queryFulfilled}) => {
            try {
                dispatch(clearInitiator())
                if (AsyncTaskConfig[endpoint].usesToasts) toast.loading("Scheduling async task...", toastOptions(endpoint))
                const {data} = await queryFulfilled
                if (AsyncTaskConfig[endpoint].usesToasts) toast.loading(data.response.status, toastOptions(endpoint))
            } catch ({error}) {
                dispatch(setError({error, workflow: AsyncTaskConfig[endpoint].slice, key: endpoint}))
                if (AsyncTaskConfig[endpoint].usesToasts) toast.dismiss(endpoint)
            }
        },
        transformResponse: (response, meta, arg) => {
            return {response, taskLocation: meta.response.headers.get("location")}
        }
    }),
    checkAsyncStatus: builder.query({
        query: ({endpoint, url}) => ({
            url,
            method: "GET",
        }),
        onQueryStarted: async ({endpoint}, {dispatch, queryFulfilled}) => {
            try {
                const {data} = await queryFulfilled
                if (data.result) {
                    dispatch(asyncSuccess({endpoint, result: data.result}))
                    if (AsyncTaskConfig[endpoint].usesToasts) toast.success("Success!", toastOptions(endpoint))
                } else {
                    if (AsyncTaskConfig[endpoint].usesToasts) toast.loading(data.message.replaceAll("_", " "),  toastOptions(endpoint))
                }
            } catch ({error}) {
                dispatch(setError({error, workflow: AsyncTaskConfig[endpoint].slice, key: endpoint}))
                if (AsyncTaskConfig[endpoint].usesToasts) toast.dismiss(endpoint)
            }
        }
    }),
})