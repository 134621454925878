// Hooks
import toast from "react-hot-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Recharts
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine
} from "recharts";

// MUI
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

///// Umiverse Imports

// Lib Hooks
import {
    useSvgAndPng,
    useRisingEdgeEffect,
    useSVG
} from "../../../clientLib/hooks";

// Redux
import { ubemIoApi } from "../../../redux/services/ubem";
import {
    selectBreakdown,
    selectMetric,
    selectNormalized,
    selectBarChartData,
    selectScenario,
    selectSortedScenarioNames
} from "../../../redux/slices/analysis/selectors";
import {
    MetricConfig,
    BreakdownConfig,
    Breakdown
} from "../../../redux/slices/analysis/consts";
import { useTabNavigation } from "../../../redux/slices/navigation/hooks";

// Components
import TargetLines from "./TargetLines";
import BarChartsOverlayControls from "./BarChartsOverlayControls";
import ChartSidebar from "./ChartSidebar";
import { setSelectedScenario } from "../../../redux/slices/analysis/reducers";

const { useLazyQuery: useExportVizData } = ubemIoApi.endpoints.exportVizData;

export default () => {
    const dispatch = useDispatch();

    const { ref, exportPng, exportSvg } = useSvgAndPng({
        title: "ubem-data-visualization"
    });
    const [exportVizDataTrigger, results] = useExportVizData();
    const exportData = useCallback(() => exportVizDataTrigger(), [
        exportVizDataTrigger
    ]);
    const selectedScenario = useSelector(selectScenario);
    const scenarioNames = useSelector(selectSortedScenarioNames);
    const { goToTab } = useTabNavigation();

    const normalized = useSelector(selectNormalized);

    const [showSolar, setShowSolar] = useState(true);
    const toggleSolar = useCallback(
        (_, solar) => {
            setShowSolar(solar);
        },
        [setShowSolar]
    );

    const [showTargets, setShowTargets] = useState(false);
    const toggleTargets = useCallback(
        (_, targets) => {
            setShowTargets(targets);
        },
        [setShowTargets]
    );

    const [showDropLines, setShowDropLines] = useState(false);
    const toggleDropLines = useCallback(
        (_, dropLines) => {
            setShowDropLines(dropLines);
        },
        [setShowDropLines]
    );

    useRisingEdgeEffect({
        condition: showDropLines && showSolar,
        callback: () =>
            toast("% drops relative to baseline scenario total.", {
                duration: 3000,
                position: "bottom-right"
            })
    });
    useRisingEdgeEffect({
        condition: !showDropLines && showSolar && !normalized,
        callback: () =>
            toast("% drops relative to column total.", {
                duration: 3000,
                position: "bottom-right"
            })
    });

    useEffect(() => {
        if (!selectedScenario) dispatch(setSelectedScenario(scenarioNames[0]));
    }, [scenarioNames, selectedScenario, dispatch]);

    return (
        <Fade timeout={1000} in={true}>
            <Box>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <BarChartWithPng
                        showTargets={showTargets}
                        showSolar={showSolar}
                        // setPngCallback={setPngCallback}
                        // setSvgCallback={setSvgCallback}
                        ref={ref}
                        showDropLines={showDropLines}
                    />
                    <Grid item>
                        <ChartSidebar
                            pngCallback={exportPng}
                            svgCallback={exportSvg}
                            exportData={exportData}
                            usesScenarioSelect={false}
                        >
                            <BarChartsOverlayControls
                                showTargets={showTargets}
                                toggleTargets={toggleTargets}
                                showDropLines={showDropLines}
                                toggleDropLines={toggleDropLines}
                                showSolar={showSolar}
                                toggleSolar={toggleSolar}
                            />
                        </ChartSidebar>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );
};

const BarChartWithPng = React.forwardRef(
    ({ showSolar, showTargets, showDropLines }, ref) => {
        const breakdown = useSelector(selectBreakdown);
        const metric = useSelector(selectMetric);
        const normalized = useSelector(selectNormalized);
        const { results: data, graphKeys, baseline } = useSelector(
            selectBarChartData
        );

        const targets = useSelector(state => state.analysis.targets);

        const config = {
            colorPicker: BreakdownConfig[breakdown].colorPicker,
            stackId: 1,
            dataKeys: graphKeys,
            yAxisLabel: `${MetricConfig[metric].label}: ${
                MetricConfig[metric].units
            }${normalized ? " / m^2" : ""} / yr`
        };

        return (
            <Box height={"60vh"} width="63vw">
                <ResponsiveContainer width={"99%"} height={"99%"}>
                    <BarChart
                        ref={ref}
                        width={800}
                        height={500}
                        data={data}
                        // onClick={this.props.click}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        {/* Chart Metadata */}
                        {/* -------------- */}
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="scenario" />
                        <YAxis
                            label={{
                                value: config.yAxisLabel,
                                angle: -90,
                                position: "insideBottomLeft",
                                textAnchor: "middle"
                            }}
                            domain={["auto", dataMax => dataMax * 1.1]}
                            width={100}
                            tickFormatter={val =>
                                new Intl.NumberFormat("en").format(val)
                            }
                        />
                        <RechartsTooltip
                            cursor={false}
                            formatter={
                                normalized
                                    ? val =>
                                          `${MetricConfig[metric].formatter(
                                              val
                                          )}/m2`
                                    : MetricConfig[metric].formatter
                            }
                        />
                        <Legend
                            verticalAlign="top"
                            height={36}
                            payload={config?.dataKeys?.map((key, i) => ({
                                value: key,
                                type: "squre",
                                id: key,
                                color: config?.colorPicker(key, i)
                            }))}
                        />
                        {/* -------------- */}

                        {/* Reference Lines */}
                        {/* --------------- */}
                        {showDropLines &&
                            data.length > 1 &&
                            breakdown !== Breakdown.Archetype &&
                            data.map((dataTable, i) => (
                                <ReferenceLine
                                    key={`reference-line-${i}-horizontal`}
                                    stroke="black"
                                    strokeWidth={2}
                                    isFront={true}
                                    segment={[
                                        {
                                            x: baseline.scenario,
                                            y: baseline.total * 1.03
                                        },
                                        {
                                            x: dataTable.scenario,
                                            y: baseline.total * 1.03
                                        }
                                    ]}
                                />
                            ))}
                        {showDropLines &&
                            data.length > 1 &&
                            breakdown !== Breakdown.Archetype &&
                            data.map(
                                (dataTable, i) =>
                                    i > 0 && (
                                        <ReferenceLine
                                            key={`reference-line-${i}-arrow-head`}
                                            stroke="black"
                                            fill="black"
                                            strokeWidth={2}
                                            isFront={true}
                                            label={{
                                                value:
                                                    dataTable.drop !== 0
                                                        ? `${dataTable.drop}%`
                                                        : "",
                                                position: "right"
                                            }}
                                            shape={<CustomBarArrowHead />}
                                            segment={[
                                                {
                                                    x: dataTable.scenario,
                                                    y: dataTable.total
                                                },
                                                {
                                                    x: dataTable.scenario,
                                                    y: baseline.total * 1.03
                                                }
                                            ]}
                                        />
                                    )
                            )}
                        {showDropLines &&
                            data.length > 1 &&
                            breakdown !== Breakdown.Archetype &&
                            data.map((dataTable, i) => (
                                <ReferenceLine
                                    key={`reference-line-${i}-vertical`}
                                    stroke="black"
                                    strokeWidth={2}
                                    isFront={true}
                                    label={{
                                        value:
                                            dataTable.drop !== 0
                                                ? `${dataTable.drop}%`
                                                : "",
                                        position: "right"
                                    }}
                                    segment={[
                                        {
                                            x: dataTable.scenario,
                                            y: dataTable.total
                                        },
                                        {
                                            x: dataTable.scenario,
                                            y: baseline.total * 1.03
                                        }
                                    ]}
                                />
                            ))}
                        {/* --------------- */}

                        {/* Main Bars */}
                        {/* --------- */}
                        {config?.dataKeys?.map((key, i) => (
                            <Bar
                                key={`bar-${key}-${i}`}
                                dataKey={key}
                                stackId={
                                    breakdown === Breakdown.Archetype
                                        ? i + 1
                                        : 1
                                }
                                fill={config.colorPicker(key, i)}
                            />
                        ))}
                        {/* --------- */}

                        {/* Solar Potential */}
                        {/* --------------- */}
                        {!normalized &&
                            showSolar &&
                            (Breakdown.Archetype !== breakdown ? (
                                <Bar
                                    dataKey={"PV"}
                                    stackId={1}
                                    // barSize={10}
                                    fill={"#fada00"}
                                    fillOpacity={0.8}
                                    stroke={"#fada00"}
                                    strokeWidth={4}
                                    shape={
                                        <CustomBarTarget
                                            baseline={baseline}
                                            useBaseline={showDropLines}
                                        />
                                    }
                                    // isAnimationActive={false}
                                />
                            ) : (
                                config?.dataKeys?.map((key, i) => (
                                    <Bar
                                        key={`bar-${key}-PV-${i}`}
                                        dataKey={`${key}-PV`}
                                        stackId={i + 1}
                                        // barSize={10}
                                        fill={"#fada00"}
                                        fillOpacity={0.8}
                                        stroke={"#fada00"}
                                        strokeWidth={4}
                                        shape={
                                            <CustomBarTarget
                                                baseline={baseline}
                                                useBaseline={showDropLines}
                                                archetype={key}
                                            />
                                        }
                                        // isAnimationActive={false}
                                    />
                                ))
                            ))}
                        {/* --------------- */}

                        {/* Target Lines */}
                        {TargetLines({ showTargets, baseline })}
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        );
    }
);

const CustomBarTarget = props => {
    const {
        stroke,
        fill,
        x,
        y,
        width,
        height,
        PV,
        PVTotal,
        Total,
        fillOpacity,
        strokeWidth,
        baseline,
        useBaseline,
        total,
        archetype
    } = props;

    const top = y + height;
    const pvLevel = y;
    const centerLine = x + width / 2;
    const percentDrop = Math.round(
        ((PV ?? props[`${archetype}-PV`]) /
            (useBaseline ? baseline.total : total)) *
            100
    );
    if (Math.abs(top - y) < 5) {
        return <svg></svg>;
    }

    return (
        <g>
            <rect
                x={x}
                y={top}
                width={width}
                height={-height}
                stroke="none"
                fill={"white"}
                fillOpacity={fillOpacity}
            />
            <line
                x1={centerLine - width / 2}
                x2={centerLine + width / 2}
                y1={y}
                y2={y}
                stroke={stroke}
                strokeWidth={strokeWidth}
            />
            <line
                x1={centerLine - width / 2}
                x2={centerLine - width / 2}
                y1={y - 8}
                y2={y + 8}
                stroke={stroke}
                strokeWidth={strokeWidth}
            />
            <line
                x1={centerLine + width / 2}
                x2={centerLine + width / 2}
                y1={y - 8}
                y2={y + 8}
                stroke={stroke}
                strokeWidth={strokeWidth}
            />
            {/* <line x1={centerLine-width/2} x2={centerLine+ width/2} y1={top} y2={top} stroke={stroke} strokeWidth={1} /> */}
            <line
                x1={centerLine}
                x2={centerLine}
                y1={y - 2}
                y2={top}
                stroke={stroke}
                strokeWidth={2}
            />
            <polygon
                points={`${centerLine} ${y - 2},${centerLine - 9} ${y -
                    12},${centerLine + 9} ${y - 12}`}
                stroke={stroke}
                fill={fill}
            />
            <text
                x={centerLine + 10}
                y={y + height / 2}
                // className="small"
                textAnchor="start"
                // fill={fill}
                // stroke={fill}
            >
                {percentDrop}%
            </text>
        </g>
    );
};

const CustomBarArrowHead = props => {
    const { stroke, fill, fillOpacity, strokeWidth, x1, x2, y1, y2 } = props;

    return (
        <g>
            <polygon
                points={`${x1} ${y1},${x1 - 5} ${y1 - 5},${x1 + 5} ${y1 - 5}`}
                stroke={stroke ?? "black"}
                fill={fill ?? "black"}
            />
        </g>
    );
};
