import { createSlice } from '@reduxjs/toolkit'
import { Workflow } from '../../consts'
import { AsyncTask } from '../async-processing/consts'
import { addAsyncExtraReducers } from '../async-processing/utils'
import { addErrorExtraReducers } from '../error/utils'
import { 
    MaxVizFileUploads, // Const
    FuelParameterConfig, // Config objs
    EndUseParameterConfig, 
    FuelParameters, // Lists of keys
    FuelSources, 
    EndUses,
    TimeSeriesMode, // Enums
    Breakdown,
    BreakdownConfig,
    Metric,
    AdoptionConfigMode,
    SolarParameterConfig,
    GraphType,
    ScenarioSettingsPage,
    SettingsPage
} from './consts'

const workflow = Workflow.analysis

const buildInitialSettingsPageSettings = () => {
    return {
        scenarioSettingsGroup: ScenarioSettingsPage.EndUses,
        tab: SettingsPage.Targets
    }
}

const buildInitialTimeSeriesSettings = () => {
    const timeSeriesSettings = {}
    FuelParameters.forEach(parameterKey => {
        timeSeriesSettings[parameterKey] = {}
        FuelSources.forEach(fuelSource => {
            timeSeriesSettings[parameterKey][fuelSource] = {
                mode: TimeSeriesMode.Exponential,
                rate: 0.00,
                list: [],
            }
        })
    })
    return timeSeriesSettings
}

const buildInitialSolarSettings = () => {
    const settings = {}
    Object.entries(SolarParameterConfig).forEach(([parameter, {defaultValue}]) => {
        settings[parameter] = defaultValue

    })
    return settings
}

const initialState = {
    selectedMetric: Metric.Carbon,
    selectedBreakdown: Breakdown.Total,
    selectedScenario: undefined,
    graphMode: GraphType.bar,
    normalized: false,
    targetsConfirmed: false,
    targets: {
        metric: Metric.Carbon,
        near: {
            value: 25,
            year: 2035,
        },
        long: {
            value: 5,
            year: 2060,
        }
    },
    // TODO: make these their own slices?
    timeSeriesConfirmed: false,
    timeSeriesSettings: {
        ...buildInitialTimeSeriesSettings()
    },
    settingsPage: buildInitialSettingsPageSettings(),
    // Settings related to uploaded files / computed data
    filenames: Array(MaxVizFileUploads).fill().map( (_, i) => ""),
    scenarioNames: Array(MaxVizFileUploads).fill().map( (_, i) => `Scenario ${i+1}`), // user editable scenario names
    data: null, 
    reports: null,
    settings: {}, // settings per scenario
    settingsConfirmed: {},
    adoption: {
        metric: null,
        mode: AdoptionConfigMode.Fixed,
        data: null,
        cacheKey: false,
        nameColumn: null,
        assignments: {
            baseline: null,
            ecms: [null,null],
        },
        probabilities: {
            value: [0.05,0.05],
            data: null,
        },
        occupation: {
            value: 1,
            data: null,
        },
        iterations: 20,
    },
    isProcessing: false,
    error: null,
    errors: {}
}


export const analysisSlice = createSlice({
    name: workflow,
    initialState,
    reducers: {
        setScenarioName: (state, {payload}) => {
            state.scenarioNames[payload.index] = payload.name
        },
        setUploadFilename: (state, {payload}) => {
            state.filenames[payload.index] = payload.name
        },
        setTaskUrl: (state, {payload}) => {
            state.taskUrl = payload
        },
        loadData: (state, {payload}) => {
        },
        clearData: (state) => {
            handleClearData(state)
        },
        clearReports: (state) => {
            state.reports = null
        },
        confirmSettings: (state, {payload}) => {
            if (payload.all) {
                state.timeSeriesConfirmed = true
                state.targetsConfirmed = true
                Object.keys(state.settingsConfirmed).forEach(scenario => {
                    Object.keys(state.settingsConfirmed[scenario]).forEach(group => {
                        state.settingsConfirmed[scenario][group] = true
                    })
                })
                return
            }
            switch (payload.settingsGroup) {
                case SettingsPage.TimeSeries:
                    state.timeSeriesConfirmed = true
                    return
                case SettingsPage.Targets:
                    state.targetsConfirmed = true
                    return
                default:
                    state.settingsConfirmed[payload.settingsGroup][payload.scenarioSettingsGroup] = true
                    return
            }
        },
        updateFuelScenarioSettings: (state, {payload}) => {
            const {scenarioName, parameterKey, fuelSource, value} = payload
            state.settings[scenarioName].fuels[parameterKey][fuelSource] = value
            state.adoption.cacheKey = false
        },
        updateEndUseScenarioSettings: (state, {payload}) => {
            const {scenarioName, endUse, parameter, value} = payload
            state.settings[scenarioName].endUses[endUse][parameter] = value
            state.adoption.cacheKey = false
        },
        updateSolarScenarioSettings: (state, {payload}) => {
            const {scenarioName, parameter, value} = payload
            state.settings[scenarioName].solar[parameter] = value
            state.adoption.cacheKey = false
        },
        setMetric: (state, {payload}) => {
            state.selectedMetric = payload
        },
        setBreakdown: (state, {payload}) => {
            state.selectedBreakdown = payload
        },
        setNormalized: (state, {payload}) => {
            state.normalized = payload
        },
        setSelectedScenario: (state, {payload}) => {
            state.selectedScenario = payload
            if (payload===null || payload === undefined) {
                state.selectedBreakdown = Breakdown.Total
                state.normalized = false
            }
        },
        setGraphMode: (state, {payload}) => {
            state.graphMode = payload
        },
        setTarget: (state, {payload}) => {
            state.targets[payload.target][payload.parameter] = payload.value
        },
        setTargetMetric: (state, {payload}) => {
            state.targets.metric = payload
        },
        updateTimeSeriesSetting: (state, {payload}) => {
            state.timeSeriesSettings[payload.fuelParameter][payload.fuelSource][payload.parameter] = payload.value
            state.adoption.cacheKey = false
        },
        updateSettingsPageTab: (state, {payload})  => {
            if (payload) {
                state.settingsPage.tab = payload
            }
        },
        updateScenarioSettingsPageGroup: (state, {payload})  => {
            if (payload) {
                state.settingsPage.scenarioSettingsGroup = payload
            }
        },
        updateTracksBaseline: (state, {payload}) => {
            state.settings[payload.scenarioName].tracksBaseline[payload.settingsGroup] = payload.value

        },
        // TODO: Adoption baseline should be coupled with "global" baseline, 
        // which is just 0 index scenario
        setBaselineAssignment: (state, {payload}) => {
            state.adoption.assignments.baseline = payload
            state.adoption.cacheKey = false
        },
        setEcmAssignment: (state, {payload}) => {
            state.adoption.assignments.ecms[payload.index] = payload.scenario
            state.adoption.cacheKey = false
        },
        setAdoptionOccupation: (state, {payload}) => {
            state.adoption.occupation.value = payload.value
            state.adoption.cacheKey = false
        },
        setAdoptionProbability: (state, {payload}) => {
            state.adoption.probabilities.value[payload.index] = payload.value
            state.adoption.cacheKey = false
        },
        setAdoptionMode: (state, {payload}) => {
            state.adoption.mode = payload
            state.adoption.cacheKey = false
            switch (payload) {
                case AdoptionConfigMode.GIS:
                    state.adoption.occupation.value = null
                    state.adoption.probabilities.value.forEach((_, i) => state.adoption.probabilities.value[i] = null)
                    break
                case AdoptionConfigMode.Fixed:
                    state.adoption.occupation.value = 1
                    state.adoption.probabilities.value.forEach((_, i) => state.adoption.probabilities.value[i] = 0.05)
                    break
            }
        },
        setAdoptionGisIdColumn: (state, {payload}) => {
            state.adoption.nameColumn = payload.value
            state.adoption.cacheKey = false
        }, 
        setAdoptionCacheKey: (state, {payload}) => {
            state.adoption.cacheKey = payload
            state.adoption.metric = payload
        }

    },
    extraReducers: (builder) => {
        addErrorExtraReducers(builder, {workflow})
        addAsyncExtraReducers(builder, {workflow, handlers})
    }
})

const handleVizFileData = (state, { payload }) => {
    const { data, reports } = payload.result
    state.data = {}
    state.settings = {}
    state.settingsConfirmed = {}
    state.settingsPage = buildInitialSettingsPageSettings()
    state.adoption.cacheKey = false
    Object.entries(data).forEach(( [scenario, {settings, sort_order: sortOrder, archetype, archetype_normalized, use, use_normalized}] ) => {
        switch (sortOrder) {
            case 0:
                state.selectedScenario = scenario
                state.adoption.assignments.baseline = scenario
                break;
            case 1:
                state.adoption.assignments.ecms[0] = scenario
                break;
            case 2:
                state.adoption.assignments.ecms[1] = scenario
                break;

        }

        // Extract just the desired values
        state.data[scenario] = {
            [BreakdownConfig.Archetype.initialData]: {normalized: archetype_normalized, original: archetype},
            [BreakdownConfig.Use.initialData]: {normalized: use_normalized, original: use},
            sortOrder
        }
        // Load default fuel settings from sdl-common/project-settings.json
        const fuels = {}
        Object.entries(FuelParameterConfig).forEach( ([  parameterKey, {umiSuffix}  ]) => {
            const parameterSettings = {}
            FuelSources.forEach(fuelSource => {
                parameterSettings[fuelSource] = settings[`${fuelSource}${umiSuffix}`]
            })
            fuels[parameterKey] = parameterSettings
        })

        // Initialize default values for endUses
        const endUses = {}
        EndUses.forEach((endUseKey) => {
            const endUse = {}
            Object.entries(EndUseParameterConfig).forEach( ([parameterKey, { defaultValue, usedBy } ]) => {
                if (usedBy.includes(endUseKey)) {
                    endUse[parameterKey] = defaultValue
                }
            })
            endUses[endUseKey] = endUse
        })

        state.settings[scenario] = {
            fuels,
            endUses,
            solar: buildInitialSolarSettings(),
            tracksBaseline: {
                [ScenarioSettingsPage.PV]: sortOrder !== 0,
                [ScenarioSettingsPage.Fuels]: sortOrder !== 0,
                [ScenarioSettingsPage.EndUses]: sortOrder !== 0,
            }
        }
        state.settingsConfirmed[scenario] = {
            [ScenarioSettingsPage.PV]: false,
            [ScenarioSettingsPage.EndUses]: false,
            [ScenarioSettingsPage.Fuels]: false,
        }
    })
    state.reports = reports

}

const handleAdoptionSuccess = (state, { payload }) => {
    state.adoption.data = payload.result
}

const handleClearAdoption = (state) => {
    state.adoption.data = null
}

const handleClearData = state => {
    state.reports = null
    state.data = null
    state.settingsConfirmed = {}
    state.settings = {}
    state.selectedScenario = undefined
    state.settingsPage = buildInitialSettingsPageSettings()
    state.adoption = initialState.adoption
}

const handlers = {
    [AsyncTask.computeAdoption]: {
        onStart: handleClearAdoption,
        onSuccess: handleAdoptionSuccess
    },
    [AsyncTask.processVizFiles]: {
        onStart: handleClearData,
        onSuccess: handleVizFileData
    }
}
// Action creators are generated for each case reducer function
export const { 
    setScenarioName, 
    setUploadFilename, 
    setTaskUrl,
    loadData,
    clearData,
    clearReports,
    confirmSettings,
    updateTracksBaseline,
    updateFuelScenarioSettings,
    updateEndUseScenarioSettings,
    updateSolarScenarioSettings,
    setMetric,
    setNormalized,
    setBreakdown,
    setSelectedScenario,
    setGraphMode,
    setTarget,
    setTargetMetric,
    updateTimeSeriesSetting,
    updateSettingsPageTab,
    updateScenarioSettingsPageGroup,
    setBaselineAssignment,
    setEcmAssignment,
    setAdoptionOccupation,
    setAdoptionProbability,
    setAdoptionMode,
    setAdoptionGisIdColumn,
    setAdoptionCacheKey,
} = analysisSlice.actions

export default analysisSlice.reducer