import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { useMemo } from "react"

export const FuelType = {
    None: 0,
    Electricity: 1,
    "Natural Gas": 2,
    "Propane Gas": 3,
    "Fuel Oil 1": 4,
    "Fuel Oil 2": 5,
    Diesel: 6,
    Gasoline: 7,
    Coal: 8,
    "Other Fuel 1": 9,
    "Other Fuel 2": 10,
    "Steam": 11,
    "District Heating": 12,
    "District Cooling": 13,
}

export const IdealLimitType = {
    "No Limit": 0,
    "Limit Flow Rate": 1,
    "Limit Capacity": 2,
    "Limit Flow Rate and Capacity": 3,
}

export const enumKeys = {
    DimmingType: {
        Continuous: 0,
        Off: 1, 
        Stepped: 2
    },
    HeatingFuelType: {
        ...FuelType,
    },
    CoolingFuelType: {
        ...FuelType,
    },
    HeatRecoveryType: {
        None: 0,
        Enthalpy: 1,
        Sensible: 2,
    },
    EconomizerType: {
        "No Economizer" : 0,
        "Differential Dry Bulb": 1,
        "Differential Enthalpy": 2,
    },
    HeatingLimitType: {
        ...IdealLimitType,
    },
    CoolingLimitType: {
        ...IdealLimitType
    },
    VentilationType: {
        Natural: 0,
        Intake: 1,
        Exhaust: 2,
        Balanced: 3,
    },
    ShadingSystemType: {
        "Exterior Shade": 0,
        "Interior Shade": 1,
    },
    WindowSettingType: {
        "External Window": 0,
        "Internal Window": 1,
    }
}

export const ObjectDataFieldEnum = ({ parentClass, keyName, label, value, onChange}) => {
    const lookupName = useMemo(() => keyName==="Type" ? `${parentClass}Type`: keyName, [keyName, parentClass])

	return (
            <FormControl fullWidth >
                <InputLabel id={`${keyName}-enum-selector-label`}>{ label}</InputLabel>
                <Select
                    labelId={ `${keyName}-enum-selector-label` }
                    id={ `${keyName}-enum-selector-` }
                    value={value}
                    onChange={onChange}
                >
                    {Object.entries(enumKeys[lookupName]).map(([enumVal, i]) => <MenuItem key={`enum-selector-menu-${keyName}-${enumVal}`} value={i}>{enumVal}</MenuItem>)}
                </Select>
            </FormControl>

    )
}