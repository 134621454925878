// Hooks
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

///// Umiverse Imports

// Redux
import { addMeasureToTemplate, setMeasureContext } from "../../../redux/slices/upgrades/reducers"

// Components
import MeasureProp from "./MeasureProp"
import { selectMeasureContext, selectTemplateSelection } from "../../../redux/slices/upgrades/selectors"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2rem",
    },
    chip: {
        marginRight: "0.5rem"
    },
    title: {
        marginBottom: "0.5rem"
    },
    divider: {
        margin: "1rem 0"
    },
    info: {
        marginTop: "1rem",
    },
    infoText: {
        margin: "1rem",
    },
    fullWidthButton: {
        width: "100%",
    }


}))

export default () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const measureContext = useSelector(selectMeasureContext)
    const template = useSelector(selectTemplateSelection)


    const {"Measure.Name": MeasureName, "Measure.Category": Category, "Measure.BuildingSystems": BuildingSystems, "Measure.DescriptionShort": descriptionShort, Name: PresetName, props_config, preset_values, } = measureContext ?? {}

    const assign = useCallback(() => {
        dispatch(addMeasureToTemplate({template, measure: measureContext}))
        dispatch(setMeasureContext(null))
    }, [template, dispatch, measureContext])

    if (!measureContext) {
        return <Typography className={classes.infoText}>No measure selected.</Typography>
    }

	return (
        <Box className={classes.root}>
            <Typography className={classes.title} variant="h5">{MeasureName}: {PresetName}</Typography>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography variant="overline">Category: </Typography><Chip size="small" className={classes.chip} label={Category}/>
                </Grid>
                <Grid item>
                    <Typography variant="overline">Building Systems: </Typography>{BuildingSystems.map((system,i) => <Chip size="small" key={`system-chip-${system}-${i}`} className={classes.chip} label={system}/>)}
                </Grid>
            </Grid>
            <Typography className={classes.info} variant="body2">{descriptionShort}</Typography>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
                {Object.values(props_config).map((config) => (
                    <Grid key={`${config.Name}-${preset_values[config.prop]?.Value}`} item xs={3}>
                        <MeasureProp config={config} preset_values={preset_values}/>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button onClick={assign} className={classes.fullWidthButton} variant="contained" color="primary" size="small">Add Package</Button>
                </Grid>
            </Grid>
        </Box>
    )
}