// Hooks
import {useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// MUI
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

///// Umiverse Imports

// Redux
import { selectWorkflowCompletionConfig } from '../redux/slices/completion/selectors';
import { setCompletedWorkflow } from '../redux/slices/completion/reducers';
import WorkflowCompletionAction from './WorkflowCompletionAction';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '80vw',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  actions: {
    marginTop: theme.spacing(2)
  }
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const {title, message, actions}= useSelector(selectWorkflowCompletionConfig)
    const [showToast, setShowToast] = useState(false) // useState(![undefined, null].includes(title))

    useEffect(() => {
        if (title) {
            setShowToast(true)
        }
    }, [title, setShowToast])

    const handleClose = (event, reason) => {
        dispatch(setCompletedWorkflow(null))
        setShowToast(false);
    };

    return (
        <div className={classes.root}>
            <Dialog open={showToast} onClose={handleClose}  >
                <Alert onClose={handleClose} severity="success" color="info">
                    <Typography variant="h5">{title}</Typography>
                    <Typography variant="body2">{message} Choose what to do next:</Typography>
                    <Grid container spacing={2} className={classes.actions}>
                        {actions?.map((config, i) => (
                            <Grid item key={`completion-action-${i}`}> 
                                <WorkflowCompletionAction handleClose={handleClose} {...config} />
                            </Grid>
                        ))}
                    </Grid>
                </Alert>
            </Dialog>
        </div>
    )
}


