import { useCallback } from "react";
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export const Changelog = ({ changelog, }) => {
    return  (
        <Card variant="outlined">
            <CardContent>
                <Typography>Changelog</Typography>
                <Grid
                    container
                    spacing={1}
                >
                    {Object.entries(changelog).map(([templateName, log]) => (
                        <ChangelogEntry
                            key={`changelog-${templateName}`}
                            templateName={templateName}
                            log={log} />
                    ))}
                </Grid>
            </CardContent>
        </Card>
    ) 
};
const ChangelogEntry = ({ templateName, log }) => {
    const changes = {};

    log.map((change) => {
        Object.values(change).map(({ original_value, address, parameter, new_value }) => {
            const target = address.concat(parameter).join(":");
            if (!Object.keys(changes).includes(target))
                changes[target] = { original_value };
            changes[target].new_value = new_value;
            return;
        });
    });

    const castBooleanOrReturn = useCallback((val) => val === true ? "True" : val === false ? "False" : val.toFixed(2), []);

    return (
        <Grid
            item
            xs={6}
        >
            <Typography variant="overline">{templateName}</Typography>
            {Object.entries(changes).map(([target, { new_value, original_value }]) => (
                <Typography
                    variant="body2"
                    key={`${templateName}-${target}`}
                >
                    {target} : {castBooleanOrReturn(original_value)}{"\u2192"}{castBooleanOrReturn(new_value)}
                </Typography>
            ))}
        </Grid>
    );
};
