// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

export const Header = ({ name }) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            <DividerWrapper>
                <Typography variant="overline">
                    {name}
                </Typography>
            </DividerWrapper>
        </Grid>
    );

};
export const DividerWrapper = ({ children }) => {
    return (
        <>
            <Grid item xs>
                <Divider />
            </Grid>
            <Grid item>
                {children}
            </Grid>
            <Grid item xs>
                <Divider />
            </Grid>
        </>
    );
};
