// Hooks
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query/react";

// MUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem";
import { useNavigationEffect } from "../../../redux/slices/navigation/hooks";
import { mutationFixedCacheKeys } from "../../../redux/slices/umibuilder/endpoints";
import {
    computeErrors,
    setKeyFieldAssignment
} from "../../../redux/slices/umibuilder/reducers";
import { Entrypoint, KeyFields } from "../../../redux/slices/umibuilder/consts";
import {
    allFieldsAssigned,
    selectClimateZones,
    selectEntrypoint,
    selectErrorSummary,
    selectGeoJSON,
    selectGroupData,
    selectKeyFields
} from "../../../redux/slices/umibuilder/selectors";

// Components
import DisplayGISMap from "../components/DisplayGISMap";
import KeyFieldSelector from "../components/KeyFieldSelector";
import KeyFieldsErrorButton from "../components/KeyFieldsErrorButton";
import { useRisingEdgeEffect } from "../../../clientLib/hooks";
import KeyfieldsErrorBypassModal from "../components/KeyfieldsErrorBypassModal";

const useExportExchangeFile =
    ubemIoApi.endpoints.exportExchangeFile.useMutation;
const useExportErrors =
    ubemIoApi.endpoints.exportKeyFieldsErrorReport.useMutation;
const useConfirmKeyFields = ubemIoApi.endpoints.confirmKeyFields.useMutation;

export default () => {
    const dispatch = useDispatch();
    const entrypoint = useSelector(selectEntrypoint);
    const geoJSON = useSelector(selectGeoJSON);
    const assignments = useSelector(selectKeyFields);
    const canProceed = useSelector(allFieldsAssigned);
    const hasGroupData = useSelector(selectGroupData);
    const errorSummary = useSelector(selectErrorSummary);
    const climateZones = useSelector(selectClimateZones);

    const [
        triggerExportExchange,
        { isLoading: isExportingExchange }
    ] = useExportExchangeFile({
        fixedCacheKey: mutationFixedCacheKeys.exportExchangeFile
    });
    const [
        triggerExportErrorReport,
        { isLoading: isExportingErrors }
    ] = useExportErrors({
        fixedCacheKey: mutationFixedCacheKeys.exportKeyFieldsErrorReport
    });
    const [
        triggerConfirmKeyFields,
        { isLoading: isConfirmingKeyFields }
    ] = useConfirmKeyFields({
        fixedCacheKey: mutationFixedCacheKeys.confirmKeyFields
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const openConfirmModal = useCallback(() => {
        setShowConfirmModal(true);
    }, [setShowConfirmModal]);

    const closeConfirmModal = useCallback(() => {
        setShowConfirmModal(false);
    }, [setShowConfirmModal]);

    const confirmKeyFields = useCallback(() => {
        const keyFields = {
            ...assignments
        };
        if (errorSummary.id > 0 || errorSummary.duplicateId > 0) {
            keyFields.id = "N/A";
            dispatch(setKeyFieldAssignment({ field: "id", value: "N/A" }));
        }
        triggerConfirmKeyFields({ keyFields });
    }, [assignments, dispatch, errorSummary.id, errorSummary.duplicateId]);

    const exportExchangeFile = useCallback(() => {
        triggerExportExchange({ assignments });
    }, [assignments, triggerExportExchange]);

    const handleConfirm = useCallback(() => {
        if (errorSummary.any > 0) {
            openConfirmModal();
            return;
        }
        switch (entrypoint) {
            case Entrypoint.gis:
                exportExchangeFile();
                break;
            default:
                confirmKeyFields();
                break;
        }
    }, [
        entrypoint,
        confirmKeyFields,
        exportExchangeFile,
        openConfirmModal,
        errorSummary.any
    ]);

    const handleContinue = useCallback(() => {
        switch (entrypoint) {
            case Entrypoint.gis:
                exportExchangeFile();
                break;
            default:
                confirmKeyFields();
                break;
        }
    }, [
        entrypoint,
        confirmKeyFields,
        exportExchangeFile,
        openConfirmModal,
        errorSummary,
        dispatch
    ]);

    const exportErrorReport = useCallback(() => {
        triggerExportErrorReport({ geoJSON });
    }, [geoJSON, triggerExportErrorReport]);

    // Entrypoint not a dependency since we only want this runnin on first load
    useEffect(() => {
        if (entrypoint === Entrypoint.uio) {
            if (!hasGroupData) {
                dispatch(computeErrors());
                // confirmKeyFields()
            }
        }
    }, [dispatch, confirmKeyFields, hasGroupData]);

    // If the mode changes to UIO, automatically confirm keyfields
    useRisingEdgeEffect({
        callback: confirmKeyFields,
        condition: entrypoint !== Entrypoint.gis
    });
    // go to organize page on hasGroupData's rising edge
    useNavigationEffect({ destination: "organize", condition: hasGroupData });

    const [mapColorMode, setMapColorMode] = useState("anyErrors");

    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid
                item
                xs={12}
                md={6}
                container
                justifyContent="center"
                alignItems="center"
                spacing={5}
                direction="column"
            >
                <Typography variant="caption">
                    Select fields based on your GIS attributes to continue.
                </Typography>
                {KeyFields.map((keyField, i) => (
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        key={`keyfield-${keyField}-${i}-selector`}
                    >
                        <Grid item xs>
                            <KeyFieldSelector keyField={keyField} />
                        </Grid>
                        <Grid item>
                            <KeyFieldsErrorButton
                                keyField={keyField}
                                setMapColorMode={setMapColorMode}
                            />
                        </Grid>
                    </Grid>
                ))}
                <Grid item container spacing={3} justifyContent="space-around">
                    <Button
                        onClick={() => setMapColorMode(`overlapError`)}
                        color="secondary"
                        size="small"
                        disabled={errorSummary.overlap === 0}
                        variant="contained"
                    >
                        Show Overlap Errors
                    </Button>
                    <Button
                        onClick={() => setMapColorMode(`anyErrors`)}
                        size="small"
                        disabled={errorSummary.any === 0}
                        variant="contained"
                    >
                        Show All
                    </Button>
                    <Button
                        onClick={exportErrorReport}
                        size="small"
                        disabled={
                            Object.values(errorSummary).filter(a => a > 0)
                                .length === 0 || isExportingErrors
                        }
                        variant="contained"
                    >
                        Download Error Report
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <DisplayGISMap mapColorMode={mapColorMode} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
            >
                {entrypoint !== Entrypoint.uio && (
                    <Button
                        size="large"
                        onClick={handleConfirm}
                        disabled={!canProceed || isExportingExchange}
                        variant="contained"
                    >
                        Export UIO
                    </Button>
                )}
                {entrypoint !== Entrypoint.gis && (
                    <Button
                        size="large"
                        onClick={handleConfirm}
                        disabled={!canProceed || isConfirmingKeyFields}
                        variant="contained"
                    >
                        Confirm
                    </Button>
                )}
                {climateZones && (
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="overline">
                            Climate Zone: {climateZones.join(",")}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <KeyfieldsErrorBypassModal
                open={showConfirmModal}
                handleClose={closeConfirmModal}
                handleContinue={handleContinue}
                exportErrorReport={exportErrorReport}
            />
        </Grid>
    );
};
