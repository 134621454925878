import axios from "axios"
import Button from "@material-ui/core/Button"
import fileDownload from "js-file-download"
import { buildLibrary } from "../EditTemplateLibrary"
import { useCallback } from "react"
import { ConfigureDownload } from "../MeasuresWorkflow/ConfigureDownload"

export const ExportLibraryView = (props) => {
	const {state, dispatch} = props
    const {indexedNodes, inputFileType} = state

    const downloadLibrary = useCallback(async (options) => {
        const library = buildLibrary(indexedNodes)
        const config = {
            withCredentials: true,
        }
        dispatch({
            type: "set isUploading",
            isUploading: true,
        })
        try {

            const mutateRes = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/upload_file_to_mutate`, library, config);
            if (inputFileType == ".umi") {
                config.responseType = "blob"
            }
            const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/export`, options, config)
            const filename = res.headers["x-suggested-filename"]
            
            if (inputFileType.toLowerCase() === ".umi") {
                fileDownload(res.data, filename)
            } else {
                fileDownload(JSON.stringify(res.data, null, 4), filename)
            }
            setTimeout(()=>dispatch({type: "set isUploading", value: false}), 750)
        } catch (error) {
            dispatch({
                type: "error",
                error,
            })
        }

    }, [indexedNodes, inputFileType, dispatch])
	return (
        <ConfigureDownload download={downloadLibrary} inputFileType={inputFileType}/>
		// <Button variant="outlined" onClick={downloadLibrary}>Download Library</Button>
	)
}