import fileDownload from "js-file-download"

export const fileHandler = async (res) => {
	if (res.status === 500) {
		return res.json()
	} else {
		const data = await res.blob()
		const filename = res.headers.get("x-suggested-filename")
		fileDownload(data, filename)
		return {message: "successfully downloaded file"}
	}
}