import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import {Container, Row, Col, Button} from "react-bootstrap"
import { ErrorDisplay } from "../ErrorDisplay";
import './MergeTemplateLibraries.css'
import axios from "axios";
import fileDownload from "js-file-download";
import Navbar from  "../CustomNavbar"
import CircularProgress from "@material-ui/core/CircularProgress"

function TemplateLibraryDropZone({errorCb}) {
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const onDrop = useCallback((acceptedFiles) => {
        errorCb(null)
        setIsLoading(true)
        setIsSuccess(false)
        console.log(acceptedFiles)
        acceptedFiles.map(async (file) => {
            const data = new FormData();
            data.append("file", file)
            try {
                const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/merge_libraries`, data, {
                    withCredentials: true,
                    headers: {"Content-Type": "multipart/form-data"}
                })
                const filename = res.headers["x-suggested-filename"]
                fileDownload(JSON.stringify(res.data, null, 4), filename)
                setIsLoading(false)
                setIsSuccess(true)
                axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/remove_user_files`, {withCredentials: true})
            } catch (error) {
                setIsLoading(false)
                if (errorCb) errorCb(error)
                const res = await axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/remove_user_files`, {withCredentials: true})

            }
        })
    }, [errorCb])
    const {getRootProps, getInputProps, acceptedFiles, isDragActive} = useDropzone({
        onDrop,
        accept: "application/zip, application/x-zip-compressed, multipart/x-zip",
        maxFiles: 1,
    })

  return (
    <div className="template-dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="template-dropzone-text">
            {isDragActive ? "Drop the files here ..." : "Drag 'n' drop a zipped collection of libraries, or click to select the .zip"}
        </p>
        <ul className="list-group">
            {acceptedFiles.length > 0 && acceptedFiles.map((acceptedFile,i) => (
                <li className={ `list-group-item list-group-item-${isLoading ? "secondary" : isSuccess ? "success" : "danger"}`  } key={`accepted-files-${i}`}>
                    {acceptedFile.name}
                </li>
            ))}
        </ul>
        {isLoading && <CircularProgress />}
    </div>
  )
}
export default () => {
    const [error, setError] = useState(null)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/remove_user_files`, {withCredentials: true}).then(res => {
            console.log(res);
        });
        
    }, [])
    return (
        <div>
            <Navbar/>
            <Container>
                <Row>
                    <Col id="title">Merge Template Libraries</Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col id="description">Upload a zipped package of UMI template libraries to merge them into a single library.</Col>
                </Row>
                <ErrorDisplay error={error}/>

                <div>
                    <TemplateLibraryDropZone errorCb={setError} />
                </div>

            </Container>
        </div>
    )
}