import { useMemo } from "react"
import { ObjectDataField } from "./ObjectDataField"

export const sortedKeysByClass = {
    BuildingTemplate: [
        "Core",
        "Perimeter",
        "PartitionRatio",
        "Windows",
        "Structure",
        "Version",
        "Lifespan",
        "YearFrom",
        "YearTo",
    ],
    ZoneDefinition: [
        "Constructions",
        "InternalMassConstruction",
        "InternalMassExposedPerFloorArea",
        "Loads",
        "Conditioning",
        "DaylightWorkplaneHeight",
        "Ventilation",
        "DomesticHotWater",
        "DaylightMeshResolution"
    ],
    ZoneConditioning: [
        "HeatingSchedule",
        "CoolingSchedule",
        "MechVentSchedule",
        "IsHeatingOn",
        "IsCoolingOn",
        "IsMechVentOn",
        "HeatingSetpoint",
        "CoolingSetpoint",
        "MinFreshAirPerPerson",
        "HeatingCoeffOfPerf",
        "CoolingCoeffOfPerf",
        "MinFreshAirPerArea",
        "MaxHeatFlow",
        "MaxCoolFlow",
        "HeatRecoveryEfficiencySensible",
        "MaxHeatingCapacity",
        "MaxCoolingCapacity",
        "HeatRecoveryEfficiencyLatent",
        "HeatingFuelType",
        "CoolingFuelType",
        "EconomizerType",
        "HeatingLimitType",
        "CoolingLimitType",
        "HeatRecoveryType"


    ],
    ZoneLoad: [
        "OccupancySchedule", 
        "PeopleDensity", 
        "IsPeopleOn", 
        "EquipmentAvailabilitySchedule", 
        "EquipmentPowerDensity", 
        "IsEquipmentOn",
        "LightsAvailabilitySchedule", 
        "LightingPowerDensity", 
        "IsLightingOn", 
        "DimmingType", 
        "IlluminanceTarget" ,
    ],
    ZoneConstructionSet: [
        "Ground",
        "IsGroundAdiabatic",
        "Slab",
        "IsSlabAdiabatic",
        "Partition",
        "IsPartitionAdiabatic",
        "Facade",
        "IsFacadeAdiabatic",
        "Roof",
        "IsRoofAdiabatic",

    ],
    DomesticHotWaterSetting: [
        "WaterSupplyTemperature",
        "WaterTemperatureInlet",
        "FlowRatePerFloorArea",
        "WaterSchedule",
        "IsOn"
    ],
    VentilationSetting: [
        "IsBuoyancyOn",
        "IsWindOn",
        "Afn",
        "IsInfiltrationOn",
        "IsNatVentOn",
        "IsScheduledVentilationOn",
        "Infiltration",
        "NatVentSchedule",
        "ScheduledVentilationSchedule",
        "NatVentMaxRelHumidity",
        "NatVentZoneTempSetpoint",
        "ScheduledVentilationSetpoint",
        "NatVentMinOutdoorAirTemp",
        "NatVentMaxOutdoorAirTemp",
        "ScheduledVentilationAch",
    ],
    WindowSetting: [
        "Construction",
        "Type",
        "ShadingSystemType",
        "AfnWindowAvailability",
        "ZoneMixingAvailabilitySchedule",
        "ShadingSystemAvailabilitySchedule",
        "IsVirtualPartition",
        "IsZoneMixingOn",
        "IsShadingSystemOn",
        "AfnTempSetpoint",
        "ZoneMixingDeltaTemperature",
        "ShadingSystemSetpoint",
        "AfnDischargeC",
        "ZoneMixingFlowRate",
        "ShadingSystemTransmittance",
        "OperableArea",
    ]
}

export const ObjectSortedDataFields = (props) => {
    const { indexedNodes, selectedNode } = props.state
    console.log(indexedNodes[ selectedNode ])
    const keys = useMemo(() => sortedKeysByClass[indexedNodes[selectedNode].class] ? sortedKeysByClass[indexedNodes[selectedNode].class] : [], [indexedNodes, selectedNode, indexedNodes[selectedNode].class])
	return (
        <>
            {keys.map(keyName => <ObjectDataField key={`${keyName}-sorted-field`} keyName={keyName} {...props}  />)}
        </>

    )
}