import { filterAsyncTasksByWorkflow } from "./consts"
import { asyncSuccess, startAsyncTask } from "./reducers"


export const addAsyncExtraReducers = (builder, {workflow, handlers}) => {
    builder.addCase(startAsyncTask, (state, {payload}) => {
        if (filterAsyncTasksByWorkflow(workflow).includes(payload.endpoint)) {
            state.isProcessing = true
        }
        try {
            Object.entries(handlers).forEach(([task, {onStart}]) => {
                if (task === payload.endpoint && onStart) {
                    onStart(state, {payload})
                }
            })
        } catch (error) {
            console.error(error)
            state.runtimeError = {message: "Something went wrong while initiating an async request."}
        }
    })
    builder.addCase(asyncSuccess, (state, {payload}) => {
        if (filterAsyncTasksByWorkflow(workflow).includes(payload.endpoint)) {
            state.isProcessing = false
        }
        try {
            Object.entries(handlers).forEach(([task, {onSuccess}]) => {
                if (task === payload.endpoint && onSuccess) {
                    onSuccess(state, {payload})
                }
            })
        } catch (error) {
            console.error(error)
            state.runtimeError = {message: "Something went wrong while handling an async request result."}
        }

    })

}