import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
    name: 'error',
    initialState: {
        error: null
    },
    reducers: {
        setError: (state, {payload}) => {
            state.error = payload.error
        }, 
        clearError: (state, {payload}) => {
            state.error = null
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setError,
    clearError
} = errorSlice.actions

export default errorSlice.reducer