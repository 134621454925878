import { 
    setUploadFilename, 
    clearData,
    setAdoptionCacheKey,
} from "./reducers"
import {setError } from "../error/reducers"
import { fileHandler } from "../../services/utils"

export const analysisTags = ["VizDataProcessing"]

export const mutationFixedCacheKeys = {
    adoptionGisConfigFile: "adoptionGisConfigFile",
}

export const createAnalysisEndpoints = (builder) => ({
    uploadVizFile: builder.mutation({
        query: ({body}) => ({
            url: `api/analysis/upload_file`,
            method: "POST",
            body,
            responseHandler: "text",
        }),
        onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
            dispatch(clearData())
            try {
                const {data} = await queryFulfilled
                dispatch(setUploadFilename({index: arg.index, name: data}))
            } catch ({error}) {
                dispatch(setError({error, workflow: "analysis", key: "uploadVizFile"}))
                dispatch(setUploadFilename({index: arg.index, name: ""}))
            }
        }
    }),
    exportVizData: builder.query({
        query: () => ({
            url: `api/analysis/export_shapefiles`,
            responseHandler: fileHandler        
        }),
        onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
            try {
                const res = await queryFulfilled
            } catch ({error}) {
                dispatch(setError({error, workflow: "analysis", key: "exportVizData"}))
            }
        }
    }),
    computeAdoption: builder.mutation({
        query: (body) => ({
            url: "api/analysis/compute_adoption",
            method: "POST",
            body,
        }),
        transformResponse: (result) => {
            result.forEach(year => year.Year = new Date().getFullYear() + year.Year)
            return result
        },
        onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
            try {
                const res = await queryFulfilled
                dispatch(setAdoptionCacheKey(arg.metric))
            } catch ({error}) {
                dispatch(setError({error, workflow: "analysis", key: "computeAdoption"}))
            }
        }

    }),
    uploadAdoptionGisFile: builder.mutation({
        query: ({body}) => ({
            url: "api/analysis/adoption_gis_file",
            method: "POST",
            body
        }),
        onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
            try {
                const res = await queryFulfilled
            } catch ({error}) {
                dispatch(setError({error, workflow: "analysis", key: "uploadAdoptionGisFile"}))
            }
        }
    })
})