// Hooks
import { useState, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import EditIcon from "@material-ui/icons/Edit"
import Chip from "@material-ui/core/Chip"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, } from '@mui/x-data-grid';

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { hasUpgrades, isLoading, selectDisabledTabs, selectTemplateContext, selectTemplates, selectTemplateSelection } from "../../../redux/slices/upgrades/selectors"
import { useTabNavigation } from "../../../redux/slices/navigation/hooks"
import { mutationFixedCacheKeys } from "../../../redux/slices/upgrades/endpoints"

// Components
import AssignUpgradesDialog from "../components/AssignUpgradesDialog"
import { setTemplateSelection } from "../../../redux/slices/upgrades/reducers"

const { useMutation: upgradeAndExport } = ubemIoApi.endpoints.upgradeAndExport

const useStyles = makeStyles((theme) => ({
    fullWidthButton: {
        width: "100%",
    },
}))

const ChipEditCell = ({value, id}) => {
    const dispatch = useDispatch()
    const chips = value.map(label => <Chip color="primary" style={{marginRight: "0.3rem"}} key={`${label}-chip`} label={label}/>)
    const button = <Button key={`${id}-id-edit-button`} variant="outlined" color="primary" size="small" endIcon={<EditIcon />}>Edit</Button>
    return chips.concat(button)
}

const convertTemplatesToTable = (templates) => {
    const templateArray = []
    Object.entries(templates).forEach(([template, packageList]) => {
        templateArray.push({
            id: template,
            name: template,
            upgradeCount: Object.keys(packageList).length,
            measures: Object.keys(packageList),
            measuresWithPresets: Object.entries(packageList).map(([measureName, {Name: presetName}]) => `${measureName}: ${presetName}`),
        })
    })
    return templateArray
}

const CustomToolbar = () => {

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}


const columnsDef = [
    {
        field: "name",
        headerName: "Template",
        minWidth: 150,
        flex: 0.75,
        editable: false,
        renderCell: ({value}) => <><Tooltip title={value.replaceAll("_", " ")}><Typography>{value}</Typography></Tooltip></>
    },
    {
        field: "measures",
        headerName: "Active Packages",
        minWidth: 250,
        editable: false,
        hide: false,
        flex: 1,
        renderCell: (params) => <ChipEditCell {...params} />
    },
    {
        field: "measuresWithPresets",
        headerName: "Active Packages (detailed)",
        minWidth: 250,
        editable: false,
        hide: true,
        flex: 1,
        renderCell: (params) => <ChipEditCell {...params} />
    }
]


export default () => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const templates = useSelector(selectTemplates)
    const loading = useSelector(isLoading)
    const hasUpgradesPending = useSelector(hasUpgrades)
    const { export: exportTabDisabled } = useSelector(selectDisabledTabs)
    const { goToTab } = useTabNavigation()

    const [triggerUpgradeAndExport] = upgradeAndExport({ fixedCacheKey: mutationFixedCacheKeys.upgradeAndExport} )

    const upgrade = useCallback(() => {
        triggerUpgradeAndExport({
            mutations: templates
        })
    }, [triggerUpgradeAndExport, templates])

    // Table config for the currently loaded templates
    const rows = useMemo(() => convertTemplatesToTable(templates), [templates])

    // Controls the measure application dialog
    const [open, setOpen] = useState(false)


    const handleOpen = useCallback((name) => {
        dispatch(setTemplateSelection(name))
        setOpen(true)
    }, [setOpen, templates, dispatch])

    const handleClickCell = useCallback(({id, field}) => {
        if (["measures", "measuresWithPresets"].includes(field)) handleOpen(id)

    }, [handleOpen])


    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={10}>
                <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columnsDef}
                    pageSize={5}
                    rowsPerPageOptions={ [5] }
                    onCellClick={handleClickCell}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </Grid>
            <Grid item xs={10}>
                <Button 
                    className={classes.fullWidthButton} 
                    variant="contained" 
                    color="primary" 
                    onClick={upgrade}
                    disabled={!hasUpgradesPending || loading }
                >
                    Export
                </Button>
                
            </Grid>
            <AssignUpgradesDialog open={open} setOpen={setOpen} />
        </Grid>
    )
}