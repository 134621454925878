import { ubemIoApi } from "../../services/ubem"
import { mutationFixedCacheKeys } from "./endpoints"

export const selectTemplates = state => state.upgrades.templates
export const hasUpgrades = state => Object.values(selectTemplates(state) ?? {})?.map(obj => Object.keys(obj).length).reduce((a,b) => a + b, 0) > 0

export const selectTemplateSelection = state => state.upgrades.templateSelection
export const selectTemplateContext = state => state.upgrades.templates?.[state.upgrades.templateSelection] ?? []
export const selectMeasureContext = state => state.upgrades.measureContext


export const selectDisabledTabs = state => ({
    upload: false,
    assign: !selectTemplates(state),
    export: !hasUpgrades(state),
})

export const selectActiveFilters = state => state.upgrades.activeFilters

export const isLoading = state => {
    const {isLoading: isLoadingMeasures} = ubemIoApi.endpoints.getMeasuresLib.select('')(state)
    const {isLoading: isUploadingFile } = ubemIoApi.endpoints.uploadBaseUpgradingFile.select(mutationFixedCacheKeys.uploadBaseUpgradingFile)(state)
    const {isLoading: isExporting } = ubemIoApi.endpoints.upgradeAndExport.select(mutationFixedCacheKeys.upgradeAndExport)(state)
    return state.upgrades.isProcessing || isLoadingMeasures || isUploadingFile || isExporting
}