import React, {Component} from 'react';
import { Navbar, Nav } from "react-bootstrap";
import {Link} from 'react-router-dom';
import './CustomNavbar.css'
import WorkflowSelectorDropdown from './WorkflowSelectorDropdown';

export default ({left, right}) => {
    // TODO: add tutorials for UBEM.io when ready

    return (
        <Navbar className="custom-navbar" bg="light" variant="light">
            <Navbar.Brand>
                <Nav.Link href="https://www.ubem.io">UBEM.io</Nav.Link>
            </Navbar.Brand>
            <Nav className="mr-auto">
                {left}
            </Nav>
            <Nav className="ml-auto">
                {right}
            </Nav>
            <WorkflowSelectorDropdown />
        </Navbar>
    );
}