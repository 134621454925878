import { ubemIoApi } from "../../services/ubem"
import { 
    Breakdown, 
    BreakdownConfig, 
    EndUses, 
    EndUseParameter, 
    EndUseConfig,
    EndUseParameterConfig,
    MetricConfig,
    MaxVizFileUploads, 
    TimeSeriesMode,
    FuelParameters,
    AdoptionConfigMode,
    computeAdjustedData,
    ScenarioSettingsPage
} from "./consts"


// Selectors

//// Selectors for UI form names
const nameSelector = (state, index) => state.analysis.scenarioNames[index]
export const getNameSelectorByIndex = index => state => nameSelector(state, index)
export const vizScenarioNames = state => state.analysis.scenarioNames
export const vizScenarioNamesHaveDuplicates = state => state.analysis.scenarioNames.length !== [... new Set(state.analysis.scenarioNames)].length

//// Selectors for names
const filenameSelector = (state, index) => state.analysis.filenames[index]
export const uploadedFileCount = (state) => Array(MaxVizFileUploads).fill().map( (_, index) => filenameSelector(state, index)).filter( name => name !== "").length
export const getFilenameSelectorByIndex = index => state => filenameSelector(state, index)
export const vizFilenames = state => state.analysis.filenames

//// Selectors for file upload status
export const makeSelectDropzoneStatus = (index) => ubemIoApi.endpoints.uploadVizFile.select(index)
export const vizFileUploadStatusSelectors = Array(MaxVizFileUploads).fill().map( (_,index) => makeSelectDropzoneStatus(index))
export const vizFilesAreLoading = state => vizFileUploadStatusSelectors.map(selector => selector(state)).some( ({ isLoading }) => isLoading)
export const vizFilesAreError = state => vizFileUploadStatusSelectors.map(selector => selector(state)).some( ({ isError }) => isError)
export const vizFilesAreSuccess = state => vizFileUploadStatusSelectors.map(selector => selector(state)).some( ({ isSuccess }) => isSuccess)
export const vizFilesCanProcess = state => !vizFilesAreLoading(state) && !vizFilesAreError(state) && uploadedFileCount(state) > 0 && !vizScenarioNamesHaveDuplicates(state)
export const highestVizFileSuccess = state => vizFilesAreSuccess(state) ? vizFileUploadStatusSelectors.map(selector => selector(state)).slice().reverse().map(({isSuccess}) => isSuccess).indexOf(true)*(-1)+MaxVizFileUploads : 0

////
export const vizFileProcessingCacheKey = "processedVizFiles"

// Report text computer
export const selectHasNoWarnings = state => {
    if (!state.analysis.reports) return null
    // Test to see if intersection between buildings
    const overlaps = Object.values(state.analysis.reports.IntersectionReport).map(scenario => scenario.length).reduce( (a,b) => a+b, 0)
    if (overlaps) return false
    // Test to see if series or buildings have missing data
    // TODO: This should be agnostic as to the keys
    const missingBuildingsCount = Object.values(state.analysis.reports.MissingData).map(scenario => scenario.buildings.length).reduce((a,b) => a+b, 0)
    const missingSeriesCount = Object.values(state.analysis.reports.MissingData).map(scenario => scenario.series.length).reduce((a,b) => a+b, 0)
    const missingFloorAreas = Object.values(state.analysis.reports.MissingData).map(scenario => scenario.floor_areas.length).reduce((a,b) => a+b, 0)
    const missingTotal = missingBuildingsCount + missingSeriesCount + missingFloorAreas
    if (missingTotal) return false
    return true
}

// Settings Page Navigation
export const selectSettingsPageTab = state => state.analysis.settingsPage.tab
export const selectSettingsPageScenarioGroup = state => state.analysis.settingsPage.scenarioSettingsGroup
export const makeSelectTracksBaseline = ({scenarioName, settingsGroup})=> state => state.analysis.settings[scenarioName].tracksBaseline[settingsGroup]
export const makeSelectScenarioSettingsConfirmed = ({scenarioName, settingsGroup})=> state => state.analysis.settingsConfirmed[scenarioName][settingsGroup]
export const selectTargetsConfirmed = state => state.analysis.targetsConfirmed
export const selectTimeSeriesConfirmed = state => state.analysis.timeSeriesConfirmed
export const selectAllSettingsConfirmed = state => {
    const allScenarioSettingsConfirmed = Object.values(state.analysis.settingsConfirmed).map(scenarioSettingsConfirmed => Object.values(scenarioSettingsConfirmed)).flat().every(confirmed => confirmed)
    const targetsConfirmed = selectTargetsConfirmed(state)
    const timeSeriesConfirmed = selectTimeSeriesConfirmed(state)
    return targetsConfirmed && allScenarioSettingsConfirmed && timeSeriesConfirmed
}

// Scenario Settings selectors
// TODO: Memoize this
export const makeSelectFuelSettings = (scenario, parameterKey, fuelSource) => state => state.analysis.settings[scenario]?.fuels[parameterKey]?.[fuelSource]
export const makeSelectEndUseSettings = (scenario, endUse, setting) => state => state.analysis.settings[scenario]?.endUses[endUse]?.[setting]
export const makeSelectSolarSettings = scenario => state => state.analysis.settings[scenario].solar
export const selectSolarEnabled = state => {
    const scenarioNames = selectSortedScenarioNames(state)
    const result = {}
    scenarioNames.forEach(scenario => {
        const settings = makeSelectSolarSettings(scenario)(state)
        result[scenario] = settings.Enabled
    })
    return result
}

// Data delivery computation
export const selectScenarioNames = state => Object.keys(state.analysis.data)
export const selectSortedScenarioNames = state => Object.entries(state.analysis.data).sort(([scenario_a, {sortOrder: a}],[scenario_b, {sortOrder: b}]) => a-b).map(([scenario, _]) => scenario)
export const selectNormalized = state => state.analysis.normalized
export const selectBreakdown = state => state.analysis.selectedBreakdown
export const selectMetric = state => state.analysis.selectedMetric
export const selectScenario = state => state.analysis.selectedScenario
export const selectGraphMode = state => state.analysis.graphMode

export const makeSelectDataSource = scenario => state => {
    const breakdown = selectBreakdown(state)
    const breakdownSourceKey = BreakdownConfig[breakdown].initialData
    const normalized = selectNormalized(state)
    const normalizedSourceKey =  normalized ? "normalized" : "original"
    // Determine whether to use the data formatted by archetype or use
    const data = state.analysis.data[scenario][breakdownSourceKey][normalizedSourceKey]
    return data
}

export const selectArchetypes = state => {
    const scenarios = Object.values(state.analysis.data).map( ( {archetype}) => archetype) 
    const allArchetypes = [ ...new Set(scenarios.flat())]
    return allArchetypes
}

const makeSelectSettingsScenarioSource = ({inputScenario, settingsGroup})=> state => {
    const tracksBaseline = makeSelectTracksBaseline({scenarioName: inputScenario, settingsGroup})(state)
    const baselineScenario = selectSortedScenarioNames(state)[0]
    return tracksBaseline ? baselineScenario : inputScenario
}

// Get the Fuel Assignments for a given state
export const makeSelectFuelAssignments = inputScenario => state => {
    const settingsScenario = makeSelectSettingsScenarioSource({inputScenario, settingsGroup: ScenarioSettingsPage.EndUses})(state)
    // TODO: Load fuel assignment data from api
    const fuelAssignments = {}
    Object.entries(EndUseConfig).forEach( ( [endUse, {fuelSource}]) => {
        const selector = fuelSource ? () => fuelSource : makeSelectEndUseSettings(settingsScenario, endUse, EndUseParameter.FuelSource)
        const fuelAssignment = selector(state)
        fuelAssignments[endUse] = fuelAssignment
    })
    return fuelAssignments
}

// TODO: this call is inconsistent with FuelSource selector
export const makeSelectCoPs = inputScenario => state => {
    const settingsScenario = makeSelectSettingsScenarioSource({inputScenario, settingsGroup: ScenarioSettingsPage.EndUses})(state)
    // TODO: Make CoP adjustable for hvac by returning template cop data from api and updating transformers to take inverter param
    const cops = {}
    EndUses.forEach(endUse => {
        if (EndUseParameterConfig.CoP.usedBy.includes(endUse)) {
            const selector = makeSelectEndUseSettings(settingsScenario, endUse, EndUseParameter.CoP)
            const cop = selector(state)
            cops[endUse] = cop
        } else {
            cops[endUse] = 1
        }
    })
    return cops
} 

export const makeSelectDataTransformer = inputScenario => state => {
    const breakdown = selectBreakdown(state)
    const transformer = BreakdownConfig[breakdown].transformer
    return transformer
}


// Get the End Use Multipliers for Each Scenario (e.g. Oil Cost or Gas Carbon)
export const makeSelectStartingMultipliers = inputScenario => state => {
    const fuelAssignmentsScenario = makeSelectSettingsScenarioSource({inputScenario, settingsGroup: ScenarioSettingsPage.EndUses})(state)
    const fuelSettingsScenario = makeSelectSettingsScenarioSource({inputScenario, settingsGroup: ScenarioSettingsPage.Fuels})(state)
    // Which metric is in use
    const metric = selectMetric(state)
    // Which Cost/Carbon/null parameter to use
    const fuelParameterKey = MetricConfig[metric].adjuster
    // Fuel Assignments for Each End Use
    const selectFuelAssignments = makeSelectFuelAssignments(fuelAssignmentsScenario)
    const fuelAssignments = selectFuelAssignments(state)

    // Table to store the scalars for each end use
    const multipliers =  {}   
    // Look up the fuel type's multiplier settings for each end use
    if (fuelParameterKey) {
        EndUses.forEach( endUse => {
            const fuelAssignment = fuelAssignments[endUse]
            // Get the multiplier parameter value for that fuel in this scenario
            const selectMultiplier = makeSelectFuelSettings(fuelSettingsScenario, fuelParameterKey, fuelAssignment)
            const multiplier = selectMultiplier(state)
            // Stor the multiplier base value.
            multipliers[endUse] = multiplier
        })
    } else {
        // Default to identity scalar if the metric does not use them (Energy)
        EndUses.forEach( endUse => {
            multipliers[endUse] = 1
        })
    }
    return multipliers
}

export const selectBarChartData = state => {
    const scenarios = selectSortedScenarioNames(state)
    const keys = []
    const baseline = {
        scenario: null,
        total: 0,
    }
    const enabledPVStatus = selectSolarEnabled(state)
    const results = scenarios.map(inputScenario => {
        const data = makeSelectDataSource(inputScenario)(state)
        const breakdown = selectBreakdown(state)
        const multipliers = makeSelectStartingMultipliers(inputScenario)(state)
        const copScalars = makeSelectCoPs(inputScenario)(state)
        const fuelAssignments = makeSelectFuelAssignments(inputScenario)(state)
        const solarSettings = makeSelectSolarSettings(inputScenario)(state)
        const transformer = makeSelectDataTransformer(inputScenario)(state)
        const result = {
            ...transformer({data, multipliers, copScalars, fuelAssignments}),
        }
        Object.keys(result).forEach(key => keys.push(key))
        const total = Object.values(result).reduce((a,b) => a+b, 0)
        // TODO: optional, sort data highest to lowest
        if (total > baseline.total) {
            baseline.scenario = inputScenario
            baseline.total = total
        }
        result.scenario = inputScenario
        result.total = total
        const pvScenario = makeSelectSettingsScenarioSource({inputScenario, settingsGroup: ScenarioSettingsPage.PV})(state)
        if (enabledPVStatus[pvScenario]) {
            if (breakdown === Breakdown.Archetype) {
                Object.entries(data).forEach(([archetype, {PV}]) => {
                    result[`${archetype}-PV`] = Math.round(PV*(solarSettings.Efficiency/15)*multipliers.Lighting*solarSettings.Coverage/100)
                    result[`${archetype}-PVTotal`] = result.total + result[`${archetype}-PVTotal`]
                })
            } else {
                result.PV =  data.PV*(solarSettings.Efficiency/15)*multipliers.Lighting*solarSettings.Coverage/100
                result.PVTotal = total+result.PV
            }
        }
        return result
    })
    results.forEach(result => result.drop = Math.round(100*(result.total-baseline.total)/baseline.total))
    const graphKeys = [...new Set(keys)]
    return {results, graphKeys , keys, baseline}
}

export const selectSankeyData = state => {
    const scenarioNames = selectSortedScenarioNames(state)
    const selectedScenario = selectScenario(state)
    const inputScenario = selectedScenario ? selectedScenario : scenarioNames[0]
    const data = state.analysis.data[inputScenario ?? scenarioNames[0]].Archetype.original // No normalized data
    const multipliers = makeSelectStartingMultipliers(inputScenario ?? scenarioNames[0])(state)
    const copScalars = makeSelectCoPs(inputScenario ?? scenarioNames[0])(state)
    const nodeIndices = {}
    const nodes = (
        Object.keys(data).map( (name, i)=> ({
            name,
            color: BreakdownConfig.Archetype.colorPicker(name, i),
        }))
        .concat(Object.values(EndUseConfig).map( ({label, color}) => ({
            name: label,
            color,
            opacity: 0,
        })))
        .flat()
    )
    nodes.forEach(( {name}, ix) => nodeIndices[name] = ix)
    const links = (
        Object.entries(data).map( ([archetype, archData], i) => {
            const adjustedData = computeAdjustedData({data: archData, multipliers, copScalars})
            return Object.entries(adjustedData).map(([ endUse, value ]) => ({
                target: nodeIndices[archetype],
                source: nodeIndices[EndUseConfig[endUse].label],
                value,
                mylinkname: `${archetype}-${endUse}-link`
            }))
        })
        .flat()
    )
    // const gradients = []
    // Object.entries(data).map( ([archetype, data], i) => (
    //     Object.entries(data).map(([ endUse, value ]) => ({
    //         source: nodeIndices[archetype],
    //         target: nodeIndices[EndUseConfig[endUse].label],
    //         value,
    //         mylinkname: `${archetype}-${endUse}-link`
    //     })))

    // )
    return {nodes, links}

}

export const makeSelectDecaySeries = ({years, fuelParameter, fuel, initial}) => state => {
    const timeSeriesConfig = state.analysis.timeSeriesSettings[fuelParameter][fuel]
    const {mode, rate, list} = timeSeriesConfig
    switch (mode) {
        case TimeSeriesMode.Exponential:
            const scalars = [initial ?? 1]
            Array(years).fill().forEach((_, i) => {
                const current = scalars[i]
                const next = current - (rate * current)
                scalars.push(next)
            })
            return scalars
            break
        case TimeSeriesMode.CSV:
            // TODO: implmenet csv uploads
            return list.length > 0 ? list : [1]
    }
}

export const makeSelectMultiplierSeries = inputScenario => state => {
    const metric = selectMetric(state)
    const longYear = state.analysis.targets.long.year
    const currentYear = new Date().getFullYear()
    const years = longYear - currentYear + 4
    const initialMultipliers = makeSelectStartingMultipliers(inputScenario)(state)
    const fuelAssignments = makeSelectFuelAssignments(inputScenario)(state)
    const decaySeries = {} 
    // Get a series for each end use
    Object.entries(fuelAssignments).forEach(([endUse, fuel]) => {
        
        const baseSeries = MetricConfig[metric].timeVaries ?
            makeSelectDecaySeries({years, fuelParameter: metric, fuel, initial: initialMultipliers[endUse]})(state)
        : 
            [1]
        const last = baseSeries.slice(-1)[0]
        // Quickly fill up extra years
        if (baseSeries.length < years+1) {
            const finalSeries = [ ...baseSeries]
            Array(years+1- baseSeries.length).fill().forEach(_ => finalSeries.push(last))
            decaySeries[endUse] = [ ... finalSeries]
        } else {
            decaySeries[endUse] = baseSeries
        }
    })
    // Transpose the decay series object
    const multiplierSeries = Array(years+1).fill().map((_, i) => {
        const multipliers = {}
        EndUses.forEach(endUse => {
            multipliers[endUse] = decaySeries[endUse][i]
        })
        return multipliers
    })
    return multiplierSeries
}
export const selectMultiplierSeries = state => {
    const scenarioNames = selectSortedScenarioNames(state)
    const series = {}
    scenarioNames.forEach(inputScenario => {
        series[inputScenario] = makeSelectMultiplierSeries(inputScenario)(state)
    })
    return series
}

export const selectCopScalars = state => {
    const scenarioNames = selectSortedScenarioNames(state)
    const cops = {}
    scenarioNames.forEach(inputScenario => {
        const copScalars = makeSelectCoPs(inputScenario)(state)
        cops[inputScenario] = copScalars
    })
    return cops
}

export const selectTimeSeriesData = state => {
    const selectedScenario = selectScenario(state)
    const scenarioNames = selectScenarioNames(state)
    const scenarios = (selectedScenario !== null) ? [selectedScenario] : [...scenarioNames]
    const currentYear = new Date().getFullYear()
    const longYear = state.analysis.targets.long.year
    const years = longYear - currentYear + 4
    const totaledDataByYear = Array(years+1).fill().map(( _, i ) => ({Year: currentYear+i}))
    const baseline = {
        scenario: null,
        total: 0,
    }
    const dataByScenario = {}
    scenarios.forEach(inputScenario => {
        // Get the time series
        const multiplierSeries = makeSelectMultiplierSeries(inputScenario)(state)
        // Get the starting data 
        const data = makeSelectDataSource(inputScenario)(state)
        const copScalars = makeSelectCoPs(inputScenario)(state)
        const fuelAssignments = makeSelectFuelAssignments(inputScenario)(state)
        // Get the transformer, which can be customized in consts
        const transformer = makeSelectDataTransformer(inputScenario)(state)
        const results = []
        multiplierSeries.forEach((multipliers,i) => {
            const result = {
                ...transformer({data, multipliers, copScalars, fuelAssignments})
            } 
            result.Year = currentYear + i
            results.push(result)
            // Sum the entry up and store the total for a line chart comparison across scenarios
            const total = Object.values(result).reduce((a,b) => a+b, 0) 
            if (i==0 && total > baseline.total) {
                baseline.scenario = inputScenario
                baseline.total = total
            }
            totaledDataByYear[i][inputScenario] = total
        })
        dataByScenario[inputScenario] = results
    })
    // If Only one scenario is selected, exit and return a standard view for stacked charts/lines
    if (scenarios.length === 1) {
        const results = dataByScenario[selectedScenario]
        const keys = Object.keys(results[0]).filter( key => key!== "Year")
        return {results, keys, baseline}
    } else {
        return {results: totaledDataByYear,  keys: scenarioNames, baseline}
    }
}

// Target Selectors
export const selectTargetMetric = state => state.analysis.targets.metric
export const selectTargetNear = state => state.analysis.targets.near
export const selectTargetLong = state => state.analysis.targets.long

// Adoption selectors
export const selectBaselineAssignment = state => state.analysis.adoption.assignments.baseline
export const makeSelectEcmAssignment = index => state => state.analysis.adoption.assignments.ecms[index]
export const selectDuplicateAdoptionAssignments = state => {
    const baseline = state.analysis.adoption.assignments.baseline
    const ecms = state.analysis.adoption.assignments.ecms
    const list = [baseline, ...ecms]
    return list.length !== [... new Set(list)].length ? "Assignments must be unique" : undefined
}
export const makeSelectFixedBaselineOwnerOccupancy = () => state => state.analysis.adoption.occupation.value
export const makeSelectFixedECMProbability = index => state => state.analysis.adoption.probabilities.value[index]

export const canComputeAdoption = state => {
    if (selectDuplicateAdoptionAssignments(state)) return false
    const doesNotHaveOccupancy  = [undefined, null].includes(state.analysis.adoption.occupation.value)
    if (doesNotHaveOccupancy) return false
    const scenarioNames = selectSortedScenarioNames(state)
    const doesNotHaveAdoptionP = state.analysis.adoption.probabilities.value.some( (value, i) => [undefined, null].includes(value) && i+1 < scenarioNames.length )
    if (doesNotHaveAdoptionP) return false
    const doesNotHaveName = [null, undefined].includes(state.analysis.adoption.nameColumn)
    const mode = state.analysis.adoption.mode
    if (mode === AdoptionConfigMode.GIS && doesNotHaveName) return false
    return true
}


export const selectAdoptionCacheIsValid = state => {
    return {cacheIsValid: state.analysis.adoption.cacheKey, metric: state.analysis.adoption.metric}
}
// Adoption gis file selectors


// Viz Shapefile export
export const vizShapesAreExporting = state => ubemIoApi.endpoints.exportVizData.select()(state).isLoading


// Computed loading state
export const isLoading = (state) => vizFilesAreLoading(state) || state.analysis.isProcessing || vizShapesAreExporting(state)