import { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Slider from "@material-ui/core/Slider"
import Typography from "@material-ui/core/Typography";

export const Measure = ({ measureId, config, updateQueue, disabled }) => {

    const getDefaultValues = useCallback(()=> {
        const defaultVals = {}
        Object.entries(config.props).map(([arg,{defaultValue}])=>defaultVals[arg] = defaultValue);
        return defaultVals
    }, [config])

    const presets = useMemo(() => [{label: "Default", props: getDefaultValues()}].concat(config.presets), [config])

    const [pendingArgs, setPendingArgs] = useState(getDefaultValues());
    const [selectedPreset, setSelectedPreset] = useState(0)

    const handleChange = useCallback((key, newValue) => {
        setSelectedPreset(-1)
        setPendingArgs(prev => (
            {
                ...prev,
                [key]: newValue
            }
        ));
    }, [setPendingArgs, setSelectedPreset]);
    useEffect(() => {
        updateQueue(measureId, pendingArgs)

    }, [pendingArgs, measureId, updateQueue])

    useEffect(() => {
        if (selectedPreset != -1) {
            const preset = presets[selectedPreset].props
            setPendingArgs({...preset})
        }
    }, [selectedPreset, presets, setPendingArgs])

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography>
                    {config.label}
                </Typography>
                {Object.entries(config.props).map(([arg, props]) => (
                    props.uiType === "slider" ?
                        <Box key={arg}>
                            <Typography variant="body2">
                                {props.label}: {props.format ? props.format(pendingArgs[arg]) : pendingArgs[arg]}
                            </Typography>
                            <Slider onChange={(e, newValue) => handleChange(arg, newValue)} value={pendingArgs[arg]} disabled={disabled} {...props} />
                        </Box>
                        : <></>

                ))}

                    <FormControl fullWidth>
                        <InputLabel id={`${measureId}-preset-select-label`}>Presets</InputLabel>
                        <Select 
                            labelid={`${measureId}-preset-select-label`}
                            id={`${measureId}-preset-select`}
                            onChange={(e)=>setSelectedPreset(e.target.value)} //TODO: This should be a memoized callback
                            disabled={disabled}
                            value={selectedPreset}
                        >
                            <MenuItem value={-1}>{`N/A`}</MenuItem>
                            {presets.map(( preset,i ) => 
                                <MenuItem key={`${measureId}-preset-${i}`} value={i}>{preset.label}</MenuItem>
                            )}

                        </Select>
                    </FormControl>
            </CardContent>
        </Card>
    );
};
