// Hooks
import {Switch, Route, useHistory, useParams, useRouteMatch, Redirect} from "react-router-dom"
import { useSelector } from "react-redux"

// MUI
import PublishIcon from "@material-ui/icons/Publish"
import SettingsIcon from "@material-ui/icons/Settings"
import BarChartIcon from "@material-ui/icons/BarChart"
import HomeWorkIcon from "@material-ui/icons/HomeWork"

///// Umiverse Imports

// Redux
import { isLoading, selectAllSettingsConfirmed } from "../../redux/slices/analysis/selectors";

// Components
import { Layout } from "../Layout";
import UploadPage from "./pages/UploadPage";
import SettingsPage from "./pages/SettingsPage";
import GraphsPage from "./pages/GraphsPage";
import AdoptionPage from "./pages/AdoptionPage";

export default ({children, tabValue}) => {
    const loading = useSelector(isLoading)
    const error = useSelector(state => state.analysis.error)
    const hasData = useSelector(state => state.analysis.data !== null)
    const allSettingsConfirmed = useSelector(selectAllSettingsConfirmed)
    const {path} = useRouteMatch()

    const tabsConfig = {
        tabs: {
            upload: {
                title: "Upload",
                description: "Upload files for visualization and analysis",
                label: "Upload",
                disabled: false,
                icon: <PublishIcon />,
                component: <UploadPage />,
            },
            settings: {
                title: "Settings",
                description: "Configure settings for visualization and analysis",
                label: "Settings",
                disabled: !hasData,
                icon: <SettingsIcon />,
                component: <SettingsPage />,
            },
            "technology-pathways": {
                title: "Technology Pathways",
                description: "Compare Data Across Scenarios",
                label: "Technology Pathways",
                disabled: !allSettingsConfirmed || !hasData,
                icon: <BarChartIcon />,
                component: <GraphsPage />,

            },
            adoption: {
                title: "Adoption",
                description: "Simulate technology adoption pathways",
                label: "Adoption",
                disabled: !allSettingsConfirmed || !hasData,
                icon: <HomeWorkIcon />,
                component: <AdoptionPage />
            }
        },
        defaultTab: "upload",
        root: {
            title: "Analysis",
            description: "Analyze UBEM Results",
            component: <div>Help info coming</div>,
            redirect: true
        },
        otherRoutes: {
            someOtherRoute: {
                component: <div>hello there</div>
            }
        }
        // root: "analysis",
    }
	return (
        <Layout 
            tabsConfig={tabsConfig}
            loading={loading}
        >
            {children}
        </Layout>

	)
}
