// Hooks
import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CancelIcon from '@material-ui/icons/Cancel'
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { DataGrid, GridToolbarDensitySelector, GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { selectActiveFilters, selectTemplateSelection } from "../../../redux/slices/upgrades/selectors"

// Components
import MeasureEditor from "./MeasureEditor"
import FilterPopover from "./FilterPopover"
import AppliedMeasures from "./AppliedMeasures"
import { setMeasureContext } from "../../../redux/slices/upgrades/reducers"

const {useQuery: useMeasuresLib} = ubemIoApi.endpoints.getMeasuresLib

export const useStyles = makeStyles((theme) => ({
    dataGrid: {
        height: "35vh",
    }
}))

const columns = [
  {
    field: 'Measure.Name',
    headerName: 'Measure',
    minWidth: 250,
    flex: 1,
    editable: false,
  },
  {
    field: 'Name',
    headerName: 'Package',
    minWidth: 250,
    flex: 1,
    editable: false,
  },
  {
    field: 'Measure.Category',
    headerName: 'Category',
    minWidth: 80,
    flex: 1,
    editable: false,
  },
  {
    field: 'Measure.BuildingSystems',
    headerName: "Building Systems",
    minWidth: 80,
    flex: 1,
    editable: false,
    hide: true,
  },
  {
    field: "Effort Level",
    headerName: 'Effort Level',
    minWidth: 80,
    flex: 1,
    editable: false,
  },
  {
    field: "Measure.DescriptionShort",
    headerName: 'Info',
    minWidth: 250,
    flex: 1,
    editable: false,
    hide: true,
  }
]

const CustomToolbar = () => {

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <FilterPopover />
        </GridToolbarContainer>
    );
}

export default ({open, setOpen }) => {
    const dispatch = useDispatch()
    const templateName = useSelector(selectTemplateSelection)
    const { data: measuresLib, isLoading } = useMeasuresLib('')

    const activeFilters = useSelector(selectActiveFilters)

    const classes = useStyles()

    const filteredMeasuresLib = useMemo(() => {
        if (!measuresLib) return []
        if (Object.keys(activeFilters).length === 0) return measuresLib
        return measuresLib.filter(measure => {
            const filterPassage = Object.entries(activeFilters).map(([prop, value]) => measure[prop].includes(value))
            return filterPassage.some(bool => bool)
        })
    }, [measuresLib, activeFilters])

    const handleClose = useCallback(() => {
        dispatch(setMeasureContext(null))
        setOpen(false)
    }, [setOpen, dispatch])

    const handleClickRow = useCallback(({row}) => {
        dispatch(setMeasureContext(row))
    }, [dispatch])


    return (

        <Dialog fullScreen open={open} onClose={handleClose} >
            <Container>
                {/* Title */}
                <DialogTitle id="template-assignment-dialog">
                    Upgrade {templateName}
                </DialogTitle>

                {/* Contents */}
                <DialogContent>

                    {/* List of applied measures as chips */}
                    <AppliedMeasures />

                    {/* Prompt Text */}
                    <DialogContentText>
                        Select an upgrade package to apply to {templateName}.
                    </DialogContentText>

                    {/* Measures Table */}
                    {/* {isLoading && <LinearProgress />} */}
                    <Grid container >
                        <Grid item xs>
                            <DataGrid
                                className={classes.dataGrid}
                                disableColumnFilter
                                // autoHeight
                                rows={filteredMeasuresLib}
                                columns={columns}
                                pageSize={25}
                                rowsPerPageOptions={ [25] }
                                sortModel={[{ field: "Measure.Name", sort: "asc" }]}
                                onRowClick={handleClickRow}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                loading={isLoading}
                                density="compact"
                            />
                        </Grid>
                    </Grid>

                    {/* Measures Editor */}
                    <MeasureEditor />

                </DialogContent>

                {/* Additional Actions */}
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="primary" endIcon={<CancelIcon/>}>
                        Close
                    </Button>
                </DialogActions>

            </Container>
        </Dialog>
    )
}