// Hooks
import { useSelector } from "react-redux"

// MUI
import Tooltip from "@material-ui/core/Tooltip"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

///// Umiverse Imports

// Redux
import { selectBreakdown, selectMetric, selectNormalized, selectSortedScenarioNames, selectTargetMetric } from "../../../redux/slices/analysis/selectors"
import { Breakdown } from "../../../redux/slices/analysis/consts"

export default (props) => {
    const normalized = useSelector(selectNormalized)
    const breakdown = useSelector(selectBreakdown)
    const usesDropLines = useSelector(selectSortedScenarioNames).length > 1
    const metric = useSelector(selectMetric)
    const targetMetric = useSelector(selectTargetMetric)
    return (
        <>
            <FormControl component="fieldset" >
                <FormLabel component="legend">Overlays</FormLabel>
                <FormGroup row>
                    <Tooltip title={normalized ? "Disabled when normalized." : "Show PV potential"}>
                    <FormControlLabel 
                        control={
                                <Checkbox 
                                    size="small"
                                    onChange={props.toggleSolar} 
                                    checked={props.showSolar && !normalized} 
                                    disabled={normalized} 
                                    name="show-solar-pv" 
                                    color="primary" 
                                />
                        } 
                        label="PV"
                    />
                    </Tooltip>
                    {usesDropLines && (
                        <Tooltip title={breakdown === "Archetype" ? "Drop lines disabled in archetype graphing modes." : "Show relative change percentages."}>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        size="small"
                                        onChange={props.toggleDropLines} 
                                        checked={props.showDropLines} 
                                        disabled={breakdown === Breakdown.Archetype} 
                                        name="show-droplines" 
                                        color="primary" 
                                    />
                                } 
                                label="Drops"
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={ targetMetric === metric ? "Show target goals" : `Graphed metric (${metric}) does not match target metric (${targetMetric})`} >
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    size="small"
                                    onChange={props.toggleTargets} 
                                    checked={props.showTargets} 
                                    disabled={targetMetric !== metric} 
                                    name="show-targets" 
                                    color="primary" 
                                />
                            } 
                            label="Targets"
                        />
                    </Tooltip>
                    </FormGroup>
                {/* <FormHelperText>Show overlays</FormHelperText> */}
            </FormControl>
        
        </>
    )
}
