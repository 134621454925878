// Hooks
import { useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

// MUI
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

///// Umiverse Imports

// Redux
import { ubemIoApi} from "../../../redux/services/ubem"
import { clearReports } from "../../../redux/slices/analysis/reducers"
import { MaxVizFileUploads } from "../../../redux/slices/analysis/consts"
import { 
    vizFilesCanProcess, 
    highestVizFileSuccess, 
    getFilenameSelectorByIndex, 
    uploadedFileCount,
    vizFilenames,
    vizScenarioNames,
    selectHasNoWarnings,
    isLoading,
} from "../../../redux/slices/analysis/selectors"
import { useTabNavigation } from "../../../redux/slices/navigation/hooks"
import { AsyncTask } from "../../../redux/slices/async-processing/consts"
import { startAsyncTask } from "../../../redux/slices/async-processing/reducers"

// Components
import { RtkDropzone } from "../../RtkDropzone"
import ScenarioNameInput from "../components/ScenarioNameInput"
import WarningReports from "../components/WarningReports"

const { useMutation: useVizFileMutation } = ubemIoApi.endpoints.uploadVizFile

export default () => {
    const dispatch = useDispatch()
    const loading = useSelector(isLoading)
    const canProcess = useSelector(vizFilesCanProcess)
    const filenames = useSelector(vizFilenames)
    const scenarioNames = useSelector(vizScenarioNames)
    const dropzoneCount = useSelector(state => Math.min(Math.max(highestVizFileSuccess(state) + 1, uploadedFileCount(state)+1), MaxVizFileUploads))
    const hasNoWarnings = useSelector(selectHasNoWarnings)
    const reports = useSelector(state => state.analysis.reports)
    const {goToTab} = useTabNavigation()

    const { taskLocation: url } = useSelector(ubemIoApi.endpoints.startAsyncTask.select(AsyncTask.processVizFiles))?.data ?? {}
    const result = useSelector(ubemIoApi.endpoints.checkAsyncStatus.select({endpoint: AsyncTask.processVizFiles, url}))
    const {isSuccess: processingIsSuccess} = result


    // TODO: Switch to new useNavigationEffect hook
    useEffect(() => {
        if (processingIsSuccess && hasNoWarnings) {
            goToTab("settings")
            dispatch(clearReports())
        }
    }, [processingIsSuccess, hasNoWarnings, goToTab])


    const processVizFiles = useCallback(() => {

        const body = filenames.map((filename, index) => ({
            filename, 
            scenarioName: scenarioNames[index], index
        })).filter( ({ filename }) => filename != "")

        dispatch(startAsyncTask({endpoint: AsyncTask.processVizFiles, body}))

    }, [filenames, scenarioNames, dispatch])




	return (
        <Container>
            {!hasNoWarnings && 
                <WarningReports />
            }
            {!reports &&
                <Grid container spacing={3} justifyContent="center">
                    {Array(dropzoneCount).fill().map( (_, i) => (
                        <Grid container item spacing={3} justifyContent="center" key={`viz-upload-form-${i}`}>
                            <Grid item xs={12} md={2}>
                                <ScenarioNameInput
                                    index={i}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RtkDropzone 
                                    index={i}
                                    mutation={useVizFileMutation}
                                    filenameSelector={getFilenameSelectorByIndex}
                                    label="UMI file for analysis"
                                />
                            </Grid>
                        </Grid>

                    ))}

                    <Grid item>
                        <Button onClick={processVizFiles} variant="outlined" size="large" disabled={!canProcess || loading} >Process files</Button>
                    </Grid>
                </Grid>
            }
            
        </Container>
    )
}
