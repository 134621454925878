// Hooks
import{ useMemo } from 'react';
import { useSelector } from 'react-redux';

// Leaflet
import {
    Map, TileLayer, Polygon, Popup,
    // GeoJSON, FeatureGroup,
    // LayerGroup, LayersControl, CircleMarker, Tooltip
} from 'react-leaflet';

// MUI
import Paper from "@material-ui/core/Paper"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from '@material-ui/core/Typography';


///// Umiverse Imports

// CSS
import "./DisplayGISMap.css"

// Redux
import { selectBounds, selectGeoJSON, selectIsLargeGeoJSON } from '../../../redux/slices/umibuilder/selectors';

const DisplayGISMap = (props) => {
    const geoJSON = useSelector(selectGeoJSON)
    const isLargeGeoJSON = useSelector(selectIsLargeGeoJSON)
    const bound = useSelector(selectBounds)
    const {
        id: selectedIdField, 
        height: selectedHeightField, 
        primary: selectedPrimaryField, 
        secondary: selectedSecondaryField 
    } = useSelector(state => state.umibuilder.keyFields)

    const data = geoJSON?.features ? geoJSON.features : []

    // Define a color mapping LUT for archetypes and cache it
    const primaryColorMap = useMemo( () => {
        const _archColorMap = {}
        // Simplify to list of unique values
        const possiblePrimaryValues = selectedPrimaryField ? [...new Set(data.map((item)=> item.properties[selectedPrimaryField?.value]))] : []
        // Generate a unique color and associate it with each unique value
        possiblePrimaryValues.map((primaryValue,i)=> _archColorMap[primaryValue] = hsv2rgbHex(i*360/possiblePrimaryValues.length,1,1))

        // Cache the value
        return _archColorMap

    }, [data, selectedPrimaryField])

    // Define a color mapping LUT for secondary field and cache it
    const secondaryColorMap = useMemo(() => {
        // Simplify to list of unique values
        const secondaryValues = selectedSecondaryField ? [...new Set(data.map((item)=> item.properties[selectedSecondaryField?.value]))]: []
        
        // If it's not numerical, get a class number
        const secondaryIsNumerical  = typeof(secondaryValues[0])==="number"
        const secondaryLookup =  secondaryIsNumerical ? (val)=>val : (val)=>secondaryValues.indexOf(val)

        const minSecondary = Math.min(...(secondaryValues).map((val)=>secondaryLookup(val)))
        const maxSecondary = Math.max(...(secondaryValues).map((val)=>secondaryLookup(val)))
        const secondaryRange = maxSecondary-minSecondary
        
        // Define a transfer function
        const secondaryColorMapper = (value) => secondaryIsNumerical ? 
            hsv2rgbHex(
                (secondaryLookup(value)-minSecondary)/(secondaryRange===0 ? 1 : secondaryRange)*360,
                1,
                (secondaryLookup(value)-minSecondary)/(secondaryRange===0 ? 1 : secondaryRange)*0.5+0.5)
            : 
            hsv2rgbHex(secondaryLookup(value)/secondaryValues.length*360,1,1)
        
        // Cache the results
        const _secondaryColorMap = {} 
        secondaryValues.map((val)=>_secondaryColorMap[val] = secondaryColorMapper(val))

        return _secondaryColorMap

    }, [data, selectedSecondaryField])

    const heightColorMap = useMemo(() => {
        // Simplify to a list of unique values
        const heights = selectedHeightField ? [...new Set(data.map((item)=> item.properties[selectedHeightField?.value]))]: []

        const minHeight = Math.min(...heights)
        const maxHeight = Math.max(...heights)
        const heightRange = maxHeight-minHeight

        // Define the transfer fn
        const heightColorMapper = (height) => hsv2rgbHex(240,1,(height-minHeight)/heightRange*0.75+0.25)

        // Cache the results values
        const _heightColorMap = {}
        heights.map((height)=> _heightColorMap[height] = heightColorMapper(height))

        return _heightColorMap

    }, [data, selectedHeightField])



    const point1 = bound[0]
    const point2 = bound[1]
    if (isLargeGeoJSON) {
        return ( 
            <Typography>
                Map display and error validation disabled for models with more than 1000 buildings.
            </Typography> 
        )
    }

    return bound.length === 0 ? <div><p>loading...</p></div> : (
        <Tooltip enterDelay={750} title="Click on a footprint to view specific information about that building.">
            <Paper elevation={5}>
                <Map className="display-GIS-map"
                        boundsOption={{padding: [5, 5]}}
                        bounds={[point1, point2]}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {data.map((item, index) => {
                        let color = "blue";
                        let show = true
                        const {properties} = item
                        const {
                            UBEM_ERROR,
                            UBEM_HEIGHT_ERROR,
                            UBEM_PRIMARY_ERROR,
                            UBEM_SECONDARY_ERROR,
                            UBEM_ID_ERROR,
                            UBEM_DUPLICATE_ID_ERROR,
                            UBEM_OVERLAP_ERROR
                        } = properties
                        if (props?.mapColorMode) {
                            switch (props.mapColorMode) {
                                case "anyErrors":
                                    color = UBEM_ERROR ? "red" : "blue"
                                    break;
                                case "overlapError":
                                    color = UBEM_OVERLAP_ERROR ? "red" : "blue"
                                    show = UBEM_OVERLAP_ERROR
                                    break;
                                case "idError":
                                    color = UBEM_ID_ERROR || UBEM_DUPLICATE_ID_ERROR ? "red" : "blue"
                                    show = UBEM_ID_ERROR || UBEM_DUPLICATE_ID_ERROR
                                    break;
                                case "heightError":
                                    color = UBEM_HEIGHT_ERROR ? "red" : "blue"
                                    show = UBEM_HEIGHT_ERROR
                                    break;
                                case "primaryError":
                                    color = UBEM_PRIMARY_ERROR ? "red" : "blue"
                                    show = UBEM_PRIMARY_ERROR
                                    break;
                                case "secondaryError":
                                    color = UBEM_SECONDARY_ERROR ? "red" : "blue"
                                    show = UBEM_SECONDARY_ERROR
                                    break;
                                case "height":
                                    const heightVal = selectedHeightField? properties[selectedHeightField?.value] : null
                                    const heightColorLookup = heightColorMap[heightVal]
                                    color = UBEM_HEIGHT_ERROR ? "black" : (heightColorLookup ? heightColorLookup : "black")
                                    break;
                                case "primary":
                                    const primaryVal = selectedPrimaryField ?  properties[selectedPrimaryField?.value] : null
                                    const primaryColorLookup = primaryColorMap[primaryVal]
                                    color = UBEM_PRIMARY_ERROR ? "black" : (primaryColorLookup ? primaryColorLookup : "black")
                                    break;
                                case "secondary":
                                    const secondaryVal = selectedSecondaryField ?  properties[selectedSecondaryField?.value] : null
                                    const secondaryColorLookup = secondaryColorMap[secondaryVal]
                                    color = UBEM_SECONDARY_ERROR ? "black" : (secondaryColorLookup ? secondaryColorLookup : "black")
                                    break;
                                default:
                                    color = "blue"
                                    break;
                            }
                        }
                        // const archColor = typeof(archColorMap[archValue]) === ? archColorMap[archValue] : "black" : "black"
                        // const heightValue = this.props?.selectedHeightField ? item.properties[this.props?.selectedHeightField?.value] : null
                        // const heightColor = heightValue ? (heightValue >= 3) ? heightColorMap(heightValue) : "red" : "red"
                        // const secondaryValue = this.props?.selectedSecondaryField ? item.properties[this.props?.selectedSecondaryField?.value] : null
                        // const secondaryColor = secondaryValue !== null && secondaryValue !== undefined && secondaryValue !== "" ? secondaryColorMap(secondaryValue) : "black"
                        // const hasId = item.properties[this.props?.selectedIDField?.value]
                        // const idColor = hasId ? "green" : "red"
                        // const colors = {
                        //     archetypes: archColor,
                        //     ids: idColor,
                        //     heights: heightColor,
                        //     secondary: secondaryColor,
                        // }
                        // const color = this.props?.mapColorMode ? colors[this.props?.mapColorMode] : null
                        const showPolygon = props?.mapColorMode ? show : true

                        return (showPolygon &&
                            <Polygon color={color} key={index} positions={[item.geometry.coordinates]}>
                                <Popup className="popup-box">
                                    {Object.entries(item.properties).map((x, i) => {
                                        return <div key={i}><strong>{x[0]}:</strong> {
                                            x[1] == null ? "Null" : ([true,false].includes(x[1]) ? String(x[1]) : x[1])
                                        }</div>
                                    })
                                    }
                                </Popup>
                            </Polygon>
                        )
                    })}
                </Map>
            </Paper>
        </Tooltip>
    )
}

function hsv2rgbHex(h,s,v) 
{                              
  let f= (n,k=(n+h/60)%6) => v - v*s*Math.max( Math.min(k,4-k,1), 0);     
  return rgbToHex( f(5)*255,f(3)*255,f(1)*255 );       
}   
function componentToHex(c) {
  var hex = Math.round(c).toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export default DisplayGISMap