import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

export default () => {
	return (
                <Dropdown >
                    <Dropdown.Toggle variant="secondary" id="dropdown-options" size="sm">
                        Workflows
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right">
                        <Dropdown.Item ><Link to="/uploadGisOnly">GIS to UIO</Link></Dropdown.Item>
                        <Dropdown.Item><Link to="/uploadUIO">UIO to UMI</Link></Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item><Link to="/results">Results Visualizer</Link></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
	)
}