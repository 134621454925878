import { useSelector } from "react-redux"
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from "@material-ui/icons/Menu"
import { Link } from "react-router-dom"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  menu: {
  },
  menuItem: {
    margin: "0.25rem 0.5rem",
  },
  dividedItem: {
    borderBottom: "1px solid black"
  },
  root: {
    marginRight: "1rem"
  }

}));

export default ({workflowTitle}) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);
    const rootPath = useSelector(state => state.navigation.rootPath)
    console.log(rootPath)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={classes.root}>
            <Button variant="outlined" color="inherit" onClick={handleClick} startIcon={<MenuIcon />}>
                {workflowTitle ? workflowTitle : "Workflows"}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                className={classes.menu}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to="/umibuilder/upload?entrypoint=gis" className={classes.menuItem}>GIS Data Preparation (GIS to UIO)</MenuItem>
                <MenuItem component={Link} to="/umibuilder/upload?entrypoint=uio" className={clsx(classes.menuItem, classes.dividedItem)} >Assign Archetypes (UIO to UMI)</MenuItem>
                <MenuItem component={Link} to="/analysis" className={clsx(classes.menuItem, classes.dividedItem)}>Results Analyzer</MenuItem>
                <MenuItem component={Link} to="/umibuilder/reference-library" className={classes.menuItem}>Build Template Library</MenuItem>
                <MenuItem component={Link} to="/upgrades" className={classes.menuItem}>Template Updater</MenuItem>
                {/* <MenuItem component={Link} to="/editTemplateLibrary" className={classes.menuItem}>Edit TemplateLibrary</MenuItem> */}
            </Menu>
        </div>

    )
}