import { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { ObjectHeader } from "./ObjectHeader";
import { ObjectDataField } from "./ObjectDataField";
import { ScheduleSliders } from "./ScheduleSliders";
import { AssemblyInfoFields, MetadataFields } from "./ObjectSimpleFieldGroup";
import { ScheduleDaySelectors } from "./ScheduleDaySelectors";
import { ZoneConstructionSetControls } from "./ZoneConstructionSetControls";
import { LayersControls } from "./LayersControls";
import { MultiSelectControl } from "./MultiSelectControl";
import { ObjectSortedDataFields, sortedKeysByClass } from "./ObjectSortedDataFields";

export const ObjectEditor = (props) => {
    const { indexedNodes, selectedNode} = props.state;

    const selectedNodeData = useMemo(() => {
        if (selectedNode) {
            return indexedNodes[selectedNode];

        } else {
            return null;
        }
    }, [indexedNodes, selectedNode]);

    const excludedKeys = ["$id", "class"].concat(MetadataFields).concat(AssemblyInfoFields).concat(sortedKeysByClass[indexedNodes[selectedNode].class] ? sortedKeysByClass[indexedNodes[selectedNode].class] : [])
    const specialKeys = ["Values", "Days", "Layers", "ClimateZone", "Country"]

    const classPropsByClass = {
        ZoneConstructionSet: {
            gridSize: 6,
        }
    }
    const classProps = selectedNodeData ? classPropsByClass[selectedNodeData.class] ? classPropsByClass[selectedNodeData.class] : {} : {}

    const classLayouts = { 
        // ZoneConstructionSet: <ZoneConstructionSetControls {...props} {...classProps} />,
    }

    const normalKeys = selectedNodeData ? Object.keys(selectedNodeData).filter(key=>!specialKeys.includes(key) && !excludedKeys.includes(key)) : []
    return (selectedNodeData ?
        <Grid item container spacing={3} xs={6}>
            <ObjectHeader {...props} />
            {Object.keys(classLayouts).includes(selectedNodeData.class) ? 
                    classLayouts[selectedNodeData.class]
            : 
                <>
                    <ObjectSortedDataFields {...props} {...classProps} /> 
                    {normalKeys.map((key) => <ObjectDataField {...props} {...classProps} key={key} keyName={key} />)}
                    {Object.keys(selectedNodeData).includes("Values") && <ScheduleSliders {...props} />}
                    {Object.keys(selectedNodeData).includes("Days") && <ScheduleDaySelectors {...props} />}
                    {Object.keys(selectedNodeData).includes("Layers") && <LayersControls {...props} />}
                    {Object.keys(selectedNodeData).includes("ClimateZone") && <MultiSelectControl keyName={"ClimateZone"} {...props} />}
                    {Object.keys(selectedNodeData).includes("Country") && <MultiSelectControl keyName={"Country"} {...props} />}
                </>
            }
        </Grid>
        :
        <Grid item xs={6}>
            No nodes selected...
        </Grid>


    );

};
