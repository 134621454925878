import { useCallback, useEffect, useMemo, useState } from "react"
import axios from "axios";
import fileDownload from "js-file-download";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid"
import './UpgradeTemplateLibrary.css'
import { ErrorDisplay } from "../ErrorDisplay";
import { Changelog } from "./MeasuresWorkflow/Changelog";
import { ConfigureCustomMeasure } from "./MeasuresWorkflow/ConfigureCustomMeasure";
import { ConfigureDownload } from "./MeasuresWorkflow/ConfigureDownload";
import { Measure } from "./MeasuresWorkflow/Measure";
import { MeasuresLib } from "./MeasuresWorkflow/MeasuresLib";
import { MuiDropzone } from "../MuiDropzone";
import { TemplateLibraryLayout } from "./TemplateLibraryLayout";
import { useTabSelector } from "../../clientLib/hooks";
import { Selector } from "../MultiSelector";
import { Layout } from "../Layout";
import { useTabNavigation } from "../../redux/slices/navigation/hooks";



export default () => {
    const [measuresLib, setMeasuresLib] = useState(MeasuresLib)
    const [error, setError] = useState(null)
    const [templateNames, setTemplateNames] = useState([])
    const [activeTemplates, setActiveTemplates] = useState([])
    const [activeMeasures, setActiveMeasures] = useState([])
    const [measuresQueue, setMeasuresQueue] = useState({})
    const [changelog, setChangelog] = useState({})
    const [queueIsApplying, setQueueIsApplying] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [inputFileType, setInputFileType] = useState(null)
    // const {selectedTab, setSelectedTab, handleTabChange} = useTabSelector({initialTab: "Upload"})
    const {goToTab: setSelectedTab} = useTabNavigation()


    const toggleCustomMeasure = useCallback(() => {
        setSelectedTab("CustomMeasure")
    }, [setSelectedTab])

    const toggleChangelog = useCallback(() => {
        setSelectedTab("Changelog")
    }, [setSelectedTab])

    const clearQueue = useCallback(() => {
        // Empty the queue
        setMeasuresQueue({})
        setSelectedTab("Upgrade")
    }, [setSelectedTab, setMeasuresQueue])

    const updateQueue = useCallback((key,values) => {
        // Update the queue with a new measure
        setMeasuresQueue(prev => (
            {
                ...prev,
                [key]: values
            }
        ))

    }, [setMeasuresQueue])

    const handleUploadSuccess = useCallback((res) => {
        // Load template names and reset state
        const {names, file_extension} = res.data
        setInputFileType(file_extension)
        setTemplateNames(names)
        setMeasuresQueue({})
        setChangelog({})
        setActiveTemplates([])
        setSelectedTab("Upgrade")
    }, [setTemplateNames, setChangelog, setMeasuresQueue, setTemplateNames, setSelectedTab, setInputFileType])

    // Wipe out user files when the page loads
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/remove_user_files`, {withCredentials: true}).then(res => {
            console.log(res);
        });
    }, [])

    const applyQueue = useCallback(async () => {
        // Call the API to apply queue to active templates
        // Request args
        const args = {
            templates: activeTemplates,
            measuresQueue,
            custom: {},
        }

        Object.keys(measuresQueue).filter(measureId=>measuresLib[measureId].custom).map(measureId => {
            args.custom[measureId] = measuresLib[measureId].custom
        })
        try {
            setQueueIsApplying(true)
            const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/apply_measures`, args, {
                withCredentials: true,
                headers: {"Content-Type": "application/json"}
            })
            const { data } = res
            // Parse the change log response into an object with the logs sorted by template name
            setChangelog(prev => {
                const newChangelog = JSON.parse(JSON.stringify(prev))
                Object.entries(data).map( ([templateName, actions]) => {
                    if (Object.keys(newChangelog).includes(templateName)) {
                        newChangelog[templateName] = newChangelog[templateName].concat(Object.values(actions))
                    } else {
                        newChangelog[templateName] = Object.values(actions)
                    }

                })
                return newChangelog
            })
            setQueueIsApplying(false)
            setSelectedTab("Changelog")

        } catch (error) {
            setQueueIsApplying(false)
            setError(error)
        }

    }, [setChangelog,activeTemplates, setSelectedTab, measuresQueue, measuresLib, setChangelog, setActiveTemplates])

    const download = useCallback(async (options) => {
        try {
            setIsDownloading(true)
            const config = {withCredentials: true}
            if (inputFileType.toLowerCase() == ".umi") {
                config.responseType = "blob"
            }
            const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/export`, options, config)
            const filename = res.headers["x-suggested-filename"]
            
            if (inputFileType.toLowerCase() === ".umi") {
                fileDownload(res.data, filename)
            } else {
                fileDownload(JSON.stringify(res.data, null, 4), filename)
            }
            setTimeout(()=>setIsDownloading(false), 750)
        } catch (error) {
            setError(error)
            setIsDownloading(false)
        }

    }, [inputFileType, setError, setIsDownloading])

    const templateSelectorOptions = useMemo(()=>templateNames.map(name=>({label: name, value: name})), [templateNames])
    const measureSelectorOptions = useMemo(()=>Object.entries(measuresLib).map(([measureId, {label}])=>({label, value: measureId})), [measuresLib])
    const loading = useMemo(() => isDownloading || isUploading || queueIsApplying, [isDownloading, isUploading, queueIsApplying])

    // TODO: Views should be memoized or nulled if tab not selected
    // to improve performance, or factored out to components
    const UploadView = (
        <div className="template-select-panel">
            <MuiDropzone 
                setIsUploading={setIsUploading} 
                errorCb={setError} 
                uploadSuccessCb={handleUploadSuccess} 
                target="upgrader/upload_initial_file"
                accept="application/zip, application/x-zip-compressed, multipart/x-zip, .umi, application/json, .json"
                maxFiles={1}
                clearUserFiles={true}
            />
        </div>
    )

    const UpgradeView = (
        <Grid container spacing={3}>        
            <Grid item xs={12}>
                <Selector activeList={activeTemplates} setActiveList={setActiveTemplates} options={templateSelectorOptions} name="templates" descriptor="Active Templates"/>
                <Selector activeList={activeMeasures} setActiveList={setActiveMeasures} options={measureSelectorOptions} name="measures" descriptor="Measures to Apply" customRender={(selected)=>selected.map(measureId => measuresLib[measureId].label).join(", ")} />
            </Grid>
            <Grid 
                container
                spacing={3}
                item
                xs={12}
            >
                <Grid item>
                    <Button variant="outlined" onClick={()=>setActiveTemplates([...templateNames])}>Select All Templates</Button>
                </Grid>
                <Grid item>
                    <Button disabled={activeTemplates.length === 0} variant="outlined" onClick={()=>setActiveTemplates([])}>Clear Active Templates</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={toggleCustomMeasure}>Create Custom Measure</Button>
                </Grid>
                <Grid item>
                    <Button disabled={Object.keys(measuresQueue).length === 0 || activeTemplates.length == 0} variant="outlined" onClick={applyQueue}>Apply Measures</Button>
                </Grid>
                <Grid item>
                    <Button disabled={Object.keys(changelog).length === 0}variant="outlined" onClick={toggleChangelog}>View Changelog</Button>
                </Grid>
            </Grid>
            {activeMeasures.map(measureId => ( 
                <Grid
                    item
                    xs={3}
                    key={measureId} 
                >
                        <Measure disabled={activeTemplates.length === 0 } updateQueue={updateQueue} measureId={measureId}  config={measuresLib[measureId]} />
                </Grid>
            ))}
        </Grid>
    )

    const CustomMeasureView = (
        <ConfigureCustomMeasure
            setMeasuresLib={setMeasuresLib}
            setSelectedTab={setSelectedTab}
        />
    )

    const ChangelogView = (
        <Grid container spacing={3}>
            <Grid 
                item
                xs={12}
            >
                <Changelog show={true} changelog={changelog} />
            </Grid>
            <Grid 
                container
                spacing={3}
                item
                xs={12}
            >
                <Grid item>
                    <Button variant="outlined" onClick={() =>{setActiveTemplates([]); setActiveMeasures([]); setSelectedTab("Upgrade")}}>Apply Additional Measures</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={() => setSelectedTab("Export")}>Export</Button>
                </Grid>
            </Grid>
        </Grid>
    )

    const DownloadView = (
        <ConfigureDownload download={download} inputFileType={inputFileType} />
    )

    const tabsConfig = {
        tabs: { 
            Upload: {
                label: "Upload",
                title: "Upgrade Template Library",
                description: "Upload an UMI Template Library (.json) or an UMI file (.umi) and apply upgrades",
                disabled: false,
                component: UploadView,
            },
            Upgrade: {
                label: "Upgrade",
                title: "Upgrade Template Library",
                description: "Apply upgrade measures to templates",
                disabled: templateNames.length === 0,
                component: UpgradeView,
            },
            CustomMeasure: {
                label: "Define Custom Measure",
                title: "Upgrade Template Library",
                description: "Define a custom upgrade measure which can be applied to templates",
                disabled: templateNames.length === 0,
                component: CustomMeasureView,
            },
            Changelog: {
                label: "Changelog",
                title: "Upgrade Template Library",
                description: "View the changes applied to each template",
                disabled: Object.keys(changelog).length === 0,
                component: ChangelogView,
            },
            Export: {
                label: "Export",
                title: "Upgrade Template Library",
                description: "Download the upgraded template library",
                disabled: Object.keys(changelog).length === 0,
                component: DownloadView,
            },

        },
        defaultTab: "Upload",
        root: {
            title: "Technology Pathway Upgrades",
            description: "Upgrade UMI files and Template Libraries",
            component: <div>Help info coming</div>,
            redirect: true
        },
        otherRoutes: {
            someOtherRoute: {
                component: <div>hello there</div>
            }
        }
    }


    return (
        <Layout
            tabsConfig={tabsConfig}
            loading={loading}
        >
            <ErrorDisplay error={error}/>
        </Layout>
    )
}