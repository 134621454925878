// Hooks
import {useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// MUI
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from "@material-ui/icons/Cancel"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import DescriptionIcon from "@material-ui/icons/Description"
import { makeStyles } from '@material-ui/core/styles';

///// Umiverse Imports

// Redux
import { selectErrorSummary, selectKeyFieldErrorBypassMessages } from '../../../redux/slices/umibuilder/selectors';
import { Button, Divider } from '@material-ui/core';
import { useCallback } from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '80vw',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  actions: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    backgroundColor: "#000000"+"cc",
  },

}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default ({open, handleClose, handleContinue}) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const errorSummary = useSelector(selectKeyFieldErrorBypassMessages)
    const acceptAndContinue = useCallback(() => {
        handleContinue()
        handleClose()
    }, [handleClose, handleContinue])



    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose}  BackdropProps={{className: classes.backdrop}}>
                <Alert onClose={handleClose} severity="warning" color="info">
                    <Typography variant="h5" component="h3">Keyfields errors</Typography>
                    <Typography variant="body2">
                        The current keyfields-to-column assignment has errors which UBEM.io will try to fix.  
                        For more information about GIS data requirements, please open the help bar.
                    </Typography>
                    <Grid container spacing={1}>
                        {errorSummary.map(({automatedAction, summary}, i) => (
                            <Grid item xs={12} key={`keyfields-warning-${i}`} >
                                <Typography variant="overline" component="h5">{summary}</Typography>
                                <Typography variant="caption">{automatedAction}</Typography>
                                <Divider/>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container spacing={2} className={classes.actions} justifyContent="center">
                        <Grid item>
                            <Button size="small" startIcon={<CancelIcon/>} variant="contained" color="primary" onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" endIcon={<NavigateNextIcon/>} variant="contained" color="primary" onClick={acceptAndContinue}>Accept and Continue</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" endIcon={<DescriptionIcon/>} variant="contained" color="primary" onClick={handleClose}>Download Report</Button>
                        </Grid>
                    </Grid>
                </Alert>
            </Dialog>
        </div>
    )
}


