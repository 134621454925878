import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const editorProps = {
	accept: "application/zip, application/x-zip-compressed, multipart/x-zip, .umi, application/json, .json",
	maxFiles: 1,
}

export const MuiDropzone = ({ errorCb, uploadSuccessCb, setIsUploading, target, accept, maxFiles, clearUserFiles, selectedFilename, showBrowseButton, label}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const onDrop = useCallback((acceptedFiles) => {
        errorCb(null);
        setIsError(false);
        setIsUploading(true);
        setIsSuccess(false);
        acceptedFiles.map(async (file) => {
            const data = new FormData();
            data.append("file", file);
            try {
                if (clearUserFiles) await axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/remove_user_files`, { withCredentials: true });
                const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/${target}`, data, {
                    withCredentials: true,
                    headers: { "Content-Type": "multipart/form-data" }
                });
                setIsUploading(false);
                setIsSuccess(true);
                uploadSuccessCb(res, file.name);
            } catch (error) {
                setIsError(true);
                setIsUploading(false);
                if (errorCb)
                    errorCb(error);
            }
        });
    }, [errorCb]);
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({ 
        accept,
        maxFiles,
        onDrop,
     });

    return (
        <div className="template-browser" {...getRootProps()}>
            {(showBrowseButton || showBrowseButton === undefined) && <Button className="template-browser-button" variant="outlined">Browse...</Button>}
            <TextField
                variant="outlined"
                label={label ?? "Template Library"}
                value={selectedFilename ? selectedFilename : acceptedFiles.length > 0 ? acceptedFiles.map(file => file.name).join(", ") : ""}
                InputProps={{
                    readOnly: true
                }}
                size="small"
                error={isError ? true : undefined} />
            <input {...getInputProps()} />
        </div>
    );
};