// Hooks
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"
import Tooltip from "@material-ui/core/Tooltip"
import CircularProgress from "@material-ui/core/CircularProgress"
import Collapse from "@material-ui/core/Collapse"
import Alert from "@material-ui/lab/Alert"

// Recharts
import { CartesianGrid, ComposedChart, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip as RechartsTooltip, Line, Area } from "recharts"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { 
    selectBaselineAssignment, 
    makeSelectEcmAssignment, 
    selectSortedScenarioNames, 
    selectDuplicateAdoptionAssignments, 
    selectBreakdown,
    canComputeAdoption,
    selectMultiplierSeries,
    selectCopScalars,
    selectAdoptionCacheIsValid,
    selectMetric,
} from "../../../redux/slices/analysis/selectors"
import { setAdoptionCacheKey, setAdoptionMode, setBaselineAssignment, setEcmAssignment } from "../../../redux/slices/analysis/reducers"
import { AdoptionConfigMode, AdoptionConfigModeOptions, Breakdown, EndUseConfig, EndUses, Metric, MetricConfig } from "../../../redux/slices/analysis/consts"
import { mutationFixedCacheKeys } from "../../../redux/slices/analysis/endpoints"
import { startAsyncTask } from "../../../redux/slices/async-processing/reducers"
import { AsyncTask } from "../../../redux/slices/async-processing/consts"

// Components
import { RtkDropzone } from "../../RtkDropzone"
import TargetLines from "../components/TargetLines"
import ScenarioSelector from "../components/ScenarioSelector"
import AnalysisDropdownSelect, { AnalysisDropdown } from "../components/AnalysisDropdownSelect"
import AdoptionControl, { AdoptionControls } from "../components/AdoptionControl"

const scenarioGridWidth = 6
const scenarioParameterWidth = 12-scenarioGridWidth
const {useMutation: useComputeAdoption }  = ubemIoApi.endpoints.computeAdoption
const {useMutation: useUploadGisConfig }  = ubemIoApi.endpoints.uploadAdoptionGisFile


export default () => {
    const dispatch = useDispatch()
    const scenarioNames = useSelector(selectSortedScenarioNames)
    const duplicateAssignmentError = useSelector(selectDuplicateAdoptionAssignments)
    const adoptionSettings = useSelector(state => state.analysis.adoption)
    const canCompute = useSelector(canComputeAdoption)
    const multipliers = useSelector(selectMultiplierSeries)
    const copScalars = useSelector(selectCopScalars)
    const metric = useSelector(selectMetric)


    const data = useSelector(state => state.analysis.adoption.data)
    const [mutateAdoptionGisConfig, {data: gisConfigData}] = useUploadGisConfig({fixedCacheKey: mutationFixedCacheKeys.adoptionGisConfigFile})

    const longTarget = useSelector(state => state.analysis.targets.long.year)

    const computeAdoption = useCallback(() => {
        const body = {
            ...adoptionSettings,
            years: longTarget - (new Date().getFullYear()) + 3,
            multipliers,
            copScalars,
            metric,
        }
        dispatch(setAdoptionCacheKey(metric))
        dispatch(startAsyncTask({endpoint: AsyncTask.computeAdoption, body}))
    }, [adoptionSettings, longTarget, multipliers, copScalars, dispatch])

    // Bail out
    if (scenarioNames.length < 2) {
        return (
            <Typography>You must upload at least two scenarios to run the adoption simulations.</Typography>
        )
    }
    return (
        <Grid container spacing={3}>
            <Grid item container spacing={2} direction="column" xs={4} alignItems="center">
                <Grid item >
                    <ToggleButtonGroup onChange={(e,value) => {if (value) dispatch(setAdoptionMode(value))}} value={adoptionSettings.mode} exclusive>
                        {AdoptionConfigModeOptions.map(({value, label}) => (
                            <ToggleButton key={`${value}-adoption-config-mode-toggle`} value={value}>{label}</ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    <Collapse in={adoptionSettings.mode===AdoptionConfigMode.GIS} >
                        <div>
                            <RtkDropzone 
                                accept={'application/zip, .geojson, application/json, application/x-zip-compressed, multipart/x-zip,' +
                                        ' "multipart/form-data", .cpg, .dbf, .prj, .qpj, .shp, .shx'}
                                maxFiles={6}
                                label="GIS Upload"
                                skipReset={true}
                                index={mutationFixedCacheKeys.adoptionGisConfigFile}
                                mutation={useUploadGisConfig}
                            />
                        </div>
                    </Collapse>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item xs={scenarioGridWidth}>
                        <ScenarioSelector 
                            label="Baseline" 
                            helperText="Initial state"
                            selector={selectBaselineAssignment} 
                            action={setBaselineAssignment}
                            error={duplicateAssignmentError}
                        />
                    </Grid>
                    <Grid item xs={scenarioParameterWidth}>
                        <AdoptionControl type={AdoptionControls.baseline} />
                    </Grid>
                </Grid>
                <Grid item container spacing={3} >
                    <Grid item xs={scenarioGridWidth}>
                        <ScenarioSelector 
                            label={ `ECM${scenarioNames.length > 2 ? 1 : ""}`} 
                            helperText={ `Upgrade Package${scenarioNames.length > 2 ? " 1" : ""}` }
                            selector={makeSelectEcmAssignment(0)} 
                            action={assignment => setEcmAssignment({scenario: assignment, index: 0})} 
                            error={duplicateAssignmentError}
                        />
                    </Grid>
                    <Grid item xs={scenarioParameterWidth}>
                        <AdoptionControl type={AdoptionControls.ecm} index={0} />
                    </Grid>
                </Grid>
                {scenarioNames.length > 2 && (
                    <Grid item container spacing={3} >
                        <Grid item xs={scenarioGridWidth}>
                            <ScenarioSelector 
                                label="ECM2" 
                                helperText="Upgrade Package 2"
                                selector={makeSelectEcmAssignment(1)} 
                                action={assignment => setEcmAssignment({scenario: assignment, index: 1})} 
                                error={duplicateAssignmentError}
                            />
                        </Grid>
                        <Grid item xs={scenarioParameterWidth}>
                            <AdoptionControl type={AdoptionControls.ecm} index={1} />
                        </Grid>
                    </Grid>
                )}
                <Grid item style={{width: "100%"}}>
                    <Collapse in={adoptionSettings.mode===AdoptionConfigMode.GIS} >
                        <div>
                           <AdoptionControl type={AdoptionControls.name} />
                        </div>
                    </Collapse>
                </Grid>
                <Grid item>
                    <AnalysisDropdownSelect dropdown={AnalysisDropdown.Metric} />
                </Grid>
                <Grid item>
                    <ConditionalTooltip showTooltip={!canCompute} title={"You must assign fields before computing adoption."}>
                            <Button onClick={computeAdoption} disabled={!canCompute} variant="contained">Compute</Button>
                    </ConditionalTooltip>
                </Grid>
                <Grid item/>
            </Grid>
            <Grid item container direction="column" justifyContent="center" alignItems="center" xs={8}>
                {data && <AdoptionChartWithPng data={data} />}
                {data && (
                    <Grid item xs style={{width: "30%"}}>
                        <AnalysisDropdownSelect dropdown={AnalysisDropdown.AdoptionBreakdown} />
                    </Grid>
                )}
                <Grid item xs/>
            </Grid>
        </Grid>
    )
}

export const AdoptionChartWithPng = ({data}) => {
    const breakdown = useSelector(selectBreakdown)
    const { cacheIsValid, metric } = useSelector(selectAdoptionCacheIsValid)

    if ([Breakdown.Archetype, Breakdown.Source].includes( breakdown)) {
        return (
            <Typography variant="overline" >No data available for the selected breakdown</Typography>
        )
    }
    return (
        <>
            <Collapse in={!cacheIsValid}>
                <Alert severity="warning">The current simulation data is stale because time series or fuel parameter settings have changed.</Alert>
            </Collapse>
            <Typography variant="h5">{metric} Projection</Typography>
            <Box height={"55vh"} width={"100%"}>
                <ResponsiveContainer width={"99%"} height={"99%"}>
                    <ComposedChart 
                            width={800} 
                            height={800} 
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            stackOffset={"none"}
                        >
                            <CartesianGrid/>
                            <XAxis dataKey="Year" />
                            <YAxis 
                                domain={['auto','auto']}
                                label={{
                                    value: `${MetricConfig[metric]?.label} (${MetricConfig[metric]?.units})`,
                                    angle: -90,
                                    position: 'insideBottomLeft',
                                    textAnchor: "middle",
                                }}
                                width={100}
                            />
                            <Legend verticalAlign="top" height={36}/>
                            <RechartsTooltip cursor={false} formatter={MetricConfig[metric]?.formatter}/>
                            {breakdown === Breakdown.Total && (
                                <>
                                    <Line 
                                        dataKey={`Total_lower_whisker`}
                                        // stroke={useColors[use]} 
                                        // type="basis"
                                        strokeDasharray="3 3"
                                        dot={false}
                                    />
                                    <Line 
                                        dataKey={`Total_upper_whisker`}
                                        // stroke={useColors[use]} 
                                        // type="basis"
                                        strokeDasharray="3 3"
                                        dot={false}
                                    />
                                    <Line 
                                        dataKey={`Total_median`}
                                        // stroke={useColors[use]} 
                                        type="basis"
                                        dot={false}
                                    />
                                    <Area 
                                        dataKey={`Total`} 
                                        type="basis"
                                        // stroke={useColors[use]} 
                                        // fill={useColors[use]} 
                                    />
                                </>
                            )}
                            {breakdown === Breakdown.Use && Object.entries(EndUseConfig).map( ([endUse, {color}])=> (
                                <Area 
                                    key={`${endUse}-area`}
                                    dataKey={`${endUse}_median`} 
                                    type="basis"
                                    stackId={1}
                                    stroke={color} 
                                    fill={color} 
                                />
                            ))}
                            {/* {TargetLines()} */}
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
        </>
    )
}

const ConditionalTooltip = ({title, showTooltip, children}) => {
    if (showTooltip) {
        return ( 
            <Tooltip title={title}>
                <span>
                    {children}
                </span>
            </Tooltip> 
        )
    } else {
        return children
    }
}