// Hooks

// MUI
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CategoryIcon from "@material-ui/icons/Category";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ListIcon from "@material-ui/icons/ListAlt";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

///// Umiverse Imports

// Components
import ScenarioSelector from "./ScenarioSelector";
import AnalysisDropdownSelect, {
    AnalysisDropdown
} from "./AnalysisDropdownSelect";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import fileDownload from "js-file-download";

const useStyles = makeStyles(theme => ({
    dropdown: {
        margintop: "1rem",
        marginBottom: "1.25rem"
    }
}));

export default ({
    exportData,
    pngCallback,
    svgCallback,
    usesScenarioSelect = true,
    usesNormalizationSelect = true,
    usesBreakdownSelect = true,
    children,
    usesAllSelect = false
}) => {
    const classes = useStyles();
    const data = useSelector(state => state.analysis.data);
    const exportAggregatedData = useCallback(() => {
        const tabular_data_raw = [];
        const tabular_data_normalized = [];
        Object.entries(data).forEach(([scenario, { Archetype }]) => {
            Object.entries(Archetype.original).forEach(
                ([archetype_name, endUses]) => {
                    Object.entries(endUses).forEach(([end_use, value]) => {
                        const row = {
                            Scenario: scenario,
                            Archetype: archetype_name,
                            "End Use": end_use,
                            Value: value
                        };
                        tabular_data_raw.push(row);
                    });
                }
            );
        });
        fileDownload(
            "Scenario,Archetype,End Use,Value (kWh)\r\n" +
                tabular_data_raw
                    .map(obj => Object.values(obj).join(","))
                    .join("\r\n"),
            "aggregated_data_absolute.csv"
        );
        Object.entries(data).forEach(([scenario, { Archetype }]) => {
            Object.entries(Archetype.normalized).forEach(
                ([archetype_name, endUses]) => {
                    Object.entries(endUses).forEach(([end_use, value]) => {
                        const row = {
                            Scenario: scenario,
                            Archetype: archetype_name,
                            "End Use": end_use,
                            Value: value
                        };
                        tabular_data_normalized.push(row);
                    });
                }
            );
        });
        fileDownload(
            "Scenario,Archetype,End Use,Value (kWh/m2)\r\n" +
                tabular_data_normalized
                    .map(obj => Object.values(obj).join(","))
                    .join("\r\n"),
            "aggregated_data_normalized.csv"
        );
    }, [data]);
    return (
        <>
            <Box marginBottom={"1rem"}>
                <Tooltip title="Export shapefile data with energy results per building (nb: this is an uncalibrated model, so per building results are not accurate!)">
                    <IconButton onClick={exportData}>
                        <SaveAltIcon />
                    </IconButton>
                </Tooltip>
                {/* <Button variant="outlined" onClick={exportData}>Export Data</Button> */}
                <Tooltip title="Export a snapshot of the chart (png)">
                    <IconButton onClick={pngCallback}>
                        <CameraAltIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export a snapshot of the chart (svg)">
                    <IconButton onClick={svgCallback}>
                        <CategoryIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export aggregated data as a csv.">
                    <IconButton onClick={exportAggregatedData}>
                        <ListIcon />
                    </IconButton>
                </Tooltip>
                <Divider />
            </Box>

            {usesScenarioSelect && (
                <Box className={classes.dropdown}>
                    <ScenarioSelector usesAllSelect={usesAllSelect} />
                </Box>
            )}

            <Box className={classes.dropdown}>
                <AnalysisDropdownSelect dropdown={AnalysisDropdown.Metric} />
            </Box>

            {usesBreakdownSelect && (
                <Box className={classes.dropdown}>
                    <AnalysisDropdownSelect
                        dropdown={AnalysisDropdown.Breakdown}
                    />
                </Box>
            )}

            {usesNormalizationSelect && (
                <Box className={classes.dropdown}>
                    <AnalysisDropdownSelect
                        dropdown={AnalysisDropdown.Normalization}
                    />
                </Box>
            )}

            {children}
        </>
    );
};
