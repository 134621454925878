// Hooks
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useMemo } from "react"

// MUI
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

///// Umiverse Imports

// Redux
import {updateEndUseScenarioSettings } from "../../../redux/slices/analysis/reducers"
import { EndUseConfig, EndUseParameterConfig } from "../../../redux/slices/analysis/consts"
import { makeSelectEndUseSettings } from "../../../redux/slices/analysis/selectors"


// TODO: abstract to be agnostic as to settings subtable (endUses, fuels)
export default ({scenarioName, endUse, parameter}) => {
    const value = useSelector(makeSelectEndUseSettings(scenarioName, endUse, parameter))
    const dispatch = useDispatch()
    const parameterConfig = useMemo(() => EndUseParameterConfig[parameter], [parameter])
    const endUseConfig = useMemo(() => EndUseConfig[endUse], [endUse])

    const handleChange = useCallback((e) => {
        dispatch(updateEndUseScenarioSettings({scenarioName, endUse, parameter, value: parameterConfig.type === "number" ? Number(e.target.value) : e.target.value}))
    }, [dispatch, updateEndUseScenarioSettings, parameterConfig] )

    if (parameterConfig.type === "number") return (
        <TextField
            label={`${endUseConfig.label} ${parameterConfig.label}`} 
            fullWidth
            // variant="outlined"
            type="number"
            min={parameterConfig.min}
            max={parameterConfig.max}
            step={parameterConfig.step}
            value={value}
            // helperText={parameterConfig.units}
            onChange={handleChange}
        />
    )
    if (parameterConfig.type === "select") return (
        <FormControl fullWidth>
            <InputLabel id={`${scenarioName}-${endUse}-${parameter}-label`} >
                {endUseConfig.label} {parameterConfig.label}
            </InputLabel>

            <Select
                labelId={`${scenarioName}-${endUse}-${parameter}-label`}
                id={`${scenarioName}-${endUse}-${parameter}`}
                value={value}
                onChange={handleChange}
            >
                {parameterConfig.options.map(({value, label}) => (
                    <MenuItem value={value} key={`${scenarioName}-${endUse}-${parameter}-option-${value}`}>{label}</MenuItem>
                ))}

            </Select>
        </FormControl>
    )
    return (
        <>
            Unimplemented control type.
        </>
    )

	return 
}