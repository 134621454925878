import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { Provider } from 'react-redux'
import { store } from "./redux/root"
// import { ThemeProvider } from "@material-ui/styles"
// import { theme } from './Theme';
// import { CssBaseline } from '@material-ui/core';


// axios.defaults.baseURL = 'http://localhost:5000/';

ReactDOM.render(
    <Provider store={store}>
        {/* <ThemeProvider theme={theme}>
            <CssBaseline /> */}
            <App />
        {/* </ThemeProvider> */}
    </Provider>,
    document.getElementById('root')
);
