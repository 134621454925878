import axios from "axios";
import { useEffect, useMemo, useReducer, useState, useCallback } from "react";
import {v4 as uuidv4} from 'uuid';
import { useTabSelector } from "../../clientLib/hooks"
import { TemplateLibraryLayout } from "./TemplateLibraryLayout";
import { ExportLibraryView } from "./GraphEditorWorkflow/ExportLibraryView";
import { EditLibraryView } from "./GraphEditorWorkflow/EditLibraryView";
import { UploadLibraryView } from "./GraphEditorWorkflow/UploadLibraryView";
import { MetadataFields } from "./GraphEditorWorkflow/ObjectSimpleFieldGroup";
import {ErrorDisplay} from "../ErrorDisplay"
import SaveIcon from "@material-ui/icons/Save"
import EditIcon from "@material-ui/icons/Edit"
import PublishIcon from "@material-ui/icons/Publish"

const levelMapping = {
    GasMaterial: 4,
    GlazingMaterial: 4,
    OpaqueMaterial: 4,
    OpaqueConstruction: 3,
    WindowConstruction: 3,
    StructureInformation: 1,
    DaySchedule: 6,
    WeekSchedule: 5,
    YearSchedule: 4,
    DomesticHotWaterSetting: 2,
    VentilationSetting: 2,
    ZoneConditioning: 2,
    ZoneConstructionSet: 2,
    ZoneLoad: 2,
    ZoneDefinition: 1,
    WindowSetting: 1,
    BuildingTemplate: 0,
}

const colorMapping = {
    GasMaterial: "#abf",
    GlazingMaterial: "#aaf",
    OpaqueMaterial: "#faf",
    OpaqueConstruction: "#f3f",
    WindowConstruction: "#66f",
    StructureInformation: "#f88",
    DaySchedule: "#ffa",
    WeekSchedule: "#ff8",
    YearSchedule: "#ff3",
    DomesticHotWaterSetting: "#faa",
    VentilationSetting: "#faa",
    ZoneConditioning: "#faa",
    ZoneConstructionSet: "#faa",
    ZoneLoad: "#faa",
    ZoneDefinition: "#f88",
    WindowSetting: "#f88",
    BuildingTemplate: "#f00",
}

export const nameMapping = {
    GasMaterial: "Gas Materials",
    GlazingMaterial: "Glazing Materials",
    OpaqueMaterial: "Opaque Materials",
    OpaqueConstruction: "Opaque Constructions",
    WindowConstruction: "Window Constructions",
    StructureInformation: "Structures",
    DaySchedule: "Day Schedules",
    WeekSchedule: "Week Schedules",
    YearSchedule: "Year Schedules",
    DomesticHotWaterSetting: "Domestic Hot Water Settings",
    VentilationSetting: "Ventilation Settings",
    ZoneConditioning: "Zone Conditionings",
    ZoneConstructionSet: "Zone Construction Sets",
    ZoneLoad: "Zone Loads",
    ZoneDefinition: "Zone Definitions",
    WindowSetting: "Window Settings",
    BuildingTemplate: "Building Templates"
}

export const libKeyNameMapping = {
    GasMaterial: "GasMaterials",
    GlazingMaterial: "GlazingMaterials",
    OpaqueMaterial: "OpaqueMaterials",
    OpaqueConstruction: "OpaqueConstructions",
    WindowConstruction: "WindowConstructions",
    StructureInformation: "StructureDefinitions",
    DaySchedule: "DaySchedules",
    WeekSchedule: "WeekSchedules",
    YearSchedule: "YearSchedules",
    DomesticHotWaterSetting: "DomesticHotWaterSettings",
    VentilationSetting: "VentilationSettings",
    ZoneConditioning: "ZoneConditionings",
    ZoneConstructionSet: "ZoneConstructionSets",
    ZoneLoad: "ZoneLoads",
    ZoneDefinition: "Zones",
    WindowSetting: "WindowSettings",
    BuildingTemplate: "BuildingTemplates"

}

const initNode = (data, id) => {
    return {
        id,
        label:`${data.class} : ${data.Name}`,
        title: `${data.Name} / ${data.Comments} / ${data.DataSource}`,
        // mass: node.data.class === "BuildingTemplate" ? 5 : 1
        fixed: {
            x: false,
            y: true,
        },
        level: levelMapping[data.class],
        color: colorMapping[data.class],
    }
}

export const buildLibrary = (indexedNodes) => {
    const library = {
        GasMaterials: [],
        GlazingMaterials: [],
        OpaqueMaterials: [],
        OpaqueConstructions: [],
        WindowConstructions: [],
        StructureDefinitions: [],
        DaySchedules: [],
        WeekSchedules: [],
        YearSchedules: [],
        DomesticHotWaterSettings: [],
        VentilationSettings: [],
        ZoneConditionings: [],
        ZoneConstructionSets: [],
        ZoneLoads: [],
        Zones: [],
        WindowSettings: [],
        BuildingTemplates: []
    }
    Object.values(indexedNodes).map(data => {
        const newData = {
            ...data
        }
        const _class = libKeyNameMapping[data.class]
        delete newData.class
        library[_class].push(newData)
    })
    return library
}

const initialState = {
    error: null, 
    indexedNodes: null, 
    graph: null, 
    selectedNode: null, 
    selectedClass: "BuildingTemplate", 
    network: null, 
    isUploading: false,
    isRebuilding: false,
    triggerRebuildGraph: false,
    inputFileType: null,
    
}
const configureGraphData = (init, args) => {
    let _state = init

    const actions = (Array.isArray(args)) ? args : [args]
    while (actions.length > 0) {
        const action = actions.shift()
        console.log(action)
        switch (action.type) { 
            case "error": 
                _state = {
                    ..._state,
                    isUploading: false,
                    isRebuilding: false,
                    error: action.error,
                }
                break
            case "clear error":
                _state = {
                    ..._state,
                    error: null
                }
                break
            case "set isUploading":
                _state ={
                    ..._state,
                    isUploading: action.isUploading,
                }
                break
            case "set input file type":
                _state = {
                    ..._state,
                    inputFileType: action.inputFileType
                }
                break
            case "initialize":
                // We need to use fixed building template ids
                const buildingTemplateIdMap = {}
                const nodes = action.originalData.nodes.map(node => {

                    if (node.data.class==="BuildingTemplate") {
                        buildingTemplateIdMap[node.id] = node.data.Name
                        return initNode(node.data, node.data.Name)
                    } else {
                        return initNode(node.data, node.id)
                    }
                })
                const edges = action.originalData.links.map(link => { 
                    const from = Object.keys(buildingTemplateIdMap).includes(link.source) ? buildingTemplateIdMap[link.source] : link.source
                    const to = Object.keys(buildingTemplateIdMap).includes(link.target) ? buildingTemplateIdMap[link.target] : link.target
                    return {
                        from,
                        to,
                        id: `${from}-to-${to}`
                } })

                const indexedNodes = {} 
                action.originalData.nodes.map(node => {
                    const objId =  node.data.class === "BuildingTemplate" ? buildingTemplateIdMap[node.id] : node.id
                    indexedNodes[objId] = {...node.data}
                })

                _state = {
                    ..._state,
                    isUploading: false,
                    originalData: action.originalData,
                    graph: {
                        nodes,
                        edges
                    },
                    indexedNodes,
                }
                break
            case "trigger rebuild graph":
                _state = {
                    ..._state,
                    triggerRebuildGraph: true
                }
                break
            case "start graph rebuild":
                _state = {
                    ..._state,
                    triggerRebuildGraph: false,
                    isRebuilding: true,
                }
                break
            case "set isRebuilding":
                _state = {
                    ..._state,
                    isRebuilding: action.isRebuilding
                }
                break
            case "set network":
                _state = {
                    ..._state,
                    network: action.network
                }
                break

            case "update simple field":
                _state.indexedNodes[action.objectId][action.key] = action.value
                if (MetadataFields.includes( action.key)) {
                    const originalIndex = _state.graph.nodes.findIndex(obj=>obj.id === action.objectId)
                    const nodes = [..._state.graph.nodes]
                    nodes[originalIndex] = initNode(_state.indexedNodes[action.objectId], action.objectId)
                    _state = {
                        ..._state,
                        graph: {
                            ..._state.graph,
                            nodes
                        }
                    }
                }
                break
            case "update index of field":
                const targetArray = [..._state.indexedNodes[action.objectId][action.key]]
                targetArray[action.index] = action.value
                _state.indexedNodes[action.objectId] = {
                    ..._state.indexedNodes[action.objectId],
                    [action.key]: targetArray,
                }
                break
            case "duplicate":
                const copiedObject = JSON.parse(JSON.stringify(_state.indexedNodes[action.targetId]))
                const existingChildrenEdges = JSON.parse(JSON.stringify(_state.graph.edges.filter(edge => edge.from === action.targetId)))
                copiedObject.$id = uuidv4()
                copiedObject.Name =`${copiedObject.Name} copy`
                const node = initNode(copiedObject, copiedObject.$id)
                _state.indexedNodes = {
                    ..._state.indexedNodes,
                    [copiedObject.$id]: copiedObject
                }
                existingChildrenEdges.map(edge => {
                    edge.from = copiedObject.$id
                    edge.id = `${copiedObject.$id}-to-${edge.to}`
                })
                
                _state = {
                    ..._state,

                    graph: {
                        nodes: [..._state.graph.nodes, node],
                        edges: [..._state.graph.edges, ...existingChildrenEdges]
                    }
                }
                if (action.relink) {
                    const relinkArgs = {
                        ...action.relink,
                        targetId: copiedObject.$id
                    }
                    actions.push(relinkArgs)
                }
                break;
            case "relink parameter at index":
                const targetArrayToRelinkParameter = [..._state.indexedNodes[action.sourceId][action.key]]
                // Update the edge
                if (targetArrayToRelinkParameter.filter(data=>data[action.parameter].$ref === action.oldTargetId).length === 1) {
                    // If the edge only occurs once in the array, remove the link
                    //TODO: need to check it's not used in any other keys!
                    _state.graph.edges.find(obj=>obj.from === action.sourceId && obj.to===action.oldTargetId).to = action.targetId
                } else {
                    _state.graph.edges.push({
                        from: action.sourceId,
                        to: action.targetId,
                        id: `${action.sourceId}-to-${action.targetId}`
                    })
                }

                // update the object:
                // Update the object
                targetArrayToRelinkParameter[action.index] = {
                    ...targetArrayToRelinkParameter[action.index],
                    [action.parameter]: {$ref: action.targetId}
                }

                // Update the index
                _state.indexedNodes[action.sourceId] = {
                    ..._state.indexedNodes[action.sourceId],
                    [action.key]:targetArrayToRelinkParameter,
                }
                _state = {
                    ..._state,
                    indexedNodes: {
                        ..._state.indexedNodes,
                    },
                    graph: {
                        nodes: [ ..._state.graph.nodes ],
                        edges: [ ..._state.graph.edges ],
                    }

                }
                actions.push({type: "trigger rebuild graph"})
                break
            case "relink index":
                const targetArrayToRelink = [..._state.indexedNodes[action.sourceId][action.key]]
                // Update the edge
                if (targetArrayToRelink.filter(data=>data.$ref === action.oldTargetId).length === 1) {
                    // If the edge only occurs once in the array, remove the link
                    //TODO: need to check it's not used in any other keys!
                    _state.graph.edges.find(obj=>obj.from === action.sourceId && obj.to===action.oldTargetId).to = action.targetId
                } else {
                    _state.graph.edges.push({
                        from: action.sourceId,
                        to: action.targetId,
                        id: `${action.sourceId}-to-${action.targetId}`
                    })
                }

                // Update the object
                targetArrayToRelink[action.index] = {
                    ...targetArrayToRelink[action.index],
                    $ref: action.targetId
                }

                // Update the index
                _state.indexedNodes[action.sourceId] = {
                    ..._state.indexedNodes[action.sourceId],
                    [action.key]: targetArrayToRelink,
                }
                _state = {
                    ..._state,
                    indexedNodes: {
                        ..._state.indexedNodes,
                    },
                    graph: {
                        nodes: [ ..._state.graph.nodes ],
                        edges: [ ..._state.graph.edges ],
                    }

                }
                actions.push({type: "trigger rebuild graph"})
                break
            case "relink":
                // TODO: Make sure node unlinking happens correctly (e.g. with perimeter/core zonedefs)
                if (Object.values(_state.indexedNodes[action.sourceId]).filter(value => value?.$ref === action.oldTargetId).length === 1) {
                    _state.graph.edges.find(obj=>obj.from === action.sourceId && obj.to===action.oldTargetId).to = action.targetId
                } else {
                    _state.graph.edges.push({
                        from: action.sourceId,
                        to: action.targetId,
                        id: `${action.sourceId}-to-${action.targetId}`
                    })
                }
                _state.indexedNodes[action.sourceId][action.key] = {$ref: action.targetId}
                _state = {
                    ..._state,
                    graph: {
                        nodes: [ ..._state.graph.nodes ],
                        edges: [ ..._state.graph.edges ],
                    }

                }
                actions.push({type: "trigger rebuild graph"})
                break
            case "select node":
                _state = {
                    ..._state,
                    selectedNode: action.id !== undefined ? action.id : _state.selectedNode,
                    selectedClass: null,
                }
                break
            case "select class":
                _state = {
                    ..._state,
                    selectedNode: null,
                    selectedClass: action.class
                }
                break
            case "begin upload":
                _state = {
                    ..._state,
                    isUploading: true,
                }
                break
            default:
                console.log("defaulting")
                console.log(action)
                _state = _state 
                break
        } 
    }

    return {
        ..._state
    }
}

export default () => {

    const [state, dispatch] = useReducer(configureGraphData, initialState )
    const {graph, indexedNodes, selectedNode, isUploading, isRebuilding, triggerRebuildGraph, selectedClass} = state


    // Resize the network to fit when selectedNode changes
    useEffect(() => {
        if (state.network) state.network.fit()

    }, [selectedNode, selectedClass, state.network, graph?.edges, graph?.nodes])

    useEffect(() => {
        if (triggerRebuildGraph) {
            dispatch({
                type: "start graph rebuild",
            })
            const rebuildGraph = async () => {

                try {
                    const library = buildLibrary(indexedNodes)
                    const res = await axios.post(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/upload_file_to_mutate`, library, {
                        withCredentials: true,
                    });
                    const {data} = await axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/build_template_library_graph`, {withCredentials: true})
                    dispatch([
                        {
                            type: "initialize",
                            originalData: data
                        },
                        {
                            type: "set isRebuilding",
                            isRebuilding: false
                        }
                    ])
                } catch (error) {
                    dispatch({type: "error", error})
                }
            }
            rebuildGraph()
        }

    }, [triggerRebuildGraph, indexedNodes, dispatch])

    const activeGraph = useMemo(() => {
        if (graph) {
            if (selectedNode) {
                const childrenNodeIds = graph.edges.filter(edge=>edge.from === selectedNode).map(edge=>edge.to)
                const parentNodeIds = graph.edges.filter(edge=>edge.to === selectedNode).map(edge=>edge.from)
                const parentIdsByLevel = [[...parentNodeIds]]
                while (graph.edges.filter(edge=>parentNodeIds.includes(edge.to) && !parentNodeIds.includes(edge.from)).length > 0) {
                    const ids = graph.edges.filter(edge=>parentNodeIds.includes(edge.to) && !parentNodeIds.includes(edge.from)).map(edge=>edge.from) 
                    parentIdsByLevel.push([...ids])
                    parentNodeIds.push.apply(parentNodeIds, [...ids])
                }
                // parentIdsByLevel.reverse()
                const nodeIds = [...childrenNodeIds, ...parentNodeIds, selectedNode]
                const nodes = graph.nodes.filter(({id})=>nodeIds.includes(id))
                const configuredNodes = nodes.map(_node=>{
                    const node = {
                        ..._node
                    }
                    
                    if (parentNodeIds.includes(node.id)) {
                        // node.color = "#ff8888"
                        let i = parentIdsByLevel.length
                        parentIdsByLevel.map(parentLevel => {
                            if (parentLevel.includes(node.id)) {
                                // node.level = i
                            }
                            i = i-1

                        })
                    }
                    if (node.id === selectedNode) {
                        node.color = "#00ff00"
                        // node.level = parentIdsByLevel.length+(parentIdsByLevel.length === 1 ? 0 : 1) 
                    }
                    if (childrenNodeIds.includes(node.id)) {
                        // node.color = "#8888ff"
                        node.level = levelMapping[indexedNodes[selectedNode].class]+1
                        // node.level = parentIdsByLevel.length+(parentIdsByLevel.length === 1 ? 0 : 1) + 1
                    }
                    if (indexedNodes[node.id].class === "BuildingTemplate") {
                        // node.color = "#ff0000"
                        // node.mass = 10
                        // node.level = 0
                    }
                    if (["ZoneDefinition", "WindowSetting", "StructureInformation"].includes(indexedNodes[node.id].class)) {
                        // node.level = 1
                    }
                    return {...node}
                })
                return {
                    edges: [...graph.edges],
                    nodes: configuredNodes,
                }
            } else if (selectedClass) {
                return {
                    edges: [...graph.edges],
                    nodes: graph.nodes.filter(_node=>indexedNodes[_node.id].class === selectedClass)

                }
            } else {
                return graph
            }

        } else {
            return null
        }

    }, [selectedNode, graph, indexedNodes, selectedClass ])


    const [graphOptions, setGraphOptions] = useState({
        layout: {
            hierarchical: true
        },
        edges: {
            color: "#000000"
        },
        height: "550px",
        nodes: {
            font: "12px arial black",
            widthConstraint: 150,
        }
    })


    const graphEvents = useMemo(() => ({
        select: ({nodes, edges}) => {
           const nodesData = nodes.map(nodeId=>indexedNodes[nodeId]) 
           const args = {
                type: "select node",
                id: nodes[0]
           }
            dispatch(args)
        }

    }), [indexedNodes, dispatch])

    const setNetwork = useCallback((network) => {
         dispatch({
            type: "set network",
            network
        })
    }, [ dispatch ])

    const graphProps = {
        getNetwork: setNetwork,
        graph: activeGraph,
        events: graphEvents,
        options: graphOptions,
    }


    const {selectedTab, setSelectedTab, handleTabChange} = useTabSelector({initialTab: "Upload"})

    const UploadLibraryViewProps = {
        state,
        dispatch,
        setSelectedTab,
    }

    const EditLibraryViewProps = {
        dispatch,
        state,
        graphProps
    }

    const ExportLibraryViewProps = {
        state,
        dispatch
    }

    const tabsConfig = {
        tabs: {
            Upload: {
                label: "Upload",
                title: "Edit Template Library",
                description: "Upload a template library (.json) or UMI file (.umi) to modify",
                disabled: false,
                component: <UploadLibraryView {...UploadLibraryViewProps} />,
                icon: <PublishIcon />
                
            },
            GraphEditor: {
                label: "Library Editor",
                title: "Edit Template Library",
                description: "Edit an uploaded template library with a graphical view",
                disabled: indexedNodes ? false : true,
                component: <EditLibraryView {...EditLibraryViewProps} />,
                icon: <EditIcon />
            },
            Export: {
                label: "Export",
                title: "Edit Template Library",
                description: "Export edited library",
                disabled: indexedNodes ? false : true,
                component: <ExportLibraryView {...ExportLibraryViewProps} />,
                icon: <SaveIcon />
            }
        },
        onChange:handleTabChange,
        value: selectedTab,
    }

    const loading = useMemo(()=> isUploading || isRebuilding, [isUploading, isRebuilding])

	return (
        <TemplateLibraryLayout 
            tabsConfig={tabsConfig}
            loading={loading}
        >
            <ErrorDisplay error={state.error}/>

        </TemplateLibraryLayout>
	)
}
