// Hooks
import { useCallback } from "react"
import { useDispatch } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LinearProgress from "@material-ui/core/LinearProgress"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { changeArchetypeID } from "../../../redux/slices/umibuilder/reducers"

const useBuildingCount = ubemIoApi.endpoints.getBuildingCount.useQuery


export default ({secondaryMin, secondaryMax, archetypeID, primaryField, index, archetypeIDs}) => {
    const dispatch = useDispatch()
    const {buildingCounts, count} = useBuildingCount('', {selectFromResult: ({data }) => {
        const buildingCounts = data?.[primaryField]
        const count = buildingCounts?.[index]
        return {buildingCounts, count}
    }})

    const staleBuildingCounts = buildingCounts?.length !== archetypeIDs.length

    const updateID = useCallback((e) => {
        dispatch(changeArchetypeID({index, field: primaryField, archetypeID: e.target.value.replaceAll(" ", "_")}))

    }, [index, primaryField])

    // TODO: This should handle ALL ids, pull it in via a selector
    const error = archetypeIDs.filter(id => id === archetypeID).length > 1

    return (
        <>
            <Grid item xs={4}>
                <TextField 
                    value={archetypeID} 
                    onChange={updateID} 
                    fullWidth={true} 
                    error={error} 
                    helperText={error ? "Duplicate IDs not allowed" : "Enter an archetype ID"}
                />
            </Grid>
            <Grid item xs={2} justifyContent="center" container>
                <Typography variant="overline">{secondaryMin} - {secondaryMax}</Typography>
            </Grid>
            <Grid item xs={4} justifyContent="center" container>
                {staleBuildingCounts ? 
                    <LinearProgress />
                :
                    <Typography variant="overline">
                        {count}
                    </Typography>
                }
            </Grid>
        </>

    )
}