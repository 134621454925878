import { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { ObjectDataFieldReference } from "./ObjectDataFieldReference";
import { ObjectDataFieldForm } from "./ObjectDataFieldForm";

// This component generically handles data fields 
// which are not handled by a custom layout,
// and may be store primitives or references, and can 
// also handle creating multiple form elements for 
// keys which store arrays of primitives or objects
// They key/value may be any of:
//      <key>: <primitive>
//      or
//      <key>: Array<primitive> (e.g. hours in day schedule)
//      or
//      <key>: Array<{$ref: <ref>}> (e.g. days in week schedule)
//      or 
//      <key>: Array<{_key: primitive, _objectKey: {$ref: ref}, _otherKey: primitive, ...}>

export const ObjectDataField = (props) => {
    const {keyName, state, dispatch, gridSize} = props
    const _gridSize =  gridSize ? gridSize : 4 
    const {indexedNodes, selectedNode} = state
    const objectId = selectedNode
    //TODO: Yikes!  This should probably be some sort of recursive component
    // Additionally, arrays should probably be grouped more elegantly.
    const data = indexedNodes[objectId][keyName]
    const excludedKeys = useMemo(() => ["$id", "class", "Name"], []);

    const isNotExcludedKey = !excludedKeys.includes(keyName)
    const hasData = data !== null

    // Immediately invoked inline fn
    // which constructs the base element and gives it 
    // a key as it will be eventually rendered in an array.
    // let T = {reactKey: str, component: React.FC}
    // Then output shape is:
    // T | Array<T> | Array<Array<T>>

    const uiElements = (() => {
        if (isNotExcludedKey) {
            if (hasData) {
                if (typeof(data) === "object") {
                    // Handle arrays or objects
                    if (Array.isArray(data)) {
                        // Handle Arrays
                        if (data.length > 0) {
                            if (data.some(item => typeof(item) === "object")) {
                                // Handle arrays of objects
                                return data.map((item, j) => Object.entries(item).map(([_key, _value], i) => {
                                    // Handle each object
                                    const reactKey = `${ keyName }-subcontrol-${i}-${_key}`
                                    if (typeof (_value) === "object") {
                                        // Handle keys from each array object which themselves store an object (which should be just be a ref object)
                                        // e.g. how modified objects work like opaque constructions or year schedules: 
                                        // [{HighLoadRatio: <val>, NormalLoadRatio: <val>, Material: {$ref: <ref>}}, {HighLoadRatio: <val>, NormalLoadRatio: <val>, Material: {$ref: <ref>}}]
                                        return { 
                                            reactKey,
                                            component: <ObjectDataFieldReference
                                                key={`${_key}-${j}`}
                                                targetId={_value.$ref}
                                                keyName={keyName}
                                                index={j}
                                                parameter={_key}
                                                targetLabel={`${keyName}-${j}-${_key}`}
                                                {...props}
                                            />
                                         };
                                    } else {
                                        // Handle  keys which store a primitive,
                                        // e.g. how day schedules are stored in week schedudles: [{$ref: <ref>}, {$ref: <ref>}]
                                        // or modifier parameter parameters for nested refs e.g. opaque constructs [{Material: {$ref: <ref>}, Thickness: 0.003}]
                                        if (_key === "$ref") {
                                            return {
                                                reactKey,
                                                component: <ObjectDataFieldReference
                                                    key={`${keyName}-${_key}-${j}`}
                                                    targetId={_value}
                                                    keyName={keyName}
                                                    index={j}
                                                    targetLabel={`${keyName}-${j}`}
                                                    {...props}
                                                />
                                            } 
                                        } else {
                                            return {
                                                reactKey,
                                                component: <ObjectDataFieldForm 
                                                    key={`${_key}-${j}`} 
                                                    keyName={keyName} 
                                                    index={j} 
                                                    parameter={_key} 
                                                    {...props}
                                                />
                                            }
                                        }
                                    }
                                }
                                ))

                            } else {
                                // Handle primitive arrays
                                return data.map((item, j) => ({
                                        reactKey: `${keyName}-subcontrol-${j}`,
                                        component: <ObjectDataFieldForm 
                                            key={`${keyName}-${j}`} 
                                            keyName={`${keyName}`} 
                                            index={j} 
                                            {...props}
                                        />
                                    }))
                            }
                        } else {
                            // TODO: Handle Empty Arrays (this should be smarter...)
                            return {
                                reactKey: `${keyName}-sub-control-empty-array-0`,
                                component: <TextField disabled={true} label={keyName} value={"<empty array>"} />
                            }
                        }

                    } else {
                        // A field where the value is an object
                        // is always just holding a ref object
                        return {
                            reactKey: `${keyName}-reference-control`,
                            component: <ObjectDataFieldReference
                                keyName={keyName}
                                targetLabel={keyName}
                                targetId={data.$ref}
                                {...props}
                            /> 
                        }
                    }
                } else {
                    // Handle a simple key/primitive field
                    return {
                        reactKey: `${keyName}-primitive-control`,
                        component: <ObjectDataFieldForm 
                            keyName={keyName} 
                            {...props} 
                        />
                    }
                }
            } else {
                // Handle no data available
                return {
                    reactKey: `${keyName}-null-control`,
                    component: <ObjectDataFieldForm 
                        keyName={keyName} 
                        {...props} 
                    />
                }
            }

        } else {
            // Skip excluded keys
            return null
        }
    })()

    // Immediately invoked inline fn
    // which converts the base components into a flat array with React arr keys and components
    // output shape is Array<T>
    const flattenedUIElements = (() => {
        if (uiElements !== null) {
            if (Array.isArray(uiElements)) {
                return uiElements.reduce((a,b) => {
                    if (Array.isArray(b)) {
                        // Flatten an arry
                        return a.concat(b)
                    } else {
                        // Append an element
                        return [...a, b]
                    }

                }, [])
            } else {
                // uiElements is just a single component
                return [uiElements]
            }
        } else {
            return null
        }

    })()

    if (flattenedUIElements === null) return <></>

    return (
        <>
            {flattenedUIElements.map(({reactKey, component}) => (
                <Grid 
                    item 
                    key={reactKey} 
                    xs={_gridSize}
                >
                    {component}
                </Grid>
            ))}
        </>
    );
};
