// Hooks
import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react";

// MUI
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@mui/x-data-grid";

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem";
import { isLoading, selectContextLayers, selectSummaryData, selectUmiReady } from "../../../redux/slices/umibuilder/selectors"
import { AsyncTask } from "../../../redux/slices/async-processing/consts";
import { startAsyncTask } from "../../../redux/slices/async-processing/reducers";
import { useRisingEdgeEffect } from "../../../clientLib/hooks";

const { useLazyQuery: getUmiFile } = ubemIoApi.endpoints.getUmiFile

const columns = [
  {
    field: 'ArchetypeID',
    headerName: 'Archetype ID',
    flex: 1,
    isEditable: false,
  },
  {
    field: 'EnergySimulatorName',
    headerName: 'Simulator',
    flex: 1,
    isEditable: false,
  },
  {
    field: 'Height',
    headerName: 'Height',
    flex: 1,
    isEditable: false,
  },
  {
    field: 'TemplateName',
    headerName: 'Template',
    flex: 1,
    isEditable: false,
  },
  {
    field: 'WindowToWallRatioN',
    headerName: 'WWR',
    flex: 1,
    isEditable: false,
  },
];


export default () => {
    const dispatch = useDispatch()

    const contextLayers = useSelector(selectContextLayers)
    const {config, stats} = useSelector(selectSummaryData)
    const loading = useSelector(isLoading)
    const umiReady = useSelector(selectUmiReady)

    const [trigger] = getUmiFile()

    useRisingEdgeEffect({callback: trigger, condition: umiReady})

    const startUmiExport = useCallback(() => {
        dispatch(startAsyncTask({endpoint: AsyncTask.startUmiExport, body: contextLayers }))
    }, [dispatch, contextLayers])

	return (
        <>
            
            <div style={{ display: 'flex', flexDirection: "column", height: '100%', marginBottom: "2rem"}}>
                <Button disabled={loading} variant="contained" color="primary" width="100%" onClick={startUmiExport}>Build & Export UBEM</Button>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={config}
                        columns={columns}
                        pageSize={10}
                        autoHeight
                        density="compact"
                    />
                </div>
            </div>
            <Grid container item justifyContent="center" alignItems="center" direction="row" spacing={2} >
                {Object.entries(stats).map(([stat, value], i) => (
                    <Grid item key={`stat-display-${stat}-${i}`}>
                        <Card variant="outlined" >
                            <CardContent>
                                <Typography color="textSecondary" variant="overline">{stat}: </Typography>
                                <Typography color="primary" variant="overline">{value}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
	)
}