import Grid from "@material-ui/core/Grid"
import { useCallback, useMemo } from "react"
import { Selector } from "../../MultiSelector"
import fileDownload from "js-file-download"
import Countries from "./countries"

const getCountries = (lang = 'en') => {
    const A = 65
    const Z = 90
    const countryName = new Intl.DisplayNames([lang], { type: 'region' });
    const countries = {}
    for(let i=A; i<=Z; ++i) {
        for(let j=A; j<=Z; ++j) {
            let code = String.fromCharCode(i) + String.fromCharCode(j)
            let name = countryName.of(code)
            if (code !== name) {
                countries[code] = name
            }
        }
    }
    fileDownload(JSON.stringify(countries, null, 4), "countries.json")
    return countries
}
const optionsByKeyname = {
    ClimateZone: ["1A", "1B", "2A", "2B",  "3A", "3B", "3C", "4A", "4B", "4C", "5A",  "5B", "5C", "6A", "6B", "7A", "7B", "8A", "8B"].map(opt=>({label: opt, value: opt})),
    Country: Object.entries(Countries).map(([value,label])=>( {label, value} ))
}

export const MultiSelectControl = ({keyName, state, dispatch}) => {
    const {indexedNodes, selectedNode} = state
    const originalValuesArray = useMemo(() => indexedNodes[selectedNode][keyName], [])
    const valuesArray = useMemo(() => indexedNodes[selectedNode][keyName], [indexedNodes, selectedNode, indexedNodes[selectedNode][keyName]])
    const optionsArray = useMemo(() => {
        const combinedArray = optionsByKeyname[keyName].concat(originalValuesArray.map(value=>({label: value, value})))
        const dedupedArray = combinedArray.filter(({label, value}, i) => combinedArray.findIndex((obj => obj.label===label && obj.value === value)) === i)
        return [...dedupedArray]
    }, [keyName, originalValuesArray])

    const setActiveList = useCallback((list) => {
        const args ={
            type: "update simple field",
            key: keyName,
            value: list,
            objectId: selectedNode
        }
        dispatch(args)

    }, [dispatch, selectedNode, keyName])

	return (
        <Grid item xs={4}>
            <Selector activeList={valuesArray} setActiveList={setActiveList} options={optionsArray} name={keyName} descriptor={keyName} />

        </Grid>
	)
}