// Hooks
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

///// Umiverse Imports

// Hooks
import { useClearDataToast } from "../../../clientLib/hooks"
import { useNavigationEffect } from "../../../redux/slices/navigation/hooks"

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { mutationFixedCacheKeys } from "../../../redux/slices/upgrades/endpoints"
import { clearAllData } from "../../../redux/slices/upgrades/reducers"
import { selectTemplates } from "../../../redux/slices/upgrades/selectors"

// Components
import { RtkDropzone } from "../../RtkDropzone"

const { useMutation: uploadFileToUpgrade } = ubemIoApi.endpoints.uploadBaseUpgradingFile
const { useMutation: clearUserFiles } = ubemIoApi.endpoints.clearUserFiles

export default () => {

    const dispatch = useDispatch()
    const [triggerClearUserFiles, __] = clearUserFiles()
    const templates = useSelector(selectTemplates)

    useClearDataToast({data: templates})
    useNavigationEffect({destination: "assign", condition: templates})

    useEffect(() => {
        // triggerClearUserFiles()
        dispatch(clearAllData())
    }, [dispatch, triggerClearUserFiles])


    return (
        <RtkDropzone 
            mutation={uploadFileToUpgrade} 
            index={mutationFixedCacheKeys.uploadBaseUpgradingFile}
            accept="application/zip, application/x-zip-compressed, multipart/x-zip, .umi, application/json, .json"
            maxFiles={1}
            skipReset={true}
        />
    )
}