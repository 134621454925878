// Hooks
import { useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"
import FormHelperText from "@material-ui/core/FormHelperText"

///// Umiverse Imports

// Redux
import { KeyFieldsConfig } from "../../../redux/slices/umibuilder/consts"
import { setKeyFieldAssignment } from "../../../redux/slices/umibuilder/reducers"
import { makeSelectAvailableColumns, makeSelectKeyFieldAssignment, makeSelectKeyFieldErrorMessage } from "../../../redux/slices/umibuilder/selectors"


export default ({keyField}) => {
    const dispatch = useDispatch()
    const columnSelector = useMemo(() => makeSelectAvailableColumns(keyField), [keyField])
    const assignmentSelector = useMemo(() => makeSelectKeyFieldAssignment(keyField), [keyField])
    const errorSelector = useMemo(() => makeSelectKeyFieldErrorMessage(keyField), [keyField])
    const error = useSelector(errorSelector)
    const columns = useSelector(columnSelector)
    const value = useSelector(assignmentSelector)

    const handleChange = useCallback(async (e) => {
        dispatch(setKeyFieldAssignment({field: keyField, value: e.target.value}))
    }, [dispatch, keyField])

    const config = useMemo(() => KeyFieldsConfig[keyField] ,[keyField])


    // TODO:  add error state
    return (
        <Tooltip title={config.hover} enterDelay={750}>
            <FormControl fullWidth error={error ? true : false}>
                <InputLabel id="keyfield-selector-label" >
                    {config.label}
                </InputLabel>
                <Select
                    labelId="keyfield-selector-label"
                    id="keyfield-selector"
                    value={value ?? ''}
                    onChange={handleChange}
                >
                    {columns.map( (column, i)=> (
                        <MenuItem 
                            value={column} 
                            key={`keyfields-option-${column}`}>{column}</MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Tooltip>

    )
}