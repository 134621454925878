// Hooks
import { useSelector } from "react-redux"

// Recharts
import { ReferenceLine } from "recharts"

///// Umiverse Imports

// Redux
import { selectMetric } from "../../../redux/slices/analysis/selectors"

export default (props) => {
    const { showTargets, baseline: { total }} = props
    const targets = useSelector(state => state.analysis.targets)
    const metric = useSelector(selectMetric)

    // bail out 
    if (metric !== targets.metric) return <></>

    return showTargets && (
        <>
            {Object.values(targets).map( ({value, year}) => (
                <ReferenceLine 
                    key={`reference-line-${year}-x`}
                    stroke="green"
                    strokeDasharray="3 3"
                    // label={{
                    //     value: `${year} Target` ,
                    //     position: "insideTopRight",
                    // }}
                    segment={[{x: year, y: 0}, {x: year, y: Math.round(value/100*total)}] }
                    {...props}
                />
            ))}
            {Object.values(targets).map( ({value, year}) => (
                <ReferenceLine 
                    key={`reference-line-${year}-y`}
                    stroke="green"
                    strokeDasharray="3 3"
                    label={{
                        value: `${year} Target` ,
                        position: "insideTopRight",
                    }}
                    y={Math.round(value/100*total)}
                    {...props}
                />
            ))}

        </>
    )
}