// Hooks
import React from "react";
import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Recharts
import {
    XAxis,
    YAxis,
    Area,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    Legend,
    ResponsiveContainer,
    ComposedChart,
    Line
} from "recharts";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";

///// Umiverse Imports

// Lib Hooks

// Redux
import { ubemIoApi } from "../../../redux/services/ubem";
import { useTabNavigation } from "../../../redux/slices/navigation/hooks";
import { updateSettingsPageTab } from "../../../redux/slices/analysis/reducers";
import {
    selectBreakdown,
    selectMetric,
    selectNormalized,
    selectTimeSeriesData,
    selectScenarioNames,
    selectScenario
} from "../../../redux/slices/analysis/selectors";
import {
    MetricConfig,
    BreakdownConfig,
    Breakdown,
    colors,
    SettingsPage
} from "../../../redux/slices/analysis/consts";

// Components
import TargetLines from "./TargetLines";
import ChartSidebar from "./ChartSidebar";
import { useSvgAndPng } from "../../../clientLib/hooks";

const { useLazyQuery: useExportVizData } = ubemIoApi.endpoints.exportVizData;

export default () => {
    const dispatch = useDispatch();
    const { ref, exportPng, exportSvg } = useSvgAndPng({
        title: "ubem-data-visualization"
    });
    const [exportVizDataTrigger, results] = useExportVizData();
    const exportData = useCallback(() => exportVizDataTrigger(), [
        exportVizDataTrigger
    ]);
    const { goToTab } = useTabNavigation();
    const selectedScenario = useSelector(selectScenario);

    return (
        <Fade timeout={1000} in={true}>
            <Box>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs>
                        <AreaChartWithPng ref={ref} />
                    </Grid>
                    <Grid item xs={3}>
                        <ChartSidebar
                            pngCallback={exportPng}
                            svgCallback={exportSvg}
                            exportData={exportData}
                            usesScenarioSelect={true}
                            usesAllSelect={true}
                            excludeEnergy={true}
                        >
                            {/* children */}
                        </ChartSidebar>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            onClick={() => {
                                dispatch(
                                    updateSettingsPageTab(
                                        SettingsPage.TimeSeries
                                    )
                                );
                                goToTab("settings");
                            }}
                            variant="outlined"
                        >
                            Edit Time Series Settings
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                dispatch(
                                    updateSettingsPageTab(selectedScenario)
                                );
                                goToTab("settings");
                            }}
                            variant="outlined"
                        >
                            Edit Scenario Settings
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                dispatch(
                                    updateSettingsPageTab(SettingsPage.Targets)
                                );
                                goToTab("settings");
                            }}
                            variant="outlined"
                        >
                            Edit Targets
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );
};

const AreaChartWithPng = React.forwardRef((props, ref) => {
    const breakdown = useSelector(selectBreakdown);
    const metric = useSelector(selectMetric);
    const normalized = useSelector(selectNormalized);
    const { results: data, keys, baseline } = useSelector(selectTimeSeriesData);
    const selectedScenario = useSelector(selectScenario);
    const allAreSelected = selectedScenario === null;

    const config = {
        colorPicker: allAreSelected
            ? (_, i) => colors[i + 5]
            : BreakdownConfig[breakdown].colorPicker,
        dataKeys: keys,
        yAxisLabel: `${MetricConfig[metric].label}: ${
            MetricConfig[metric].units
        }${normalized ? " / m^2" : ""} / yr`,
        stackId: "1"
    };

    const chartProps = {
        ref,
        width: 800,
        height: 800,
        data,
        margin: {
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
        }
    };

    return (
        <Box height={"55vh"}>
            <ResponsiveContainer width={"99%"} height={"99%"}>
                <ComposedChart {...chartProps}>
                    <CartesianGrid />
                    <XAxis dataKey="Year" />
                    <YAxis
                        label={{
                            value: config.yAxisLabel,
                            angle: -90,
                            position: "insideBottomLeft",
                            textAnchor: "middle"
                        }}
                        width={100}
                        tickFormatter={val =>
                            new Intl.NumberFormat("en").format(val)
                        }
                    />
                    {!(
                        (breakdown == Breakdown.Archetype && normalized) ||
                        allAreSelected
                    ) &&
                        config?.dataKeys?.map((key, i) => (
                            <Area
                                key={`area-${key}-${i}`}
                                dataKey={key}
                                stackId={config.stackId}
                                stackOffset={0}
                                stroke={config.colorPicker(key, i)}
                                fill={config.colorPicker(key, i)}
                            />
                        ))}
                    {((breakdown === Breakdown.Archetype && normalized) ||
                        allAreSelected) &&
                        config?.dataKeys?.map((key, i) => (
                            <Line
                                key={`line-${key}-${i}`}
                                dataKey={key}
                                stroke={config.colorPicker(key, i)}
                                dot={false}
                                strokeWidth={4}
                            />
                        ))}
                    {TargetLines({ showTargets: true, baseline })}
                    <RechartsTooltip
                        cursor={false}
                        formatter={
                            normalized
                                ? val =>
                                      `${MetricConfig[metric].formatter(
                                          val
                                      )}/m2`
                                : MetricConfig[metric].formatter
                        }
                    />
                    <Legend verticalAlign="top" height={36} />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
});
