// Hooks
import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

// MUI
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

///// Umiverse Imports

// Redux
import { selectSortedScenarioNames, selectScenario, } from "../../../redux/slices/analysis/selectors"
import { setSelectedScenario } from "../../../redux/slices/analysis/reducers"

export default ({action, selector, label, helperText, error, usesAllSelect}) => {
    const dispatch = useDispatch()
    const scenarios = useSelector(selectSortedScenarioNames)
    const options = scenarios.map( (scenario, i)=> ({label: scenario, value: scenario}))
    const value = useSelector(selector ? selector : selectScenario) // default to selecting the active scenario

    const handleChange = useCallback((e) => {
        // default to selecting the active scenario
        dispatch(action ? action(e.target.value) : setSelectedScenario(e.target.value))

    }, [dispatch, action])

    const helper = helperText ? helperText : "Select a scenario to analyze"

    return (
        <FormControl fullWidth error={error} size="small">
            <InputLabel id="scenario-selector-label" >
                {label ? label : "Scenario"}
            </InputLabel>

            <Select
                labelId="scenario-filter-label"
                id="scenario-filter"
                value={value ? value : ''}
                onChange={handleChange}
            >
                {options.map(({value, label: _label}) => (
                    <MenuItem 
                        value={value} 
                        key={`scenario-option-${value}`}>{_label}</MenuItem>
                ))}
                {usesAllSelect && <MenuItem value={null}>All Scenarios</MenuItem>  }

            </Select>
            <FormHelperText>{error ? error : helper}</FormHelperText>
        </FormControl>

    )
}