import { createSlice, current} from '@reduxjs/toolkit'
import { Workflow } from '../../consts'
import { AsyncTask } from '../async-processing/consts'
import { addAsyncExtraReducers } from '../async-processing/utils'
import { addErrorExtraReducers } from '../error/utils'

const workflow = Workflow.upgrades

const initialState = {
    extension: null,
	templates: null,
    measureContext: null,
    templateSelection: null,

    activeFilters: {},

    // Other
    isProcessing: false,
    
    error: null,
    errors: {},
}

const upgradesSlice = createSlice({
    name: workflow,
    initialState,
    reducers: {
        loadData: (state, {payload}) => {
            const {file_extension, names} = payload
            state.extension = file_extension
            state.templates = {}
            names.forEach(name => state.templates[name] = {})
        },
        addMeasureToTemplate: (state, {payload}) => {
            const {template, measure} = payload
            state.templates[template][measure["Measure.Name"]] = measure
        },
        removeMeasureFromTemplate: (state, {payload}) => {
            delete state.templates?.[payload.templateName]?.[payload.measureName]
        },
        setMeasureContext: (state, { payload }) => {
            state.measureContext = payload
        },
        updateMeasureContext: (state, {payload}) => {
            state.measureContext.Name = "User-defined"
            const { config, Value } = payload
            const { "Prop.Name": PropName, Prop } = config
            const current_preset = state.measureContext.preset_values[PropName] ?? {}
            state.measureContext.preset_values[PropName] = { ...current_preset, Value, Prop, "Prop.Prop.Name": PropName }
        },
        setTemplateSelection: (state, { payload }) => {
            state.templateSelection = payload
        },
        setActiveFilter: (state, {payload}) => {
            const {prop, value} = payload
            if (value) {
                state.activeFilters[prop] = value
            } else if (state.activeFilters[prop]) {
                delete state.activeFilters[prop]
            }
        },
        clearFilters: (state) => {
            state.activeFilters = {}
        },
        clearAllData: (state) => {
            state.activeFilters = {}
            state.templates = null
            state.templateSelection = null
            state.measureContext = null
            state.extension = null
        }
    },
    extraReducers: (builder) => {
        addErrorExtraReducers(builder, { workflow })
        addAsyncExtraReducers(builder, { workflow, handlers })
    }
})

// Async Action Handlers
const handlers = {
//     [AsyncTask.processBaseGis]: {
//         onStart: handleClearBaseGisData,
//         onSuccess: handleProcessedGis
//     },
}


// Action creators are generated for each case reducer function
export const {
    loadData,
    addMeasureToTemplate,
    removeMeasureFromTemplate,
    setMeasureContext,
    setTemplateSelection,
    setActiveFilter,
    updateMeasureContext,
    clearAllData,
} = upgradesSlice.actions

export default upgradesSlice.reducer

