// Hooks
import { useEffect } from "react"
import { useSelector } from "react-redux"

// MUI
import Grid from "@material-ui/core/Grid"
import  Button  from "@material-ui/core/Button"

///// Umiverse Imports

// Redux
import { ubemIoApi } from "../../../redux/services/ubem"
import { selectGroupData } from "../../../redux/slices/umibuilder/selectors"
import { useTabNavigation } from "../../../redux/slices/navigation/hooks"
import { mutationFixedCacheKeys } from "../../../redux/slices/umibuilder/endpoints"

// Components
import PrimaryFieldGroup from "../components/PrimaryFieldGroup"

const { useMutation: mutateOrganizingData } = ubemIoApi.endpoints.sendGroupData


export default () => {
    const { goToTab } = useTabNavigation()

    const [trigger, {isLoading: isSendingGroupData}] = mutateOrganizingData({fixedCacheKey: mutationFixedCacheKeys.sendGroupData})
    const allGroups = useSelector(selectGroupData)

    useEffect(() => {
       trigger({groups: allGroups}) 
    }, [allGroups])


	return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3} direction="column">
            <Button disabled={isSendingGroupData} onClick={() => goToTab("assign")} variant="contained" color="primary">Confirm</Button>
            {allGroups.map((primaryGroup) => (
                <PrimaryFieldGroup primaryGroup={primaryGroup} key={primaryGroup.field} />
            ))}

        </Grid>
    )
}