import { skipToken } from "@reduxjs/toolkit/query/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ubemIoApi } from "../redux/services/ubem"

const { useQuery: useAsyncStatus } = ubemIoApi.endpoints.checkAsyncStatus
const { useMutation: useAsyncTrigger} = ubemIoApi.endpoints.startAsyncTask

export default ({endpoint}) => {
    const dispatch = useDispatch()

    const initiator = useSelector(state => state.asyncTasks.initiator)
    const body = useSelector(state => state.asyncTasks[`${endpoint}Config`]?.body)
    const method = useSelector(state => state.asyncTasks[`${endpoint}Config`]?.method)
    const cachedData = useSelector(state => state.asyncTasks[endpoint])

    const [trigger, {data: asyncMetadata, isLoading, isError, isSuccess, reset}] = useAsyncTrigger({fixedCacheKey: endpoint})

    const queryArg = asyncMetadata?.taskLocation && !cachedData ? {
        url: asyncMetadata.taskLocation,
        endpoint
    } : skipToken

    const {data, isLoading: isAsyncLoading, isError: isAsyncError} = useAsyncStatus(queryArg, {pollingInterval: 1000})

    useEffect(() => {
        if (isAsyncError) {
            reset()
        }

    }, [reset, isAsyncError])

    useEffect(() => {
        if (initiator === endpoint) {
            trigger({body, method, endpoint})
        }
    }, [body, method, endpoint, initiator, dispatch, trigger])

    return (
        <></>
    )
}