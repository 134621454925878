// Hooks
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";

// MUI
import TextField from "@material-ui/core/TextField";

///// Umiverse Imports

// Redux
import { setScenarioName } from "../../../redux/slices/analysis/reducers";
import { getNameSelectorByIndex, vizScenarioNamesHaveDuplicates } from "../../../redux/slices/analysis/selectors";

export default ({ index }) => {
    const dispatch = useDispatch();
    const handleNameChange = useCallback((e) => {
        dispatch(setScenarioName({ index, name: e.target.value }));
    }, [index]);

    const name = useSelector(getNameSelectorByIndex(index));
    const duplicatesExist = useSelector(vizScenarioNamesHaveDuplicates)
    const hasData = useSelector(state => state.analysis.data !== null)
    const helperText = (
    duplicatesExist ?
        "Names must be unique"
    : 
    hasData ?
        "Upload a file to unlock"
    :
        "Scenario name"
    )

    return (
        <TextField
            onChange={handleNameChange}
            value={name}
            variant="outlined"
            size="small"
            helperText={helperText}
            error={duplicatesExist}
            disabled={hasData}
        />
    );
};
