// Table to store props which can be used to construct a Measure panel


export const MeasuresLib = {
    SetCOP: {
        label: "Set HVAC CoP",
        custom: null,
        presets: [
            {
                label: "Base",
                props: {
                    cooling_cop: 1,
                    heating_cop: 0.95,
                }
            },
            {
                label: "ASHP",
                props: {
                    cooling_cop: 2,
                    heating_cop: 2
                }
            },
            {
                label: "GSHP",
                props: {
                    cooling_cop: 3.5,
                    heating_cop: 3.5
                }
            }
        ],
        props: {
            cooling_cop: {
                type: "number",
                min: 0.1,
                max: 5.0,
                step: 0.01,
                defaultValue: 1.0,
                label: "Cooling COP",
                uiType: "slider",
                // marks: [ 
                //     {
                //         label: "ASHP",
                //         value: 2
                //     },
                //     {
                //         label: "GSHP",
                //         value: 4
                //     },
                // ]
            },
            heating_cop: {
                type: "number",
                min: 0.1,
                max: 5.0,
                step: 0.01,
                defaultValue: 4.0,
                label: "Heating COP",
                uiType: "slider"
            }
        }
    },
    SetMechanicalVentilation: {
        label: "Set Mechanical Ventilation",
        custom: null,
        presets: [],
        props: {
            ventilation_ach: {
                type: "number",
                min: 0.1,
                max: 12,
                step: 0.01,
                defaultValue: 1.0,
                label: "Ventilation ACH",
                uiType: "slider",
            },
        }
    },
    EnergyStarUpgrade: {
        label: "Set Loads",
        presets: [
            {
                label: "Baseline Office",
                props: {
                    equipment_power_density: 6,
                    lighting_power_density: 18,
                }
            },
            {
                label: "Baseline Residential",
                props: {
                    equipment_power_density: 5,
                    lighting_power_density: 7,
                }
            },
            {
                label: "High Performance Office",
                props: {
                    equipment_power_density: 2,
                    lighting_power_density: 8,
                }
            },
            {
                label: "High Performance Residential",
                props: {
                    equipment_power_density: 3,
                    lighting_power_density: 3,
                }
            },
        ],
        custom: null,
        props: {
            lighting_power_density: {
                type: "number",
                min: 0.1,
                max: 20,
                step: 0.01,
                defaultValue: 3.0,
                label: "Lighting Power Density",
                uiType: "slider",
                // format: (val) => `${val} kWh/m^2`
            },
            equipment_power_density: {
                type: "number",
                min: 0.1,
                max: 20,
                step: 0.01,
                defaultValue: 8.0,
                label: "Equipment Power Density",
                uiType: "slider",
                // format: (val) => `${val} kWh/m^2`
            }
        }
    },
    SetInfiltration: {
        label: "Set Infiltration",
        presets: [
            {
                label: "Regular",
                props: {
                    infiltration_ach: 0.6,
                }
            },
            {
                label: "Medium",
                props: {
                    infiltration_ach: 0.3,
                }
            },
            {
                label: "Tight",
                props: {
                    infiltration_ach: 0.1,
                }
            },
        ],
        custom: null,
        props: {
            infiltration_ach: {
                type: "number",
                min: 0.1,
                max: 12,
                step: 0.01,
                defaultValue: 0.8,
                label: "Infiltration ACH",
                uiType: "slider",
            },
        }
    },
    SetFacadeConstructionThermalResistanceToEnergyStar: {
        label: "Set Insulation",
        presets: [
            {
                label: "Low",
                props: {
                    rsi_value_facade: 1 / 3.5,
                    rsi_value_roof: 1 / 4.5,
                }
            },
            {
                label: "Regular",
                props: {
                    rsi_value_facade: 1 / 1.66,
                    rsi_value_roof: 1 / 2.37,
                }
            },
            {
                label: "Mid",
                props: {
                    rsi_value_facade: 1 / 0.34,
                    rsi_value_roof: 1 / 0.33,
                }
            },
            {
                label: "Best",
                props: {
                    rsi_value_facade: 1 / 0.13,
                    rsi_value_roof: 1 / 0.11,
                }
            },
        ],
        custom: null,
        props: {
            rsi_value_facade: {
                type: "number",
                min: 0.1,
                max: 20,
                step: 0.01,
                defaultValue: 3.08,
                label: "Facade RSI",
                uiType: "slider",
            },
            rsi_value_roof: {
                type: "number",
                min: 0.1,
                max: 20,
                step: 0.01,
                defaultValue: 5.02,
                label: "Roof RSI",
                uiType: "slider",
            },
        }
    }
};
