import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button"
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import Slide from '@material-ui/core/Slide';
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link"

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    marginBottom: "2rem"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({help, helpLinks, asIcon}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    {asIcon && (help || helpLinks) && (
        <IconButton onClick={handleClickOpen} style={{right: "1rem", position: "absolute"}}>
            <HelpIcon color="inherit" /> 
        </IconButton>
    )}
    {!asIcon && (help || helpLinks) && (
        <Button style={{marginTop: "2rem",  right: "1rem", position: "absolute"}} variant="contained" onClick={handleClickOpen} endIcon={<HelpIcon color="inherit"/>}>
            Help
        </Button >
    )}
    <Dialog fullScreen open={open} onClose={handleClose} >
        <AppBar className={classes.appBar}>
        <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                Help
            </Typography>
        </Toolbar>
        </AppBar>
        {!(help || helpLinks) && <CircularProgress />}
        {help && help?.map( ({text, heading}, i)=>(
            <Container key={`${heading}-${i}`}>
                <Typography variant="h5">
                    {heading}
                </Typography>
                <Typography variant="body2">
                    {text}
                </Typography>
                <Divider style={{margin: "1rem 0"}}/>
            </Container>
        ) )}
          {helpLinks?.length > 0 && (
            <Container>
              <Typography variant="h5">
                Additional Information
              </Typography>
              {helpLinks?.map(({Link: link, Title}, i) => (
                <Typography key={`${link}-${i}-${Title}`}>
                  <Link href={link}>{Title}</Link>
                </Typography>
              ))}
            </Container>
          )}
    </Dialog>
    </div>
  );
}

