// Redux
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

// General Reducers
import { ubemIoApi } from './services/ubem'
import asyncTasksReducer from './slices/async-processing/reducers'
import completionReducer from './slices/completion/reducers'
import errorReducer from './slices/error/reducers'
import navigationReducer from './slices/navigation/reducers'

// Workflow Reducers
import analysisReducer from './slices/analysis/reducers'
import umibuilderReducer from './slices/umibuilder/reducers'
import upgradesReducer from './slices/upgrades/reducers'

export const store = configureStore({
    reducer: {
        [ubemIoApi.reducerPath]: ubemIoApi.reducer,
        asyncTasks: asyncTasksReducer,
        completion: completionReducer,
        error: errorReducer,
        navigation: navigationReducer,
        analysis: analysisReducer,
        umibuilder: umibuilderReducer,
        upgrades: upgradesReducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(ubemIoApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)