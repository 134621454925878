import { useCallback, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

export const ScheduleSliders = ({ state, dispatch }) => {
    const {selectedNode, indexedNodes} = state
    const valuesArray = useMemo(() => indexedNodes[selectedNode].Values, 
        [indexedNodes,selectedNode, indexedNodes[selectedNode].Values]);
    // const valuesarray = []

    const handleChange = useCallback((index, value) => {
        console.log("dispatching")
        const args = {
            objectId: selectedNode,
            key: "Values",
            index,
            value,
            type: "update index of field",
        }
        dispatch(args)

    }, [selectedNode, indexedNodes, indexedNodes[selectedNode].Values, dispatch])

    const handlers = valuesArray.map((value,ix)=>((event,val)=>handleChange(ix,val)))

    return (
        <Grid item xs={12}>

            	    <Grid container>
            {valuesArray.map((value,i) => 

                    <Grid item container xs={1} key={`schedule-slider-${i}`} >
                        <Grid item xs={12}>

                            <Slider 
                                onChange={handlers[i]} 
                                valueLabelDisplay="auto" 
                                style={{
                                    height: "14vh", 
                                    marginTop: "1rem"
                                }} 
                                id={`${i}`}
                                min={0} 
                                max={1} 
                                step={0.01} 
                                size="small" 
                                orientation="vertical" 
                                value={value}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">{i}h</Typography>
                        </Grid>
                    </Grid>
            )}
                </Grid>
        </Grid>

    );
};
