import axios from "axios"
import { useCallback } from "react"
import { MuiDropzone } from "../../MuiDropzone"

export const UploadLibraryView = ({state, dispatch, setSelectedTab}) => {
    const setIsUploading = useCallback((value) => {
        const args = {
            type: "begin upload"
        }
        if (value) dispatch(args)

    }, [dispatch])

    const handleUploadSuccess = useCallback(async (uploadRes) => {
        const args = {
            type: "set input file type",
            inputFileType: uploadRes.data.file_extension
        }
        dispatch(args)
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/upgrader/build_template_library_graph`, {withCredentials: true})
            const args = {
                type: "initialize",
                originalData: data
            }
            dispatch(args)
            setSelectedTab("GraphEditor")
        } catch (error) {
            const args = [
                {
                    type: "error",
                    error,
                }, 
                {
                    type: "set isUploading",
                    isUploading: false
                }
            ]
            dispatch(args)

        }
    }, [dispatch, setSelectedTab])

    const setError = useCallback((error) => {
        const args = [
            {
                type: "error",
                error,
            }, 
            {
                type: "set isUploading",
                isUploading: false
            }
        ]
        dispatch(args)

    }, [dispatch])

	return (
        <MuiDropzone 
            setIsUploading={setIsUploading} 
            errorCb={setError} 
            uploadSuccessCb={handleUploadSuccess} 
            target="upgrader/upload_initial_file"
            accept="application/zip, application/x-zip-compressed, multipart/x-zip, .umi, application/json, .json"
            maxFiles={1}
            clearUserFiles={true}
        />
	)
}