import toast from "react-hot-toast";
import { Workflow } from "../../consts";
import { fileHandler } from "../../services/utils";
import { setCompletedWorkflow } from "../completion/reducers";
import { setError } from "../error/reducers";
import { Entrypoint } from "./consts";
import {
    clearAllData,
    clearUserTemplateAssignments,
    loadGroupData,
    setAllKeyFields
} from "./reducers";
import { buildLegacyKeyFields, transformTemplateLib } from "./utils";

const workflow = Workflow.umibuilder;
const toastOptions = (endpoint, additional) => ({
    id: endpoint,
    position: "bottom-center",
    ...additional,
    style: { minWidth: "30%" }
});

export const mutationFixedCacheKeys = {
    exportExchangeFile: "exportExchangeFile",
    exportKeyFieldsErrorReport: "exportKeyFieldsErrorReport",
    exportReferenceLibrary: "exportReferenceLibrary",
    confirmKeyFields: "confirmKeyFields",
    sendGroupData: "sendGroupData",
    uploadUserTemplateLib: "uploadUserTemplateLib"
};

export const umibuilderTags = ["keyFields", "tempGroupData"];

export const createUmibuilderEndpoints = builder => ({
    uploadBaseGisFile: builder.mutation({
        query: ({ body }) => ({
            url: "api/umibuilder/upload",
            method: "POST",
            body
        }),
        onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
            try {
                dispatch(clearAllData());
                const { data } = await queryFulfilled;
                if (getState().umibuilder.entrypoint == Entrypoint.uio) {
                    dispatch(setAllKeyFields(data));
                }
            } catch ({ error }) {
                dispatch(
                    setError({ error, workflow, key: "uploadBaseGisFile" })
                );
            }
        }
    }),
    // TODO: abstract standardized file downloader into its own uniform endpoint which just takes a query arg
    exportExchangeFile: builder.mutation({
        query: ({ assignments, useLegacyNaming }) => {
            const body = useLegacyNaming
                ? buildLegacyKeyFields(assignments)
                : assignments;
            return {
                url: "api/umibuilder/export_exchange_file",
                method: "POST",
                body,
                responseHandler: fileHandler
            };
        },
        onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
            try {
                const res = await queryFulfilled;
                dispatch(
                    setCompletedWorkflow({
                        context: getState().umibuilder.entrypoint,
                        workflow
                    })
                );
            } catch ({ error }) {
                dispatch(
                    setError({
                        error,
                        workflow,
                        key: mutationFixedCacheKeys.exportExchangeFile
                    })
                );
            }
        }
    }),
    exportKeyFieldsErrorReport: builder.mutation({
        query: ({ geoJSON }) => ({
            url: "api/umibuilder/merge_errors",
            method: "POST",
            body: geoJSON,
            responseHandler: fileHandler
        }),
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
            try {
                const res = await queryFulfilled;
                toast.success("Donwloaded error report.");
            } catch ({ error }) {
                dispatch(
                    setError({
                        error,
                        workflow,
                        key: mutationFixedCacheKeys.exportKeyFieldsErrorReport
                    })
                );
            }
        }
    }),
    confirmKeyFields: builder.mutation({
        query: ({ keyFields }) => ({
            url: "api/umibuilder/create_group_data",
            method: "POST",
            body: keyFields
        }),
        invalidatesTags: ["keyFields"],
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
            try {
                const { data } = await queryFulfilled;
                dispatch(loadGroupData(data));
            } catch ({ error }) {
                dispatch(
                    setError({
                        error,
                        workflow,
                        key: mutationFixedCacheKeys.confirmKeyFields
                    })
                );
            }
        }
    }),
    // TODO: make these group level for less rendering?
    sendGroupData: builder.mutation({
        query: ({ groups }) => ({
            url: "api/umibuilder/send_group_data",
            method: "POST",
            body: groups
        }),
        invalidatesTags: ["tempGroupData"],
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
            try {
                const { data } = await queryFulfilled;
            } catch ({ error }) {
                dispatch(
                    setError({
                        error,
                        workflow,
                        key: mutationFixedCacheKeys.sendGroupData
                    })
                );
            }
        }
    }),
    getBuildingCount: builder.query({
        query: () => "api/umibuilder/get_building_count",
        providesTags: ["tempGroupData"],
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
            try {
                const { data } = await queryFulfilled;
            } catch ({ error }) {
                dispatch(
                    setError({ error, workflow, key: "getBuildingCount" })
                );
            }
        }
    }),
    uploadUserTemplateLibUmibuilder: builder.mutation({
        query: ({ body }) => ({
            url: "api/umibuilder/upload_template_lib",
            body,
            method: "POST"
        }),
        transformResponse: library =>
            transformTemplateLib({ library, isUser: true }),
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
            try {
                const { data } = await queryFulfilled;
                dispatch(clearUserTemplateAssignments());
            } catch ({ error }) {
                dispatch(
                    setError({
                        error,
                        workflow: "all",
                        key: mutationFixedCacheKeys.uploadUserTemplateLib
                    })
                );
            }
        }
    }),
    getUmiFile: builder.query({
        query: () => ({
            url: "api/umibuilder/get_umi_export",
            responseHandler: fileHandler
        }),
        onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
            try {
                const res = await queryFulfilled;
                dispatch(
                    setCompletedWorkflow({
                        context: getState().umibuilder.entrypoint,
                        workflow
                    })
                );
            } catch ({ error }) {
                dispatch(setError({ error, workflow, key: "getUmiFile" }));
            }
        }
    }),
    getCZFromLatLon: builder.query({
        query: ({ lat, lon }) => ({
            url: `api/umibuilder/get_climate_zone?Lat=${lat}&Lon=${lon}`,
            method: "GET"
        }),
        onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
            try {
                const res = await queryFulfilled;
            } catch ({ error }) {
                dispatch(setError({ error, workflow, key: "getCZFromLatLon" }));
            }
        }
    }),
    exportReferenceLibrary: builder.mutation({
        query: ({ templates, user }) => {
            return {
                url: "api/umibuilder/build_template_library",
                method: "POST",
                body: { templates, user },
                responseHandler: fileHandler
            };
        },
        onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
            try {
                toast.loading(
                    "Exporting template library...",
                    toastOptions("exportReferenceLibrary")
                );
                const res = await queryFulfilled;
                toast.success(
                    "Template library exported!",
                    toastOptions("exportReferenceLibrary")
                );
            } catch ({ error }) {
                dispatch(
                    setError({
                        error,
                        workflow,
                        key: mutationFixedCacheKeys.exportReferenceLibrary
                    })
                );
                toast.error(
                    "Error exporting template library",
                    toastOptions("exportReferenceLibrary")
                );
            }
        }
    })
});
