import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { useMemo, useCallback } from "react"
import { nameMapping } from "../EditTemplateLibrary"


export const ObjectList = ({state, dispatch}) => {
    // Browse and select objects by Class
    const {selectedClass, indexedNodes} = state
    const objectIds = useMemo(() => Object.keys(indexedNodes).filter(id=>indexedNodes[id].class === selectedClass), [selectedClass, indexedNodes])
    const objects = useMemo(() => objectIds.map(id=>indexedNodes[id]), [indexedNodes, objectIds])

    const goToObject = useCallback((id) => {
        const args = {
            type: "select node",
            id
        }
        dispatch(args)
    }, [dispatch])

    const handlers = useMemo(()=>objectIds.map((id)=>()=>goToObject(id)), [goToObject, objectIds])

    const handleClassChange = useCallback((event) => {
        const args = {
            type: "select class",
            class: event.target.value
        }
        dispatch(args)
    }, [dispatch])


    return (
        <Grid item xs={6}>
            <FormControl fullWidth >
                <InputLabel id="cateogry-selector-label">Category</InputLabel>
                <Select
                    labelId="cateogry-selector-label"
                    id="category-selector"
                    value={selectedClass}
                    onChange={handleClassChange}
                >
                    {Object.entries(nameMapping).map(([className, displayName])=> <MenuItem key={`class-object-selector-menu-${className}`} value={className}>{displayName}</MenuItem>)}
                </Select>
            </FormControl>
            {objects.map(({Name}, i) => (
                <Button key={`${Name}-${i}`} onClick={handlers[i]} size="small" variant="contained" style={{margin: "0.5rem 0.25rem"}}>{Name}</Button>
            ))}

        </Grid>
    )
}