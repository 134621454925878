import React, {Component} from 'react';
import './App.css';
import {
    BrowserRouter as Router, Redirect, Route, Switch,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';


// Workflows
import AnalysisWorkflow from "./components/Analysis/AnalysisWorkflow"
import UmiBuilderWorkflow from './components/UmiBuilder/UmiBuilderWorkflow';
import UpgradesWorkflow from './components/Upgrades/UpgradesWorkflow';

// Template editor workflows
import MergeTemplateLibraries from './components/MainPages/MergeTemplateLibraries';
import UpgradeTemplateLibrary from './components/TemplateLibraryWorkflows/UpgradeTemplateLibrary';
import EditTemplateLibrary from './components/TemplateLibraryWorkflows/EditTemplateLibrary';

// Modals
import AsyncTaskAutomator from "./components//AsyncTaskAutomator";
import ErrorDisplayAutomator from './components/ErrorDisplayAutomator';
import WorkflowCompletionModal from './components/WorkflowCompletionModal';

// Consts
import { AsyncTasks } from "./redux/slices/async-processing/consts";

// Error Display


// Lib Hooks
import {useIsMainWindow} from './clientLib/hooks';

const App = () => {
    const _isMainWindow = useIsMainWindow()
    const isMainWindow = true

    return (
        <Router>
            <div>
                <Toaster />
                <ErrorDisplayAutomator />
                <WorkflowCompletionModal />
                {AsyncTasks.map( (task, i) => (
                    <AsyncTaskAutomator endpoint={task} key={`${task}-${i}`} />
                ))}
                {isMainWindow === false && (

                    <Switch>
                        <Route path="/">
                            <div>This is the home page</div>
                        </Route>
                    </Switch>
                )}
                {isMainWindow && (
                    <Switch>
                        <Route path="/mergeTemplateLibraries" >
                            <MergeTemplateLibraries />
                        </Route>
                        <Route path="/upgradeTemplateLibrary-old" >
                            <UpgradeTemplateLibrary/>
                        </Route>
                        <Route path="/editTemplateLibrary" >
                            <EditTemplateLibrary/>
                        </Route>

                        
                        <Route path="/analysis">
                            <AnalysisWorkflow />
                        </Route>
                        <Route path="/umibuilder">
                            <UmiBuilderWorkflow />
                        </Route>
                        <Route path="/upgrades">
                            <UpgradesWorkflow />
                        </Route>


                        <Route path='/about' component={() => { 
                            window.location.href = 'http://ubem.io/people'; 
                            return null;
                        }}/>
                        <Route path='/resources' component={() => { 
                            window.location.href = 'http://ubem.io'; 
                            return null;
                        }}/>
                        <Route path='/tutorials' component={() => { 
                            window.location.href = 'https://www.ubem.io/existing-buildings-workflow-overview-1'; 
                            return null;
                        }}/>
                        <Route path='/getstarted' component={() => { 
                            window.location.href = 'https://www.ubem.io/existing-buildings-workflow-overview-1'; 
                            return null;
                        }}/>
                        <Route path='/umi' component={() => { 
                            window.location.href = 'https://web.mit.edu/sustainabledesignlab/projects/umi/index.html'; 
                            return null;
                        }}/>
                        <Route path='/uploadcitygml' component={() => { 
                            window.location.href = 'http://ubem.io'; 
                            return null;
                        }}/>
                        <Redirect from="/templates" to="/upgradetemplatelibrary" />
                        <Redirect from="/upgradetemplatelibrary" to="/upgrades" />
                        <Redirect from="/results" to="/analysis" />
                        <Redirect from="/resultsSummary" to="/analysis" />
                        <Redirect from="/uploadGis" to="/umibuilder/upload?entrypoint=full" />
                        <Redirect from="/uploadUio" to="/umibuilder/upload?entrypoint=uio" />
                        <Redirect from="/uploadGisOnly" to="/umibuilder/upload" />
                        <Redirect from="/assignTemplates" to="/umibuilder" />
                        <Redirect from="/keyfields" to="/umibuilder" />
                        <Redirect from="/shpfilesummary" to="/umibuilder" />
                        <Redirect from="/organize" to="/umibuilder" />
                        <Redirect from="/contextLayers" to="/umibuilder" />
                        <Redirect from="/verify" to="/umibuilder" />
                        <Redirect exact from="/" to="/umibuilder" />

                    </Switch>
                )}
                
            </div>
        </Router>
    );
}

export default App;
