// Hooks
import toast from "react-hot-toast"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"

///// Umiverse Imports

// Redux
import { makeSelectTracksBaseline, selectAllSettingsConfirmed, selectSettingsPageScenarioGroup } from "../../../redux/slices/analysis/selectors"
import { updateScenarioSettingsPageGroup, updateTracksBaseline } from "../../../redux/slices/analysis/reducers"
import { ScenarioSettingsPageConfig } from "../../../redux/slices/analysis/consts"

const useStyles = makeStyles((theme) => ({
    switch: {
        marginLeft: "1rem"
    }
}))

export default ({usesTrackerControl, scenarioName}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const allSetingsConfirmed = useSelector(selectAllSettingsConfirmed)
    const activeScenarioSettingsGroup = useSelector(selectSettingsPageScenarioGroup)
    const tracksBaseline = useSelector(makeSelectTracksBaseline({scenarioName, settingsGroup: activeScenarioSettingsGroup}))

    const handleChangeGroup = useCallback((e, value) => {
        // if (!allSetingsConfirmed) {
        //     toast.error("The first time you visit the settings page, you must confirm each individual settings page by using the 'next' button")
        //     return
        // }
        if (value?.length) dispatch(updateScenarioSettingsPageGroup(value))
    }, [dispatch])

    const handleToggleTracker = useCallback((e, value) => {
        dispatch(updateTracksBaseline({scenarioName, settingsGroup: activeScenarioSettingsGroup, value}))
    }, [dispatch, scenarioName, activeScenarioSettingsGroup] )
    return (
        <>
            <ToggleButtonGroup size="small" exclusive value={activeScenarioSettingsGroup} onChange={handleChangeGroup}>
                {Object.entries(ScenarioSettingsPageConfig).map( ([name, {label}])=> (
                    <ToggleButton key={`${name}-scenario-toggle-button`} value={name}>{label}</ToggleButton>
                ))}
            </ToggleButtonGroup>
            {usesTrackerControl > 0 && (
                <Tooltip title={"If enabled, this scenario will follow whatever settings are set for the Baseline scenario."}>
                    <FormControlLabel className={classes.switch} control={<Switch checked={tracksBaseline} onChange={handleToggleTracker} name={"tracksBaseline"} color="primary"/>} label="Track Baseline" />
                </Tooltip>
            )}
        </>

    )
}