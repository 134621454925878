import { Workflow } from "../../consts"
import { buildLUTs } from "../../utils"

export const AsyncTaskConfig = {
	testAsyncRoute: {
		route: "test_async",
        usesToasts: true,
        slice: null,
	},
    processVizFiles: {
        route: "analysis/get_all_data",
        usesToasts: true,
        slice: Workflow.analysis
    },
    computeAdoption: {
        route: "analysis/compute_adoption",
        usesToasts: true,
        slice: Workflow.analysis,
    },
    processBaseGis: {
        route: "umibuilder/process_zip",
        usesToasts: true,
        slice: Workflow.umibuilder,
    },
    assignBuildingsToArchetypes: {
        route: "umibuilder/assign_buildings_to_archetypes",
        usesToasts: true,
        slice: Workflow.umibuilder,
    },
    startUmiExport: {
        route: "umibuilder/start_umi_export",
        usesToasts: true,
        slice: Workflow.umibuilder,
    }
}
export const {enum: AsyncTask, arr: AsyncTasks} = buildLUTs(AsyncTaskConfig)

// TODO: Consider returning an object per workflow, or defining it per workflow in the firstplace
// To avoid needing to filter in extraReducer cases
export const filterAsyncTasksByWorkflow = (workflow) => {
    return AsyncTasks.filter(task => AsyncTaskConfig[task].slice === workflow)
}

