// Hooks
import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"

// MUI
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

///// Umiverse Imports

// Redux
import { updateMeasureContext } from "../../../redux/slices/upgrades/reducers"

export default ({preset_values, config}) => {
    const dispatch = useDispatch()

    const {Name: DisplayName, Default, Units, Type, "Prop.Name": PropName, Prop} = config

    const isCategorical = useMemo(() => Type === "Categorical")

    const isDefinedInPreset = useMemo(() => preset_values[PropName], [PropName, preset_values])

    const value = isDefinedInPreset ? preset_values[PropName].Value : Default

    const options = useMemo(() => {
        return isCategorical ? Units.split(",").map( (label, value)=> ({value, label})) : []
    }, [ isCategorical, Units])

    const handleChange = useCallback((e) => {
        const Value = isCategorical ? e.target.value : Number(e.target.value)
        dispatch(updateMeasureContext({config, Value}))
    }, [dispatch, config, isCategorical])

    // TODO: Support booleans

    return isCategorical ? (
            <FormControl fullWidth >
                <InputLabel id={`${PropName}-categorical-selector-label` }>
                    {DisplayName}
                </InputLabel>
                <Select
                    labelId={`${PropName}-categorical-selector-label` }
                    id={`${PropName}-categorical-selector` }
                    value={value}
                    onChange={handleChange}
                >
                    {options.map( ({value, label}, i)=> (
                        <MenuItem
                            value={value} 
                            key={`${PropName}-option-${value}-${label}-${i}`}
                        >
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
    ) : (
        <TextField
            label={DisplayName}
            helperText={isCategorical ? undefined : Units}
            type={isCategorical ? undefined : "number"}
            fullWidth
            // defaultValue={value}
            value={value}
            onChange={handleChange}
        />
    )
}
