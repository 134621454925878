
import { createSlice } from '@reduxjs/toolkit'

export const navigationSlice = createSlice({
    name: 'analysis',
        initialState: {
        selectedTab: null,
        rootPath: "/",
    },
    reducers: {
        setTab: (state, {payload}) => {
            state.selectedTab = payload
        }, 
        setRootPath: (state, {payload}) => {
            if (payload) {
                state.rootPath = payload
            } else {
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setTab,
    setRootPath,
} = navigationSlice.actions

export default navigationSlice.reducer